import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import cls from "./components/ToolsTable.module.scss";

import DatePicker from "react-datepicker";
import ar from "date-fns/locale/ar-EG";
import { makeStyles } from "@material-ui/core/styles";
import { registerLocale } from "react-datepicker";
import axios from "axios";
import Alerto from "../../components/UI/toaster";
import { Autocomplete } from "@material-ui/lab";
import ToolsTable from "./components/ToolsTable";
import { toast } from "react-toastify";
import Mydate from "../../components/Helpers/MyDateParser";
import FromToDateHandler from "./components/FromToDateHandler";
import MainHeader from "../../components/mainHeader/MainHeader";
const Size = 20;
const actions = [
  { label: "اضافة", id: "1", state: true },
  { label: "تعديل", id: "2", state: false },
  { label: "حذف", id: "3", state: false },
];
const categories = [
  { label: "الكل", id: "0", state: true },
  { label: "خارج", id: "1", state: false },
  { label: "وارد", id: "2", state: false },
  { label: "هالك", id: "3", state: false },
];
const headerCells = [
  "اسم العميل",
  "تاريخ ",
  "المستخدم",
  "تاريخ الاجراء",
  "الاجراء",
];
const BodyCells = [
  "اسم العميل",
  "تاريخ ",
  "العدة",
  "عدد ",
  "المستخدم",
  "تاريخ الاجراء",
  "الاجراء",
];
registerLocale("ar", ar);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  radio: {
    margin: "0vh 0vw 0vh 20px",
    display: "block",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: 12,
  },
  SearchForm: {
    width: "20vw",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    "& > *": {
      marginRight: 12,
    },
  },
}));

function ToolsHistory() {
  const [DamageHeader, setDamageHeader] = useState([]);
  const [DamageGetHeader, setDamageGetHeader] = useState([]);
  const [DamageBody, setDamageBody] = useState([]);
  const [DamageGetBody, setDamageGetBody] = useState([]);
  const [ExportingHeader, setExportingHeader] = useState([]);
  const [ExportingGetHeader, setExportingGetHeader] = useState([]);
  const [ExportingBody, setExportingBody] = useState([]);
  const [ExportingGetBody, setExportingGetBody] = useState([]);
  const [ImportingHeader, setImportingHeader] = useState([]);
  const [ImportingGetHeader, setImportingGetHeader] = useState([]);
  const [ImportingBody, setImportingBody] = useState([]);
  const [ImportingGetBody, setImportingGetBody] = useState([]);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [fromTime, setFromTime] = useState(new Date());
  const [toTime, setToTime] = useState(new Date());
  const [parsedFrom, setParsedFrom] = useState(Mydate(new Date()));
  const [parsedTo, setParsedTo] = useState(Mydate(new Date()));
  const [Namesearch, setNameSearch] = useState("");
  const [Actions, setActions] = useState(actions);
  const [categ, setCateg] = useState(categories);
  const timeRef = useRef();
  const checkhandler = (id) => {
    let element = categ.filter((ele) => ele.id == id);
    if (!element[0].state) {
      return false;
    }
    return true;
  };
  const handleCatg = (id) => {
    let arr = [...categ];
    let element = arr.filter((ele) => ele.id == id);
    let index = arr.indexOf(element[0]);

    element[0].state = !element[0].state;
    arr[index] = element[0];
    if (index == 0 && arr[0].state) {
      arr.map((ele) => (ele.id > 0 ? (ele.state = false) : null));
    } else {
      arr.map((ele) => (ele.id == 0 ? (ele.state = false) : null));
    }
    setCateg(arr);
  };
  const checkhandler2 = (id) => {
    let element = Actions.filter((ele) => ele.id == id);
    if (!element[0].state) {
      return false;
    }
    return true;
  };
  const handleAction = (id) => {
    let arr = [...Actions];
    let element = arr.filter((ele) => ele.id == id);
    let index = arr.indexOf(element[0]);
    element[0].state = !element[0].state;
    arr[index] = element[0];
    setActions(arr);
  };
  const getUsers = () => {
    axios
      .get(`/Security/users`)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  React.useEffect(() => {
    getUsers();
  }, []);

  const ExportingHandler = async () => {
    try {
      const res = await axios.post(`History/Tools/Header/Exporting`, {
        recorderId: user.userId,
        from: parsedFrom,
        to: parsedTo,
        added: Actions[0].state,
        updated: Actions[1].state,
        deleted: Actions[2].state,
      });
      setExportingHeader(res.data);
    } catch (err) {
      Alerto(err);
    }
  };
  const ExportingBodyHandler = async () => {
    try {
      const res2 = await axios.post(`History/Tools/Item/Exporting`, {
        recorderId: user.userId,
        from: parsedFrom,
        to: parsedTo,
        added: Actions[0].state,
        updated: Actions[1].state,
        deleted: Actions[2].state,
      });
      setExportingBody(res2.data);
    } catch (err) {
      Alerto(err);
    }
  };
  const ImportingHandler = async () => {
    try {
      const res = await axios.post(`History/Tools/Header/Importing`, {
        recorderId: user.userId,
        from: parsedFrom,
        to: parsedTo,
        added: Actions[0].state,
        updated: Actions[1].state,
        deleted: Actions[2].state,
      });

      setImportingHeader(res.data);
    } catch (err) {
      Alerto(err);
    }
  };
  const ImportingBodyHandler = async () => {
    try {
      const res2 = await axios.post(`History/Tools/Item/Importing`, {
        recorderId: user.userId,
        from: parsedFrom,
        to: parsedTo,
        added: Actions[0].state,
        updated: Actions[1].state,
        deleted: Actions[2].state,
      });
      setImportingBody(res2.data);
    } catch (err) {
      Alerto(err);
    }
  };
  const DamageHandler = async () => {
    try {
      const res = await axios.post(`History/Tools/Item/Damages`, {
        recorderId: user.userId,
        from: parsedFrom,
        to: parsedTo,
        added: Actions[0].state,
        updated: Actions[1].state,
        deleted: Actions[2].state,
      });

      setDamageBody(res.data);
    } catch (err) {
      Alerto(err);
    }
  };
  const DamageBodyHandler = async () => {
    try {
      const res2 = await axios.post(`History/Tools/Header/Damages`, {
        recorderId: user.userId,
        from: parsedFrom,
        to: parsedTo,
        added: Actions[0].state,
        updated: Actions[1].state,
        deleted: Actions[2].state,
      });

      setDamageHeader(res2.data);
    } catch (err) {
      Alerto(err);
    }
  };

  const ResetAll = () => {
    setExportingHeader([]);
    setExportingBody([]);
    setDamageHeader([]);
    setDamageBody([]);
    setImportingBody([]);
    setImportingHeader([]);
  };
  React.useEffect(() => {
    clearTimeout(timeRef.current);
    timeRef.current = setTimeout(() => {
      ResetAll();
      if (categ[0].state) {
        ExportingHandler();
        ExportingBodyHandler();
        ImportingHandler();
        ImportingBodyHandler();
        DamageHandler();
        DamageBodyHandler();
        return;
      }
      if (categ[1].state) {
        ExportingHandler();
        ExportingBodyHandler();
      }
      if (categ[2].state) {
        ImportingHandler();
        ImportingBodyHandler();
      }
      if (categ[3].state) {
        DamageHandler();
        DamageBodyHandler();
      }
    }, 1000);
    return () => clearTimeout(timeRef.current);
  }, [categ, Actions, user, fromTime, toTime]);
  const classes = useStyles();
  const ExpoHeadGhandl = async (id, date) => {
    try {
      const res = await axios.get(
        `History/Tools/Header/Exporting/before?entityId=${id}&before=${date.substring(
          0,
          date.length - 5
        )}`,
        {
          recorderId: user.userId,
          from: parsedFrom,
          to: parsedTo,
          added: Actions[0].state,
          updated: Actions[1].state,
          deleted: Actions[2].state,
        }
      );
      if (!res.data[0]) {
        toast.error("لا يوجد بيانات لعرضها");
        return;
      }
      setExportingGetHeader(res.data);
    } catch (err) {
      Alerto(err);
    }
  };
  const ExpoBodyGhandl = async (id, date) => {
    try {
      const res = await axios.get(
        `History/Tools/Item/Exporting/before?entityId=${id}&before=${date.substring(
          0,
          date.length - 5
        )}`,
        {
          recorderId: user.userId,
          from: parsedFrom,
          to: parsedTo,
          added: Actions[0].state,
          updated: Actions[1].state,
          deleted: Actions[2].state,
        }
      );
      if (!res.data[0]) {
        toast.error("لا يوجد بيانات لعرضها");
        return;
      }
      setExportingGetBody(res.data);
    } catch (err) {
      Alerto(err);
    }
  };
  const ImportGetHeaderHandl = async (id, date) => {
    try {
      const res = await axios.get(
        `History/Tools/Header/Importing/before?entityId=${id}&before=${date.substring(
          0,
          date.length - 5
        )}`,
        {
          recorderId: user.userId,
          from: parsedFrom,
          to: parsedTo,
          added: Actions[0].state,
          updated: Actions[1].state,
          deleted: Actions[2].state,
        }
      );
      if (!res.data[0]) {
        toast.error("لا يوجد بيانات لعرضها");
        return;
      }
      setImportingGetHeader(res.data);
    } catch (err) {
      Alerto(err);
    }
  };
  const ImportGetBodyHandl = async (id, date) => {
    try {
      const res = await axios.get(
        `History/Tools/Item/Importing/before?entityId=${id}&before=${date.substring(
          0,
          date.length - 5
        )}`,
        {
          recorderId: user.userId,
          from: parsedFrom,
          to: parsedTo,
          added: Actions[0].state,
          updated: Actions[1].state,
          deleted: Actions[2].state,
        }
      );
      if (!res.data[0]) {
        toast.error("لا يوجد بيانات لعرضها");
        return;
      }
      setImportingGetBody(res.data);
    } catch (err) {
      Alerto(err);
    }
  };
  const DamagGetHeadHandl = async (id, date) => {
    try {
      const res = await axios.get(
        `History/Tools/Header/Damages/before?entityId=${id}&before=${date.substring(
          0,
          date.length - 5
        )}`,
        {
          recorderId: user.userId,
          from: parsedFrom,
          to: parsedTo,
          added: Actions[0].state,
          updated: Actions[1].state,
          deleted: Actions[2].state,
        }
      );
      if (!res.data[0]) {
        toast.error("لا يوجد بيانات لعرضها");
        return;
      }
      setDamageGetHeader(res.data);
    } catch (err) {
      Alerto(err);
    }
  };
  const DamagGetBodyHandl = async (id, date) => {
    try {
      const res = await axios.get(
        `History/Tools/Item/Damages/before?entityId=${id}&before=${date.substring(
          0,
          date.length - 5
        )}`,
        {
          recorderId: user.userId,
          from: parsedFrom,
          to: parsedTo,
          added: Actions[0].state,
          updated: Actions[1].state,
          deleted: Actions[2].state,
        }
      );
      if (!res.data[0]) {
        toast.error("لا يوجد بيانات لعرضها");
        return;
      }
      setDamageGetBody(res.data);
    } catch (err) {
      Alerto(err);
    }
  };
  const manageFromDate = (d) => {
    setFromTime(d);
    setParsedFrom(Mydate(d));
  };
  const manageToDate = (d) => {
    setToTime(d);
    setParsedTo(Mydate(d));
  };

  return (
    <div className={classes.root}>
      <div className="my-2">
        <MainHeader title="سجل العدة والفوارغ " />
      </div>
      <CardContent className="headerSearch m-0">
        <Grid
          container
          spacing={2}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <FromToDateHandler
            toTime={toTime}
            fromTime={fromTime}
            handleFrom={manageFromDate}
            handleTo={manageToDate}
          />
          <Grid item xs={12} md={3}>
            <Autocomplete
              size="small"
              className="w-100"
              options={users}
              getOptionSelected={(option, value) => {
                return option.value === value?.value;
              }}
              getOptionLabel={(option) =>
                option.partnerName ? option.partnerName : ""
              }
              autoHighlight
              autoComplete
              noOptionsText="لم يتم العثور على خيارات"
              onChange={(event, value) => {
                if (value) {
                  setUser(value);
                } else {
                  setUser({});
                }
              }}
              value={user}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="بحث بأسم المستخدم"
                  variant="outlined"
                  className="w-100"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              className="w-100"
              placeholder="بحث بأسم العميل"
              size="small"
              value={Namesearch}
              onChange={(e) => setNameSearch(e.target.value)}
            />
          </Grid>

          <Grid className={classes.radio}>
            <FormControl
              component="fieldset"
              style={{ marginBottom: 4, display: "block" }}
            >
              <RadioGroup
                row
                onClick={(event) => {
                  handleCatg(event.target.value);
                }}
              >
                {categories.map((ele) => (
                  <FormControlLabel
                    value={ele.id}
                    control={
                      <Radio
                        style={{
                          color: "#fbc02d",
                        }}
                        checked={checkhandler(ele.id)}
                      />
                    }
                    label={ele.label}
                  />
                ))}
              </RadioGroup>
              <RadioGroup
                row
                onClick={(event) => {
                  handleAction(event.target.value);
                }}
              >
                {actions.map((ele) => (
                  <FormControlLabel
                    value={ele.id}
                    control={
                      <Radio
                        style={{
                          color: "#fbc02d",
                        }}
                        checked={checkhandler2(ele.id)}
                      />
                    }
                    label={ele.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      {ExportingHeader[0] && (
        <ToolsTable
          getHandler={ExpoHeadGhandl}
          close={() => setExportingGetHeader([])}
          class={cls.table}
          search={Namesearch}
          GetData={ExportingGetHeader}
          data={ExportingHeader}
          head={headerCells}
          title={"الخارج"}
          Gclass={cls.modaltable}
          state={true}
          pagination={ExportingHandler}
        />
      )}
      {ExportingBody[0] && (
        <ToolsTable
          getHandler={ExpoBodyGhandl}
          close={() => setExportingGetBody([])}
          class={cls.table2}
          search={Namesearch}
          GetData={ExportingGetBody}
          data={ExportingBody}
          head={BodyCells}
          title={"خارج عدة"}
          Gclass={cls.modaltable2}
          pagination={ExportingBodyHandler}
        />
      )}
      {ImportingHeader[0] && (
        <ToolsTable
          getHandler={ImportGetHeaderHandl}
          close={() => setImportingGetHeader([])}
          class={cls.table}
          search={Namesearch}
          GetData={ImportingGetHeader}
          data={ImportingHeader}
          head={headerCells}
          title={"الوارد"}
          Gclass={cls.modaltable}
          pagination={ImportingHandler}
        />
      )}
      {ImportingBody[0] && (
        <ToolsTable
          getHandler={ImportGetBodyHandl}
          close={() => setImportingGetBody([])}
          class={cls.table2}
          search={Namesearch}
          GetData={ImportingGetBody}
          data={ImportingBody}
          head={BodyCells}
          title={"وارد عدة"}
          Gclass={cls.modaltable2}
          pagination={ImportingBodyHandler}
        />
      )}
      {DamageHeader[0] && (
        <ToolsTable
          getHandler={DamagGetHeadHandl}
          close={() => setDamageGetHeader([])}
          class={cls.table}
          search={Namesearch}
          GetData={DamageGetHeader}
          data={DamageHeader}
          head={headerCells}
          title={"الهالك"}
          Gclass={cls.modaltable}
          pagination={DamageHandler}
        />
      )}
      {DamageBody[0] && (
        <ToolsTable
          getHandler={DamagGetBodyHandl}
          close={() => setDamageGetBody([])}
          class={cls.table2}
          search={Namesearch}
          GetData={DamageGetBody}
          data={DamageBody}
          head={BodyCells}
          title={"هالك عدة"}
          Gclass={cls.modaltable2}
          pagination={DamageBodyHandler}
        />
      )}
    </div>
  );
}

export default ToolsHistory;
