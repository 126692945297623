import React from "react";
import {
  Typography,
  Paper,
  Box,
  Link,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
  Fade,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  AccountBalanceWallet,
  Payment,
  CreditCard,
  AccountBalance,
  Language,
  ContentCopy,
  Launch,
} from "@mui/icons-material";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MusicNoteIcon from "@mui/icons-material/MusicNote";

// Import images
import QRinstaPay from "../src/assets/images/QR/QRInstaPay.jpeg";
import fawry from "../src/assets/images/fawry.jpg";
import instapay from "../src/assets/images/instapay.png";
import paypal from "../src/assets/images/paypal.png";
import misr from "../src/assets/images/misr.png";
import qnb from "../src/assets/images/qnb.png";
import vodafone from "../src/assets/images/vodafone.jpeg";

// Helper Functions
const getWhatsAppLink = () => {
  const phoneNumber = "+201006375557";
  const systemName = window.location.hostname;
  const message = `${systemName}\n سلام عليكم،أود الاستفسار عن `;

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  if (isMobile) {
    return `whatsapp://send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
  } else {
    return `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
  }
};

const getSocialMediaLink = (platform) => {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const platformLinks = {
    facebook: {
      mobile: "fb://page/OwlTech2012",
      web: "https://www.facebook.com/OwlTech2012",
    },
    youtube: {
      mobile: "youtube://www.youtube.com/channel/UC4HINSLHHDsMNq28eArGoeg",
      web: "https://www.youtube.com/@OwlTechEG",
    },
    instagram: {
      mobile: "instagram://user?username=OwlTechEG",
      web: "https://www.instagram.com/OwlTechEG",
    },
    twitter: {
      mobile: "twitter://user?screen_name=Owltech2012",
      web: "https://twitter.com/Owltech2012",
    },
    tiktok: {
      mobile: "https://www.tiktok.com/@OwlTechEG",
      web: "https://www.tiktok.com/@OwlTechEG",
    },
  };

  const platformData = platformLinks[platform];
  return isMobile ? platformData.mobile : platformData.web;
};

const PaymentMethodCard = ({ method }) => {
  const [copied, setCopied] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Box mb={2}>
      <Paper elevation={3} style={{ overflow: "hidden" }}>
        <Box
          p={isMobile ? 1 : 2}
          bgcolor="#f5f5f5"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant={isMobile ? "subtitle1" : "h6"}
            style={{ fontWeight: "bold" }}
          >
            {method.name}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {method.note}
          </Typography>
        </Box>

        <Box p={isMobile ? 1 : 2}>
          <Box
            display="flex"
            alignItems="center"
            bgcolor="#ffffff"
            p={isMobile ? 1 : 2}
            borderRadius={4}
            border="1px solid #e0e0e0"
          >
            <Typography
              variant="body1"
              style={{
                fontFamily: "monospace",
                userSelect: "all",
                flexGrow: 1,
                fontSize: isMobile ? "14px" : "16px",
              }}
            >
              {method.details}
            </Typography>
            <Tooltip
              title={copied ? "تم النسخ!" : "نسخ"}
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <IconButton
                size="small"
                onClick={() => handleCopy(method.details)}
              >
                <ContentCopy fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>

          {method.qrCode && (
            <Box mt={2} display="flex" justifyContent="center">
              <Paper
                elevation={2}
                style={{
                  padding: isMobile ? "8px" : "12px",
                  backgroundColor: "#ffffff",
                }}
              >
                <img
                  src={method.qrCode}
                  alt="QR Code"
                  style={{
                    width: isMobile ? "150px" : "200px",
                    height: isMobile ? "150px" : "200px",
                    objectFit: "contain",
                  }}
                />
                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="block"
                  textAlign="center"
                  mt={1}
                >
                  امسح الكود للدفع عبر إنستا باي
                </Typography>
              </Paper>
            </Box>
          )}

          {method.swiftCode && (
            <Box mt={1}>
              <Typography variant="body2" color="textSecondary">
                Swift Code:{" "}
                <Box
                  component="span"
                  fontFamily="monospace"
                  bgcolor="#f5f5f5"
                  px={1}
                  py={0.5}
                  borderRadius={1}
                >
                  {method.swiftCode}
                </Box>
              </Typography>
            </Box>
          )}

          {(method.paymentLink || method.paypalLink) && (
            <Box
              mt={2}
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              gap={2}
            >
              {method.paymentLink && (
                <Link
                  href={method.paymentLink}
                  target="_blank"
                  rel="noopener"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    color: "#1976d2",
                    textDecoration: "none",
                    fontWeight: "bold",
                    padding: "8px 16px",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "4px",
                    transition: "background-color 0.2s",
                    width: isMobile ? "100%" : "auto",
                    justifyContent: isMobile ? "center" : "flex-start",
                  }}
                >
                  <Launch fontSize="small" />
                  الدفع عبر إنستا باي
                </Link>
              )}

              {method.paypalLink && (
                <Link
                  href={method.paypalLink}
                  target="_blank"
                  rel="noopener"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    color: "#003087",
                    textDecoration: "none",
                    fontWeight: "bold",
                    padding: "8px 16px",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "4px",
                    transition: "background-color 0.2s",
                    width: isMobile ? "100%" : "auto",
                    justifyContent: isMobile ? "center" : "flex-start",
                  }}
                >
                  <Launch fontSize="small" />
                  الدفع عبر باي بال{" "}
                </Link>
              )}
            </Box>
          )}

          {method.companyLogo && (
            <Box mt={2} display="flex" justifyContent="center">
              <img
                src={method.companyLogo}
                alt="Company Logo"
                style={{
                  width: isMobile ? "60px" : "80px",
                  height: "auto",
                  opacity: 0.8,
                }}
              />
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

const PaymentMethods = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const paymentMethods = [
    {
      title: "المحافظ الإلكترونية",
      icon: AccountBalanceWallet,
      methods: [
        {
          name: "فودافون كاش",
          details: "01006375557",
          note: "تحويل فوري ومباشر",
          companyLogo: vodafone,
        },
      ],
    },
    {
      title: "انستا باي",
      icon: Payment,
      methods: [
        {
          name: "InstaPay",
          details: "owltech@instapay",
          note: "دفع إلكتروني آمن",
          companyLogo: instapay,
          qrCode: QRinstaPay,
          paymentLink: "https://ipn.eg/S/owltech/instapay/1qUon1",
        },
      ],
    },
    {
      title: "فوري",
      icon: CreditCard,
      methods: [
        {
          name: "خدمة فوري",
          details: "01006375557",
          note: "متوفر في تطبيق فوري",
          companyLogo: fawry,
        },
      ],
    },
    {
      title: "الحسابات البنكية",
      icon: AccountBalance,
      methods: [
        {
          name: "Banque Misr",
          details: "EG230002025602560333000117174",
          swiftCode: "BMISEGXXXX",
          note: "تحويل بنكي محلي",
          companyLogo: misr,
        },
        {
          name: "QNB (EGP)",
          details: "EG200037020208181130514442897",
          swiftCode: "QNBAEGCXXXX",
          note: "تحويل بنكي محلي",
          companyLogo: qnb,
        },
        {
          name: "QNB (USA)",
          details: "EG390037020208401130515347914",
          swiftCode: "QNBAEGCXXXX",
          note: "تحويل دولي",
          companyLogo: qnb,
        },
      ],
    },
    {
      title: "باي بال",
      icon: Language,
      methods: [
        {
          name: "PayPal",
          details: "paypal.me/owltech2012",
          note: "دفع دولي آمن",
          companyLogo: paypal,
          paypalLink: "https://paypal.me/owltech2012",
        },
      ],
    },
  ];

  return (
    <Box maxWidth={isMobile ? "100%" : "lg"} mx="auto" p={isMobile ? 1 : 3}>
      <Box textAlign="center" mb={isMobile ? 3 : 6}>
        <Typography
          variant={isMobile ? "h4" : "h3"}
          gutterBottom
          fontWeight="bold"
        >
          طرق الدفع 
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          نوفر لكم مجموعة متنوعة من طرق الدفع الآمنة والمريحة
        </Typography>
      </Box>

      <Paper
        elevation={0}
        style={{
          backgroundColor: "#f5f5f5",
          marginBottom: isMobile ? "16px" : "24px",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          textColor="primary"
          indicatorColor="primary"
          aria-label="payment methods tabs"
          style={{ backgroundColor: "#ffffff", borderRadius: "8px" }}
        >
          {paymentMethods.map((method, index) => (
            <Tab
              key={index}
              icon={<method.icon />}
              label={method.title}
              style={{
                minHeight: isMobile ? "60px" : "72px",
                minWidth: isMobile ? "120px" : "160px",
              }}
            />
          ))}
        </Tabs>
      </Paper>

      {paymentMethods.map((category, index) => (
        <Box
          key={index}
          role="tabpanel"
          hidden={tabValue !== index}
          style={{ display: tabValue === index ? "block" : "none" }}
        >
          {category.methods.map((method, methodIndex) => (
            <PaymentMethodCard key={methodIndex} method={method} />
          ))}
        </Box>
      ))}

      <Box
        textAlign="center"
        mt={isMobile ? 3 : 6}
        bgcolor="#f5f5f5"
        p={isMobile ? 2 : 4}
        borderRadius={4}
      >
        <Typography variant="body1" color="textSecondary" gutterBottom>
          للمساعدة في عملية الدفع أو للحصول على روابط دفع بالبطاقة، أو
          للاستفسارات الأخرى، يُرجى التواصل مع فريق خدمة العملاء
        </Typography>
        <Typography
          style={{
            direction: "ltr",
            
            fontSize: "28px",
            fontWeight: "bold",
            color: "#1976d2",
            textDecoration: "none",
            margin: "16px 0",
            letterSpacing: "2px",
          }}
        >
          <a
            href="tel:+201006375557"
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            +201006375557
          </a>
        </Typography>

        <Box
          justifyContent="center"
          gap={2}
          p={2}
          bgcolor="white"
          borderRadius={2}
          boxShadow="inset 0 0 5px rgba(0,0,0,0.1)"
        >
          <IconButton
            href={getSocialMediaLink("facebook")}
            style={{ color: "#1877F2" }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            href={getSocialMediaLink("youtube")}
            style={{ color: "#FF0000" }}
          >
            <YouTubeIcon />
          </IconButton>
          <IconButton
            href={getSocialMediaLink("instagram")}
            style={{ color: "#E4405F" }}
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            href={getSocialMediaLink("twitter")}
            style={{ color: "#1DA1F2" }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            href={getSocialMediaLink("tiktok")}
            style={{ color: "#000000" }}
          >
            <MusicNoteIcon />
          </IconButton>
          <IconButton href={getWhatsAppLink()} style={{ color: "#25D366" }}>
            <WhatsAppIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentMethods;
