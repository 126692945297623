import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import AppRouter, { authRoles } from "./AppRouter";
import RTL from "./components/RTL";
import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { getClaims, handleSub, logOut } from "./features/auth/authSlice";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import UpCursor from "./features/Home/components/UpCursor";
import { useRef } from "react";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "bootstrap/dist/css/bootstrap-utilities.min.css";
import Alerto from "./components/UI/toaster";

function App() {
  const subState = useSelector((state) => state.auth.subs);
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role"); // استرجاع الدور من التخزين المحلي
  const history = useHistory();

  const timeref = useRef();
  const dispatch = useDispatch();

  axios.interceptors.response.use(null, (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;
    if (!expectedError) {
      console.log(error);
      toast.error("حدث خطأ غير متوقع");
    }
    if (error.response && error.response.status === 423) {
      console.log(error.response);
      localStorage.removeItem("token");
      localStorage.removeItem("license");
      history.replace("./security");
      toast.error("يرجي التواصل مع الدعم");
    }
    if (error.response && error.response.status === 401) {
      clearTimeout(timeref.current);
      timeref.current = setTimeout(() => {
        !!token && dispatch(logOut());
      }, 1000);
    }

    if (error.response && error.response.status === 402) {
      !!token && dispatch(logOut());
      window.location = "https://stop.owltecheg.com";
    }
    if (error.response && error.response.status === 403) {
      toast.error("غير مصرح لك");
      history.goBack();
    }

    return Promise.reject(error);
  });

  const handleSubsAlert = ({ remainDays, Packagee }) => {
   
    // if (role === "عميل_او_بائع") return;

    switch (true) {
      case remainDays < 0:
        return dispatch(
          handleSub({
            state: true,
            bar: true,
            text: `تم إيقاف الخدمة من  ${remainDays * -1} يوم  `,
          })
        );
      case remainDays === 0:
        return dispatch(
          handleSub({
            state: true,
            bar: true,
            text: `تنبيه هام! يجب تجديد باقة أوول تك ${Packagee} اليوم لتجنب إيقاف الخدمة`,
          })
        );

      case remainDays < 3:
        return dispatch(
          handleSub({
            state: true,
            bar: true,
            text:  `تنبيه : باقي  ${remainDays} يوم فقط لتجديد باقة أوول تك ${Packagee} `,
          })
        );

      default:
        break;
    }
  };

  function checkSubs() {
    axios
      .get("Setting/subscribtion/info")
      .then((res) => {
        handleSubsAlert({
          remainDays: res.data?.remainingDays,
          Packagee: res.data?.type,
        });
      })
      .catch((e) => {
        console.log(e);
        Alerto(e);
      });
  }
  // const user = useSelector((state) => state.auth.user);
  // useEffect(() => {
  //   !!user?.UserId && dispatch(getClaims());
  // }, [user]);
  
  useEffect(() => {
    !!token && checkSubs();
  }, [token]);

  return (
    <RTL>
      <ToastContainer
        style={{
          fontFamily: "DroidKufi-Regular",
          fontSize: 15,
          width: 310,
          marginTop: 60,
        }}
      />
      {subState.bar && role !== "عميل_او_بائع" && (
        <div className="subAlert">{subState.text}</div>
      )}
      <AppRouter />
      <UpCursor />
    </RTL>
  );
}

export default App;