import {
  Box,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Autocomplete from "@material-ui/lab/Autocomplete";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import DatePicker from "react-datepicker";
import InvoicesTable from "./components/invoicesTable";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ar from "date-fns/locale/ar-EG";
import axios from "axios";
import { fetchClients } from "../main-data/dataSlice";
import { makeStyles } from "@material-ui/core/styles";
import { registerLocale } from "react-datepicker";
import Mydate from "../../components/Helpers/MyDateParser";
import Alerto from "../../components/UI/toaster";
import { Col, Container, Row } from "react-bootstrap";
import MainHeader from "../../components/mainHeader/MainHeader";

registerLocale("ar", ar);
const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
  },
  toolbar: {
    marginBottom: 12,
    overflowX: "auto",
  },

  title: {
    fontSize: 14,
  },
});

export default function CommissionInvoice() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [truckDate, setTruckDate] = React.useState("");
  const [client, setClient] = useState("");
  const [invoiceType, setInvoiceType] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [trucks, setTrucks] = useState([]);
  const [renderNext, setRenderNext] = useState(true);

  const clients = useSelector((state) => state.data.clients);
  const dispatch = useDispatch();

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(fetchClients());
    }
    return () => {
      mounted = false;
    };
  }, []);

  const invoiceTypes = [
    { id: 1, name: "مجمدة" },
    { id: 2, name: "غير مجمدة" },
  ];

  const getReports = () => {
    setLoading(true);
    axios
      .post(`/Truck/Report?pageNumber=${pageNumber}&pageSize=15`, {
        at: truckDate ? Mydate(truckDate) : null,
        partnerId: !!client.id ? client.id : null,
        hasInvoice: true,
        freezed: invoiceType === 1 ? true : false,
        truckTypeId: 1,
      })
      .then((res) => {
        setLoading(false);
        setTrucks(res.data);
        if (res.data.length < 15) {
          setRenderNext(false);
        } else {
          setRenderNext(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        setRenderNext(false);

        Alerto(err);
      });
  };

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      getReports();
    }
    return () => {
      mounted = false;
    };
  }, [pageNumber, truckDate, client, invoiceType]);

  return (
    <>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <CircularProgress style={{ color: "#F06563" }} size={80} />{" "}
        </div>
      ) : (
        <div
          className=" w-100  p-0 pb-4 pb-5 position-relative overflow-x-hidden  "
          style={{
            background: "#534d3b",
            marginTop: " -15px !important",
            minHeight: "107vh",
            outline: " 8px solid #534d3b",
          }}
        >
          <Container>
            <div className="mb-3">
              <MainHeader title="فواتير العمولات" />
            </div>
            <Card
              style={{
                marginBottom: 16,
                boxShadow: "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px",
              }}
              className=" center"
              variant="outlined"
            >
              <CardContent className=" center w-100">
                <Row xs={12} className="w-100 center">
                  <Col xs={10} md={3} className=" text-center mb-2">
                    <DatePicker
                      locale="ar"
                      selected={truckDate}
                      onChange={(date) => setTruckDate(date)}
                      dateFormat="dd/MM/yyyy"
                      customInput={
                        <TextField
                          variant="outlined"
                          label="التاريخ"
                          size="small"
                          style={{ width: "100%" }}
                        />
                      }
                    />
                  </Col>

                  <Col xs={10} md={3} className=" text-center mb-2">
                    <Autocomplete
                      xs={10}
                      md={3}
                      className=" text-center"
                      options={clients}
                      getOptionSelected={(option, value) =>
                        option.value === value?.value
                      }
                      getOptionLabel={(option) =>
                        option.name ? option.name : ""
                      }
                      style={{ width: "100%" }}
                      autoHighlight
                      autoComplete
                      noOptionsText="لم يتم العثور على خيارات"
                      onChange={(event, value) => {
                        if (value) {
                          setClient(value);
                        } else {
                          setClient("");
                        }
                      }}
                      value={client}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="اسم العميل"
                          variant="outlined"
                          size="small"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                        />
                      )}
                    />
                  </Col>
                  <Col xs={10} md={3} className=" text-center mb-2">
                    <TextField
                      xs={10}
                      md={3}
                      className=" text-center"
                      style={{ width: "100%" }}
                      select
                      label="نوع الفاتورة"
                      onChange={(e) => setInvoiceType(e.target.value)}
                      value={invoiceType}
                      size="small"
                      variant="outlined"
                    >
                      {invoiceTypes.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Col>
                </Row>
              </CardContent>
            </Card>
            {trucks.length > 0 ? (
              <InvoicesTable trucks={trucks} />
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{ marginTop: 180 }}
                  variant="h6"
                  color="textSecondary"
                >
                  {" "}
                  ﻻ يوجد نقلات{" "}
                </Typography>
              </div>
            )}
            <div style={{ display: "flex", justifyContent: "center" }}>
              {trucks.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: 140,
                    padding: 12,
                  }}
                >
                  <IconButton
                    disabled={!renderNext}
                    onClick={() => {
                      setPageNumber((num) => num + 1);
                    }}
                  >
                    <NavigateNextIcon />
                  </IconButton>
                  <IconButton
                    disabled={pageNumber === 1}
                    onClick={() => {
                      setPageNumber((num) => (num > 0 ? num - 1 : 1));
                    }}
                  >
                    <NavigateBeforeIcon />
                  </IconButton>
                </Box>
              )}
            </div>
          </Container>
        </div>
      )}
    </>
  );
}
