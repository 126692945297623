import { ConvertToArabicNumbers } from "../../../utils";
import React from "react";

const SummeryTable = (props) => {
  const tableHeader = props.isPaper
    ? ["الصنف", "العدد", "الوزن"]
    : ["الصنف", "العدد", "الوزن", "القيمة"];

  // حساب الإجماليات
  const totalCounts = props.summaryItems?.reduce((sum, item) => {
    return sum + (item.itemCount ? item.itemCount : item.itemsCount);
  }, 0);

  const totalWeight = props.summaryItems?.reduce((sum, item) => {
    return sum + item.weight;
  }, 0);

  const totalValue = props.summaryItems?.reduce((sum, item) => {
    return sum + (item.totalMoney ? Math.round(item.totalMoney) : 0);
  }, 0);

  return (
    <table
      style={{
        width: "100%",
        border: "3px solid black",
        borderCollapse: "collapse",
        margin: "6px 0",
      }}
    >
      {/* رأس الجدول */}
      <thead>
        <tr style={{ background: "#f3c637", lineHeight: "0px" }}>
          {tableHeader.map((item, index) => (
            <td key={index} align="center">
              <p> {item} </p>
            </td>
          ))}
        </tr>
      </thead>

      {/* محتوى الجدول */}
      <tbody>
        {props.summaryItems &&
          props.summaryItems.length > 0 &&
          props.summaryItems.map((item, index) => (
            <tr key={index} align="center" style={{ lineHeight: ".1px" }}>
              <td
                style={{
                  backgroundColor: "#EEF66C",
                  padding: "0 5px 0 5px",
                  border: "3px solid black",
                }}
                align="center"
              >
                <p>{item.item.product.name}</p>
              </td>

              <td style={{ backgroundColor: "#fff", border: "3px solid black" }}>
                <p>
                  {ConvertToArabicNumbers(
                    item.itemCount ? item.itemCount : item.itemsCount
                  )}
                </p>
              </td>

              <td style={{ backgroundColor: "#fff", border: "3px solid black" }}>
                <p>{ConvertToArabicNumbers(item.weight)}</p>
              </td>

              {!props.isPaper && (
                <td
                  style={{ backgroundColor: "#fff", border: "3px solid black" }}
                >
                  <p>{ConvertToArabicNumbers(Math.round(item.totalMoney))}</p>
                </td>
              )}
            </tr>
          ))}

        {/* صف الإجمالي */}
        {props.summaryItems && props.summaryItems.length > 1 && (
          <tr
            align="center"
            style={{ fontWeight: "bold", backgroundColor: "#EEF66C" }}
          >
            <td style={{ border: "3px solid black" }}></td>
            <td style={{ border: "3px solid black" }}>
              {ConvertToArabicNumbers(totalCounts)}
            </td>
            <td style={{ border: "3px solid black" }}>
              {ConvertToArabicNumbers(totalWeight)}
            </td>
            {!props.isPaper && (
              <td style={{ border: "3px solid black" }}>
                {ConvertToArabicNumbers(totalValue)}
              </td>
            )}
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default SummeryTable;
