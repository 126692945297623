import { Box, Button, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { infoCardHeader, warningCardHeader } from "../../../components/styles";
import { useDispatch, useSelector } from "react-redux";
import AddDedeuctions from "../container/AddDedeuctions";
import AddExpenses from "../container/AddExpenses";
import AddItemsForm from "./AddItemForm";
import { ConvertToArabicNumbers } from "../../../utils";
import CustomDialog from "../../../components/CustomDialog";
import ItemsTable from "./ItemsTable";
import { getCommisionItem, getTruck } from "../loadsSlice";
import { makeStyles } from "@material-ui/styles";
import { useParams } from "react-router-dom";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import axios from "axios";
import Alerto from "../../../components/UI/toaster";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 16,
    paddingTop: 20,
    overflow: "auto",
    [theme.breakpoints.up("md")]: {
      width: "90%",
    },
  },

  commissionTruck: {},

  purchasesTruck: {},

  loadInfo: {
    padding: "8px 12px",
    borderRadius: 20,
    backgroundColor: "#EEF66C",
    boxShadow:
      "0 4px 20px  rgba(238, 246, 108, 0.3), 0 -7px -10px -5px rgba(238, 246, 108, 0.09)",
    "&:hover": {
      boxShadow: "0 0 0",
    },
  },
  qty: {
    padding: 8,

    borderRadius: 20,
    paddingRight: 12,
    paddingLeft: 12,
    color: "#FFF",
    ...infoCardHeader,
  },
  close: {
    position: "absolute",
    top: 16,
    right: 16,
    color: theme.palette.grey[700],
    height: 36,
    width: 36,
    backgroundColor: "rgba(216, 218, 223, 0.5)",
    "&:hover": {
      backgroundColor: "#D8DADF",
    },
  },
}));

function AddItems({ show, onClose, onDepart }) {
  const loadItems = useSelector((state) => state.loads.commissionItems.items);
  const load = useSelector((state) => state.loads.load.data);
  const [isShared, setIsShared] = useState(!!load.sharedTruck);
  const classes = useStyles();
  const deduction = useSelector((state) => state.loads.deduction);
  const dispatch = useDispatch();
  const totalItemsCount = loadItems.reduce((acc, currentValue) => {
    return acc + currentValue.itemsCount;
  }, 0);

  const totalRemainingCount = loadItems.reduce((acc, currentValue) => {
    return acc + currentValue.remainingProductCount;
  }, 0);

  const totalSoldItems = totalItemsCount - totalRemainingCount;

  const loadValue =
    load &&
    load.truckTypeId &&
    loadItems.reduce((acc, item) => {
      return (
        acc +
        (item.price * item.weight + item.commisionForClient * item.itemsCount)
      );
    }, 0);

  let { truckId } = useParams();
  React.useEffect(() => {
    if (truckId) {
      dispatch(getCommisionItem());
    }
  }, [truckId, load, totalItemsCount]);

  const loadExpenses = useSelector((state) => state.loads.loadExpenses);
  const totalExpenses = loadExpenses.expenses?.reduce((acc, val) => {
    return acc + val.money;
  }, 0);

  const formatNumberIfDecimal = (number) => {
    return Number.isInteger(number) ? number : Number(number).toFixed(2);
  };

  const [openDeductions, setOpenDeductions] = useState(false);
  const [openExpenses, setOpenExpenses] = useState(false);
  const totals =
  deduction && deduction.length > 0
    ? (() => {
        // العمولة ونسبة العمولة
        const totalIndependentCommision = deduction.reduce(
          (sum, item) => sum + (item.independentCommision || 0),
          0
        );
        const totalCommisionPercentage = deduction.reduce(
          (sum, item) => sum + (item.commisionPercentage || 0),
          0
        );

        // الخصومات الأخرى
        const totalFridgeRent = deduction.reduce(
          (sum, item) => sum + (item.fridgeRent || 0),
          0
        );
        const totalOfficeTips = deduction.reduce(
          (sum, item) => sum + (item.officeTips || 0),
          0
        );
        const totalMovementToolRent = deduction.reduce(
          (sum, item) => sum + (item.movementToolRent || 0),
          0
        );
        const totalCarryingPrice = deduction.reduce(
          (sum, item) => sum + (item.carryingPrice || 0),
          0
        );

        // مجموع الخصومات الكلي
        const totalDeductions =
          totalFridgeRent +
          totalOfficeTips +
          totalMovementToolRent +
          totalCarryingPrice;

        return {
          independentCommision: totalIndependentCommision,
          commisionPercentage: totalCommisionPercentage,
          deductions: totalDeductions,
        };
      })()
    : { independentCommision: 0, commisionPercentage: 0, deductions: "" };

async function setIsSharedApi() {
  try {
    setIsShared(!isShared);
    await axios.put(
      `Truck/setShared?truckId=${
        truckId || load?.id
      }&isShared=${!load.sharedTruck}`
    );
    dispatch(getTruck(truckId || load?.id));
  } catch (e) {
    setIsShared(load.sharedTruck);
    Alerto(e);
  }
}
  useEffect(() => {
    if (load) {
      setIsShared(!!load.sharedTruck);
    }
  }, [load]);
  return (
    <CustomDialog open={show} onClose={onClose}>
      <div
        className={
          load && load.truckTypeId === 1
            ? classes.commissionTruck
            : classes.purchasesTruck
        }
      >
        <Grid container spacing={1}>
          <Grid item>
            <Typography style={warningCardHeader} className={classes.qty}>
              نوع النقلة: {load.truckTypeId === 1 ? "عمولة" : "مشتريات"}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.qty}>
              العدد الكلي:{" "}
              {!isNaN(totalItemsCount) && totalItemsCount === 0
                ? "صفر"
                : ConvertToArabicNumbers(totalItemsCount)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.qty}>
              المباع:{" "}
              {!isNaN(totalSoldItems) && totalSoldItems === 0
                ? "صفر"
                : ConvertToArabicNumbers(totalSoldItems)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.qty}>
              المتبقي:
              {!isNaN(totalRemainingCount) && totalRemainingCount === 0
                ? "صفر"
                : ConvertToArabicNumbers(Math.abs(totalRemainingCount))}
            </Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={isShared}
                  onChange={setIsSharedApi}
                  color="warning"
                />
              }
              label="نقلة مشتركة"
            />
          </Grid>
        </Grid>
        <Box mt={3}>
          {loadItems && loadItems.length > 0 && (
            <ItemsTable
              onCloseModal={onDepart}
              truckTypeId={load.truckTypeId}
              items={loadItems}
            />
          )}
        </Box>

        <AddItemsForm />
        {loadItems && loadItems.length > 0 && (
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ marginTop: "-30px" }}
          >
            <Grid item container md={10} spacing={2} sm={12}>
              {loadExpenses && loadExpenses.expenses?.length > 0 && (
                <Grid item>
                  <Typography className={classes.loadInfo}>
                    مدفوعات النقلة: {ConvertToArabicNumbers(totalExpenses)}
                  </Typography>
                </Grid>
              )}

              {load && load.truckTypeId === 2 && loadValue > 0 && (
                <Grid item>
                  <Typography className={classes.loadInfo}>
                    قيمة وارد النقلة:{" "}
                    {!isNaN(loadValue) ? ConvertToArabicNumbers(formatNumberIfDecimal(loadValue)) : ""}
                  </Typography>
                </Grid>
              )}

{deduction && deduction.length > 0 ? (
                <>
                  <Grid item>
                    <Typography className={classes.loadInfo}>
                      قيمة العمولة:{" "}
                      {totals.independentCommision > 0
                        ? ConvertToArabicNumbers(totals.independentCommision)
                        : "ﻻ يوجد"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.loadInfo}>
                      نسبة العمولة:{" "}
                      {" %" +
                        ConvertToArabicNumbers(totals.commisionPercentage)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.loadInfo}>
                      خصومات النقلة:{" "}
                      {totals.deductions > 0
                        ? ConvertToArabicNumbers(totals.deductions)
                        : "ﻻ يوجد"}
                    </Typography>
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Grid>

            <Grid
              item
              container
              md={3}
              justifyContent="space-around"
              className="me-auto mt-2"
              sm={12}
            >
              <Grid item>
                <Button
                  variant="text"
                  className="btnMore"
                  onClick={() => {
                    setTimeout(() => {
                      let bs = document.getElementById("00");
                      bs.focus();
                    }, 1);
                    setOpenExpenses(true);
                  }}
                  style={{
                    height: "38px",
                    boxShadow:
                      "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset",
                    padding: "25px 10px",
                  }}
                >
                  مصروفات
                </Button>
              </Grid>
              {load && load.truckTypeId === 1 && (
                <Grid item>
                  <Button
                    variant="text"
                    onClick={() => {
                      setTimeout(() => {
                        let bs = document.getElementById("0");
                        bs.focus();
                      }, 1);
                      setOpenDeductions(true);
                    }}
                    style={{
                      height: "38px",
                      boxShadow:
                        "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset",
                      padding: "25px 10px",
                      background: "#06CEA8",
                    }}
                  >
                    خصومات
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </div>
      <CustomDialog
        open={openDeductions}
        title="اضف خصومات النقلة"
        textAlign="center"
        onClose={() => setOpenDeductions(false)}
      >
        {!!openDeductions && (
          <AddDedeuctions closeDialog={() => setOpenDeductions(false)} />
        )}
      </CustomDialog>
      <AddExpenses open={openExpenses} onClose={() => setOpenExpenses(false)} />
    </CustomDialog>
  );
}

export default AddItems;
