import { Card, CardContent, Divider, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import ar from "date-fns/locale/ar-EG";
import { makeStyles } from "@material-ui/core/styles";
import { registerLocale } from "react-datepicker";
import ToolsInfo from "./partials/ToolsInfo";
import UserInfo from "./partials/userInfo";
import Form from "./partials/form";
import { dynamicReq } from "../Helpers/ajax";
import DamageTypeSwitch from "./partials/damageSwitch";
import { useState } from "react";
registerLocale("ar", ar);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
  },
  title: {
    fontSize: 24,
    marginBottom: 16,
  },
}));
export default function ToolsForm({
  targetApi,
  title,
  refresh,
  isDamage,
  haveAction,
}) {
  const targetPartner = useSelector((state) => state.data.targetPartner);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const classes = useStyles();

  return (
    <>
      <Card className="headerSearch m-0 pb-0">
        <CardContent className="pb-3">
          {!!isDamage && (
            <DamageTypeSwitch
              isAnonymous={isAnonymous}
              setIsAnonymous={setIsAnonymous}
            />
          )}
          <div className="center mb-3">
            {targetPartner?.id && <UserInfo partnerId={targetPartner.id} />}

            {targetPartner?.id && (
              <ToolsInfo haveAction={haveAction} partnerId={targetPartner.id} />
            )}
            <Typography className={classes.title} gutterBottom>
              {title}
            </Typography>
          </div>
          {/* <Divider style={{ marginBottom: 24, marginTop: 14 }} /> */}
          <Form
            isAnonymous={isAnonymous}
            isDamage={isDamage}
            onSubmit={(data) =>
              dynamicReq({
                api: targetApi,
                data: data,
                msg: "تمت الاضافة",
                type: "post",
                callback: refresh,
              })
            }
          />
        </CardContent>
      </Card>
    </>
  );
}