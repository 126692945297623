import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// layouts
import LoginForm from "./components/LoginForm";
import Page from "./components/Page";
// material
import { styled } from "@material-ui/styles";
import "./Register.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import MobileStep from "./components/MobileStep";
import axios from "axios";
import SiteStep from "./components/SiteStep";
import PasswordStep from "./components/PasswordStep";
import Alerto from "../../components/UI/toaster";
import "../../components/Layout.css";
import { getAppState, login, logOut } from "./authSlice";
import Body from "../../assets/images/Logo/Body2.webp";
import Eyes from "../../assets/images/Logo/Eyes.webp";
import Bottom from "../../assets/images/Logo/Bottom.webp";
import LeftEar from "../../assets/images/Logo/Left.webp";
import RightEar from "../../assets/images/Logo/Right.webp";
import Top from "../../assets/images/Logo/Top.webp";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  height: "84%",
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));
const useStyles = makeStyles((theme) => ({
  contentBox: {
    marginTop: "27px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "22px",
    },
    [theme.breakpoints.up("xl")]: {
      marginTop: "26px",
    },
  },
}));

// const ContentStyle = styled("div")(({ theme }) => ({
//   maxWidth: 480,
//   margin: "auto",
//   display: "flex",
//   minHeight: "100%",
//   flexDirection: "column",
//   justifyContent: "center",
//   //padding: theme.spacing(6, 0),
// }));

// ----------------------------------------------------------------------
const portalBase = "https://portal.owltecheg.com/api";
const isPortal = false;
// ----------------------------------------------------------------------
document.addEventListener("DOMContentLoaded", (event) => {
  const textElement = document.getElementById("animated-text");
  const text = "OWL IT SOLUTIONS";

  let index = 0;

  function type() {
    if (index < text.length) {
      textElement.innerHTML += text.charAt(index);
      index++;
      setTimeout(type, 150);
    }
  }

  type();
});

export default function Login() {
  const subState = useSelector((state) => state.auth.subs);
  const isLocal = useSelector((state) => state.auth.isLocal);
  const [isChecked, setIsChecked] = useState(true);
  //const token = localStorage.getItem("token");
  const [owls, setOwls] = useState([]);
  const [step, setStep] = useState(0);
  const [mobV, setMobV] = useState("");
  const [pass, setPass] = useState("");
  const [chosedSite, setChosedSite] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    //!!token && dispatch(logOut());
    dispatch(getAppState());
    setMobV(localStorage.getItem("phone"));
    setPass(localStorage.getItem("password"));
  }, []);
  function submitMobile(e) {
    e.preventDefault();
    axios
      .get(`${portalBase}/Router/OWLSClients?phoneNumber=${mobV}`)
      .then((res) => {
        setOwls(res.data);
        setStep((v) => v + 1);
      })
      .catch((e) => Alerto(e));
  }
  function handleSite(chosedSite) {
    setChosedSite(chosedSite);
    setStep((v) => v + 1);
  }
  function submitOwl(e) {
    e.preventDefault();
    axios
      .get(
        `${portalBase}/Router/URL?OWLsClientId=${chosedSite.owlsClientId}&phoneNumber=${mobV}&password=${pass}`
      )
      .then((res) => {
        let base = res.data;
        dispatch(login({ phone: mobV, password: pass, base })).then(
          ({ payload }) => {
            if (payload) {
              localStorage.setItem("chosedOwl", chosedSite.owlsClientId);
              localStorage.setItem("baseUrl", base);
              if (isChecked) {
                localStorage.setItem("phone", mobV);
                localStorage.setItem("password", pass);
              } else {
                localStorage.removeItem("phone");
                localStorage.removeItem("password");
              }
              localStorage.setItem("license", true);
            }
          }
        );
      })
      .catch((e) => Alerto(e));
  }
  const classes = useStyles();

  return (
    <div
      style={{
        minHeight: "100vh",
        width: "100%",
        overflow: "hidden",
        margin: "0",
        padding: "0",
      }}
    >
      <div style={{ height: "100%", width: "100%", position: "relative" }}>
        <div id="backgroundLogin"></div>

        <RootStyle title="تسجيل الدخول">
          <Grid container>
            <div className="h-100 w-100 center">
              {subState.bar && <div className="SubsMargin"></div>}

              <Grid item xs={12} md={8} lg={6} className={classes.contentBox}>
                <div>
                  <div className="owlLOGOBg">
                    <img className="owlBody" src={Body} alt="Body" />
                    <img className="owlEyes" src={Eyes} alt="Eyes" />
                    <img className="owlLeftEar" src={LeftEar} alt="Left Ear" />
                    <img
                      className="owlRightEar"
                      src={RightEar}
                      alt="Right Ear"
                    />
                    <img className="owlTop" src={Top} alt="Top" />
                    <img className="owlBottom" src={Bottom} alt="Bottom" />
                  </div>
                </div>

                {!isPortal ? (
                  <LoginForm disableLogin={!subState.state} />
                ) : (
                  <>
                    {step === 0 && (
                      <MobileStep
                        mobV={mobV}
                        setMobV={setMobV}
                        onSub={submitMobile}
                      />
                    )}
                    {step === 1 && <SiteStep sites={owls} onSub={handleSite} />}
                    {step === 2 && (
                      <PasswordStep
                        setPass={setPass}
                        onSub={submitOwl}
                        pass={pass}
                        label={chosedSite.owLsClientName}
                        isChecked={isChecked}
                        setIsChecked={setIsChecked}
                      />
                    )}
                  </>
                )}
              </Grid>
            </div>
          </Grid>
        </RootStyle>
      </div>
    </div>
  );
}
