import {
  Button,
  Card,
  CardContent,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";

import CollectingTable from "./components/CollectingTable";
import React from "react";
import axios from "axios";
import { indexOfId } from "../../utils";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Alerto from "../../components/UI/toaster";
import Mydate from "../../components/Helpers/MyDateParser";
import MainHeader from "../../components/mainHeader/MainHeader";
import { Container } from "react-bootstrap";

const useStyles = makeStyles({
  root: {
    marginTop: 24,
  },
  title: {
    fontSize: 24,
    marginBottom: 16,
  },
});
function CollectingAddresse() {
  const [addressName, setAddressName] = React.useState("");
  const [collectingAddresses, setCollectingAddresses] = React.useState([]);
  const user = useSelector((state) => state.auth.user);
  const fetchCollectingAddresses = () => {
    axios
      .get("/CollectingAddress")
      .then((res) => {
        setCollectingAddresses(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      fetchCollectingAddresses();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const submitCollectingAddress = () => {
    axios
      .post("/CollectingAddress", {
        name: addressName.trim(),
        adderId: user.UserId,
      })

      .then((res) => {
        if (res.status === 200) {
          setCollectingAddresses((old) => [res.data, ...old]);
          setAddressName("");
          toast.success("تم اضافة خط التحصيل بنجاح");
        } else {
          throw new Error("خط  موجود بالفعل");
        }
      })
      .catch((error) => {
        setAddressName("");

        toast.error("خط التحميل موجود بالفعل");
      });
  };

  const handleUpdate = (item) => {
    axios
      .put(`/CollectingAddress/${item.id}`, {
        ...item,
        name: item.name.trim(),
        userId: user.UserId,
        creationDate: Mydate(new Date()),
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success("تم حفظ التعديل");
          const index = indexOfId(collectingAddresses, item.id);
          let addressesArr = [...collectingAddresses];
          addressesArr[index].name = item.name;
          setCollectingAddresses(addressesArr);
        }
      })
      .catch((e) => {
        Alerto(e);
      });
  };

  const handleDelete = (item) => {
    axios.delete(`/CollectingAddress/${item.id}`).then((res) => {
      if (res.status === 200) {
        toast.error("تم الحذف");
        let addressesArr = [...collectingAddresses];
        addressesArr = addressesArr.filter((a) => {
          return a.id !== item.id;
        });
        setCollectingAddresses(addressesArr);
      }
    });
  };

  const classes = useStyles();

  return (
    <Container>
      <div className="my-4">
        <MainHeader title=" خطوط التحصيل" />
      </div>
      <div  className="headerSearch p-3 center container ">
          <div  className="center">
            <TextField
              label="خط التحصيل"
              size="small"
              variant="outlined"
              value={addressName}
              onChange={(e) => setAddressName(e.target.value)}
              style={{ width: "100%" }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  submitCollectingAddress();
                }
              }}
            />
            <Button
              variant="contained"
              color="primary"
              className="btnMore me-2"
              
              onClick={submitCollectingAddress}
            >
              {" "}
              اضف
            </Button>
          </div>
      </div>
      <div >
        {collectingAddresses.length > 0 && (
          <CollectingTable
            data={collectingAddresses}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
          />
        )}
      </div>
    </Container>
  );
}

export default CollectingAddresse;
