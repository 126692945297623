import { Button, Grid, TextField } from "@material-ui/core";
import React, { useState } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomDialog from "../../../components/CustomDialog";
import ar from "date-fns/locale/ar-EG";
import { makeStyles } from "@material-ui/core/styles";
import { registerLocale } from "react-datepicker";
import { useSelector } from "react-redux";

registerLocale("ar", ar);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

function EditImportItem({ item, open, onClose, onEditItemClicked }) {
  const tools = useSelector((state) => state.data.tools);
  const classes = useStyles();

  const [movementTool, setMovementTool] = useState("");
  const [count, setCount] = useState("");

  React.useEffect(() => {
    setMovementTool(item.movementTool);
    setCount(item.count);
  }, [item]);

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      action1={
        <Button
          onClick={onClose}
          color="secondary"
          variant="outlined"
          size="small"
          style={{ width: 56, height: 40 }}
        >
          الغاء
        </Button>
      }
      action2={
        <Button
          variant="contained"
          color="secondary"
          style={{ width: 56, height: 40, marginLeft: 16 }}
          size="small"
          onClick={() => {
            onEditItemClicked({
              id: item.id,
              movementTool,
              count,
            });
            onClose();
          }}
        >
          تعديل
        </Button>
      }
    >
      <div style={{ height: 100 }}>
        <form className={classes.root} noValidate autoComplete="off">
          <Grid container spacing={1}>
            <Grid item>
              <Autocomplete
                options={tools}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                getOptionSelected={(option, value) =>
                  option.value === value?.value
                }
                style={{ width: 200 }}
                autoHighlight
                autoComplete
                noOptionsText="لم يتم العثور على خيارات"
                onChange={(event, value) => {
                  if (value) {
                    setMovementTool(value);
                  }
                }}
                value={movementTool}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                  }
                }}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="العدة"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <TextField
                onChange={(e) => setCount(Number.parseFloat(e.target.value))}
                value={count}
                label="العدد"
                size="small"
                variant="outlined"
                style={{ width: 160 }}
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </CustomDialog>
  );
}
export default EditImportItem;
