import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Mydate from "../../../components/Helpers/MyDateParser";
import Alerto from "../../../components/UI/toaster";
import {
  showTransfer,
  transferRowsHandler,
  transferStateHandler,
} from "../loadsSlice";

const TransferSellingComp = ({
  showEtraBtn,
  setShowMassEdit,
  showMassEdit,
  isAuthorizedToReplace,
  setShowCombinedWeight,
  showCombinedWeight,
}) => {
  const show = useSelector((state) => state.loads.showTransfer);
  const [date, setDate] = useState(new Date());
  const [trucks, setTrucks] = useState([]);
  const [farmer, setFarmer] = useState("");
  const [chosedTruck, setChosedTruck] = useState("");
  const checkedList = useSelector((state) => state.loads.checkedTransferRows);
  const load = useSelector((state) => state.loads.load.data);
  const soldItems = useSelector((state) => state.loads.soldItems);
  const dispatch = useDispatch();
  function getTrucks() {
    axios
      .get(`Truck/getTrucksOfDate/${Mydate(date)}`)
      .then((res) => setTrucks(res.data))
      .catch((e) => Alerto(e));
  }
  function truckTransferHandler() {
    let data = {
      toTruckId: chosedTruck.truckId,
      sellings: checkedList,
    };
    axios
      .put("selling/transfer", data)
      .then((res) => {
        toast.success("تم النقل");
        window.location.reload();
      })
      .catch((e) => Alerto(e));
  }
  useEffect(() => {
    date && getTrucks();
    setFarmer("");
    setChosedTruck("");
  }, [date]);
  useEffect(() => {
    setChosedTruck("");
  }, [farmer]);
  function clearTransfer(params) {
    dispatch(transferStateHandler({ state: false, truckId: null }));
    dispatch(transferRowsHandler([]));
  }
  useEffect(() => {
    chosedTruck?.truckId
      ? dispatch(transferStateHandler({ state: true, truck: chosedTruck }))
      : clearTransfer();
  }, [chosedTruck]);
  return (
    <div className="col-12 container ">
      <Grid container style={{ marginBottom: "1rem", gap: "1rem" }}>
        {isAuthorizedToReplace && (
          <Button
            variant="contained"
            className="btnMore p-0 px-2"
            onClick={() => setShowMassEdit((prev) => !prev)}
            style={{
              boxShadow: "white 1px 1px 1px",
            }}
          >
            {showMassEdit ? "اخفاء" : "استبدال"}
          </Button>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={() => dispatch(showTransfer(!show))}
          style={{
            boxShadow: "white 1px 1px 1px",
          }}
          className="px-2"
        >
          {show ? "إخفاء" : "نقل المبيع"}
        </Button>

        {showEtraBtn && load && load.id && soldItems.length > 0 && (
          <Button
            variant="contained"
            component={Link}
            className="btnMore p-0 px-2"
            style={{
              boxShadow: "white 1px 1px 1px",
            }}
            to={`/invoice/${load.id}`}
          >
             فاتورة العميل
          </Button>
        )}
        {!!setShowCombinedWeight && (
          <FormControlLabel
            style={{ color: "white" }}
            control={
              <Checkbox
                color="primary"
                checked={showCombinedWeight}
                onChange={(event) =>
                  setShowCombinedWeight(event.target.checked)
                }
              />
            }
            label="م وزن"
          />
        )}
      </Grid>
      {show && (
        <div
          style={{ gap: "10px" }}
          className=" headerSearch px-4 m-0 mb-2 py-3 pb-4 "
        >
          <Grid
            item
            xs={12}
            md={3}
            lg={2}
            className="d-inline-block  col-12 me-2 "
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Basic example"
                value={date}
                onChange={(date) => setDate(date)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label="التاريخ"
                    className="w-100 mx-auto"
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={3}
            className="d-inline-block m-2 col-12"
          >
            <Autocomplete
              options={trucks}
              getOptionSelected={(option, value) =>
                option.farmerName === value.farmerName
              }
              getOptionLabel={(option) =>
                option.farmerName ? option.farmerName : ""
              }
              autoHighlight
              size="small"
              noOptionsText="لم يتم العثور على خيارات"
              onChange={(e, value) => {
                if (value) {
                  setFarmer(value);
                } else {
                  setFarmer("");
                }
              }}
              className="w-100"
              value={farmer}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="اسم العميل"
                  variant="outlined"
                  size="small"
                  className="w-100"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            lg={1}
            className="d-inline-block m-2 col-12"
          >
            <Autocomplete
              className="w-100 col-12"
              options={farmer?.trucks || []}
              getOptionSelected={(option, value) =>
                option.truckNumber === value.truckNumber
              }
              getOptionLabel={(option) =>
                option.truckNumber ? option.truckNumber.toString() : ""
              }
              autoHighlight
              size="small"
              noOptionsText="لم يتم العثور على خيارات"
              onChange={(e, value) => {
                if (value) {
                  setChosedTruck(value);
                } else {
                  setChosedTruck("");
                }
              }}
              value={chosedTruck}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="النقلة"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Grid>

          <Grid
            xs={12}
            md={4}
            
            className="d-inline-block m-2 col-lg-12"
            onClick={() =>
              (!checkedList[0] || !chosedTruck?.truckId) &&
              toast.error("يرجي اكمال بيانات التحويل")
            }
            item
          >
            <Button
              disabled={!checkedList[0] || !chosedTruck?.truckId}
              variant="contained"
              onClick={truckTransferHandler}
              className="btnMore center"
              style={{
                marginBottom: "-16px",
              }}
            >
              اتمام عملية النقل
            </Button>
          </Grid>
        </div>
      )}
    </div>
  );
};
export default TransferSellingComp;
