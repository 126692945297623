import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import Mydate from "../../components/Helpers/MyDateParser";
import ar from "date-fns/locale/ar-EG";
import { makeStyles } from "@material-ui/core/styles";
import { registerLocale } from "react-datepicker";
import axios from "axios";
import Alerto from "../../components/UI/toaster";
import { Autocomplete } from "@material-ui/lab";
import TreasuryExpenseTable from "./components/TreasuryExpenseTable";
import TreasuryDepositTable from "./components/TreasuryDepositTable";
import FromToDateHandler from "./components/FromToDateHandler";
import MainHeader from "../../components/mainHeader/MainHeader";
const actions = [
  { label: "اضافة", id: "1", state: true },
  { label: "تعديل", id: "2", state: false },
  { label: "حذف", id: "3", state: false },
];
const categories = [
  { label: "التحصيل", id: "1", state: true },
  { label: "المصروفات ", id: "2", state: false },
];

registerLocale("ar", ar);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  radio: {
    margin: "0vh 0vw 0vh 20px",
    display: "block",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: 12,
  },
  SearchForm: {
    width: "20vw",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    "& > *": {
      marginRight: 12,
    },
  },
}));

function TreasuryHistory() {
  const [Deposits, setDeposits] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [fromTime, setFromTime] = useState(new Date());
  const [toTime, setToTime] = useState(new Date());
  const [parsedFrom, setParsedFrom] = useState(Mydate(new Date()));
  const [parsedTo, setParsedTo] = useState(Mydate(new Date()));
  const [Namesearch, setNameSearch] = useState("");
  const [Actions, setActions] = useState(actions);
  const [categ, setCateg] = useState(categories);
  const checkhandler = (id) => {
    let element = categ.filter((ele) => ele.id == id);
    if (!element[0].state) {
      return false;
    }
    return true;
  };
  const handleCatg = (id) => {
    let arr = [...categ];
    let element = arr.filter((ele) => ele.id == id);
    let index = arr.indexOf(element[0]);

    element[0].state = !element[0].state;
    arr[index] = element[0];

    setCateg(arr);
  };
  const checkhandler2 = (id) => {
    let element = Actions.filter((ele) => ele.id == id);
    if (!element[0].state) {
      return false;
    }
    return true;
  };
  const handleAction = (id) => {
    let arr = [...Actions];
    let element = arr.filter((ele) => ele.id == id);
    let index = arr.indexOf(element[0]);
    element[0].state = !element[0].state;
    arr[index] = element[0];
    setActions(arr);
  };
  const getUsers = () => {
    axios
      .get(`/Security/users`)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  React.useEffect(() => {
    getUsers();
  }, []);
  // let d = "Sun May 29 2022 00:07:22 GMT+0200 (Eastern European Standard Time)";
  const DepositsHandler = async () => {
    try {
      const res = await axios.post(`History/Treasury/Deposits`, {
        recorderId: user.userId,
        from: parsedFrom,
        to: parsedTo,
        added: Actions[0].state,
        updated: Actions[1].state,
        deleted: Actions[2].state,
      });
      setDeposits(res.data);
    } catch (err) {
      Alerto(err);
    }
  };
  const ExpensesHandler = async () => {
    try {
      const res = await axios.post(`History/Treasury/Expenses`, {
        recorderId: user.userId,
        from: parsedFrom,
        to: parsedTo,
        added: Actions[0].state,
        updated: Actions[1].state,
        deleted: Actions[2].state,
      });
      setExpenses(res.data);
    } catch (err) {
      Alerto(err);
    }
  };

  const ResetAll = () => {
    setDeposits([]);
    setExpenses([]);
  };
  const timeRef = useRef();
  React.useEffect(() => {
    clearTimeout(timeRef.current);
    timeRef.current = setTimeout(() => {
      ResetAll();

      if (categ[0].state) {
        DepositsHandler();
      }
      if (categ[1].state) {
        ExpensesHandler();
      }
    }, 1000);
    return () => clearTimeout(timeRef.current);
  }, [categ, Actions, user, fromTime, toTime]);
  const classes = useStyles();
  const manageFromDate = (d) => {
    setFromTime(d);
    setParsedFrom(Mydate(d));
  };
  const manageToDate = (d) => {
    setToTime(d);
    setParsedTo(Mydate(d));
  };
  return (
    <div className={classes.root}>
      <div className="my-2">
        <MainHeader title="سجل الخزينة" />
      </div>
      <CardContent className="headerSearch m-0">
        <Grid
          container
          spacing={2}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <FromToDateHandler
            toTime={toTime}
            fromTime={fromTime}
            handleFrom={manageFromDate}
            handleTo={manageToDate}
          />
          <Grid item xs={12} md={3}>
            <Autocomplete
              size="small"
              className="w-100"
              options={users}
              getOptionSelected={(option, value) => {
                return option.value === value?.value;
              }}
              getOptionLabel={(option) =>
                option.partnerName ? option.partnerName : ""
              }
              autoHighlight
              autoComplete
              noOptionsText="لم يتم العثور على خيارات"
              onChange={(event, value) => {
                if (value) {
                  setUser(value);
                } else {
                  setUser({});
                }
              }}
              value={user}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="بحث بأسم المستخدم"
                  variant="outlined"
                  className="w-100"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              placeholder="بحث بأسم العميل"
              className="w-100"
              size="small"
              value={Namesearch}
              onChange={(e) => setNameSearch(e.target.value)}
            />
          </Grid>

          <Grid className={classes.radio}>
            <FormControl
              component="fieldset"
              style={{ marginBottom: 4, display: "block" }}
            >
              <RadioGroup
                row
                onClick={(event) => {
                  handleCatg(event.target.value);
                }}
              >
                {categories.map((ele) => (
                  <FormControlLabel
                    value={ele.id}
                    control={
                      <Radio
                        style={{
                          color: "#fbc02d",
                        }}
                        checked={checkhandler(ele.id)}
                      />
                    }
                    label={ele.label}
                  />
                ))}
              </RadioGroup>
              <RadioGroup
                row
                onClick={(event) => {
                  handleAction(event.target.value);
                }}
              >
                {actions.map((ele) => (
                  <FormControlLabel
                    value={ele.id}
                    control={
                      <Radio
                        style={{
                          color: "#fbc02d",
                        }}
                        checked={checkhandler2(ele.id)}
                      />
                    }
                    label={ele.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      {expenses[0] && (
        <TreasuryExpenseTable search={Namesearch} data={expenses} />
      )}
      {Deposits[0] && (
        <TreasuryDepositTable search={Namesearch} data={Deposits} />
      )}
    </div>
  );
}

export default TreasuryHistory;
