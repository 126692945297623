import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DatePicker from "react-datepicker";
import DepositsTable from "./components/DepositsTable";
import SearchIcon from "@material-ui/icons/Search";
import Total from "../../components/UI/Total";
import ar from "date-fns/locale/ar-EG";
import { getAllDeposits } from "./treasurySlice";
import { makeStyles } from "@material-ui/core/styles";
import { registerLocale } from "react-datepicker";
import Mydate from "../../components/Helpers/MyDateParser";
import { Container } from "react-bootstrap";
import MainHeader from "../../components/mainHeader/MainHeader";

registerLocale("ar", ar);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: 12,
  },
  SearchForm: {
    marginTop: 12,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    "& > *": {
      marginRight: 12,
    },
  },
}));

function DepositsDetails() {
  const [date, setDate] = useState(new Date());

  const allDeposits = useSelector((state) => state.treasury.allDeposits);
  const dispatch = useDispatch();
  const fetchdata = () => {
    dispatch(getAllDeposits(Mydate(date)));
  };
  React.useEffect(() => {
    fetchdata();
  }, [date]);

  const total = allDeposits.reduce((acc, val) => {
    return acc + val.price;
  }, 0);

  const [searchQuery, setSearchQuery] = useState("");

  const classes = useStyles();
  return (
    <div
      className=" w-100  p-0 py-4 pb-5 position-relative  "
      style={{
        background: "#534d3b",
        marginTop: " -15px !important",
        minHeight: "107vh",
        outline: " 8px solid #534d3b",
      }}
    >
      <Container>
        <div className="my-4">
          <MainHeader title=" التحصيل" />
        </div>
        <CardContent className="headerSearch m-0">
          <Box className="center justify-content-between px-3">
            <Grid className="ms-1">
              <DatePicker
                locale="ar"
                selected={date}
                onChange={(d) => setDate(d)}
                dateFormat="dd/MM/yyyy"
                customInput={
                  <TextField size="small" variant="outlined" id="dateTime" />
                }
              />
            </Grid>
            <TextField
              placeholder="بحث..."
              size="small"
              style={{ width: 220, backgroundColor: "#FFF" }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </CardContent>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {total !== 0 && <Total value={total} label="اجمالي تحصيل اليوم" />}
        </div>
        {allDeposits && allDeposits.length > 0 && (
          <DepositsTable
            refresh={fetchdata}
            deposits={allDeposits}
            searchQuery={searchQuery}
          />
        )}
       
      </Container>
    </div>
  );
}

export default DepositsDetails;
