import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import LogoImage from "../../../components/LogoImage";
import React, { useState, useEffect, useRef } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ar from "date-fns/locale/ar-EG";
import { makeStyles } from "@material-ui/core/styles";
import { parseISO } from "date-fns/esm";
import { registerLocale } from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import Mydate from "../../../components/Helpers/MyDateParser";

import Alerto from "../../../components/UI/toaster";
import axios from "axios";
import { infoCardHeader } from "../../../components/styles";
import { ConvertToArabicNumbers } from "../../../utils";
import { useParams } from "react-router-dom";
import { InputSorting } from "../../../components/Helpers/InsputSorting";
import { fetchPartners } from "../../main-data/dataSlice";
import { getDepositCategories } from "../treasurySlice";
import { Container } from "react-bootstrap";
import InfoIcon from "@mui/icons-material/Info";


import ToolsInfo from "../../../components/ToolsForm/partials/ToolsInfo";
import { useHistory } from "react-router-dom";

registerLocale("ar", ar);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
    backgroundColor: "white",
  },
  title: {
    fontSize: 24,
    marginBottom: 16,
  },
  detailArrow: {
    position: "absolute",
    top: 0,
  },
  formContent: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    "& > *": {
      marginRight: 12,
    },
  },

  loadInfo: {
    padding: "8px 12px",
    borderRadius: 20,
    backgroundColor: "#EEF66C",
    boxShadow:
      "0 4px 20px  rgba(238, 246, 108, 0.3), 0 -7px -10px -5px rgba(238, 246, 108, 0.09)",
    "&:hover": {
      boxShadow: "0 0 0",
    },
  },
  qty: {
    background: "#f3c637 !important",
    color: "black !important",
    boxShadow:
      "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset",
    height: "50px",
    padding: "0 20px",
    width: "content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  para: {
    margin: "0 0 0 10px",
  },
  close: {
    position: "absolute",
    top: 16,
    right: 16,
    color: theme.palette.grey[700],
    height: 36,
    width: 36,
    backgroundColor: "rgba(216, 218, 223, 0.5)",
    "&:hover": {
      backgroundColor: "#D8DADF",
    },
  },
}));
const handleMoney = async (partner) => {
  try {
    const remain = await axios.get(
      `MovementTools/reports/Remaining/price/${partner.id}`
    );
    const buyer = await axios.get(
      `MoneyCurrentAccountReport/debtDetails/buyer/${partner.id}`
    );
    const client = await axios.get(
      `MoneyCurrentAccountReport/debtDetails/client/${partner.id}`
    );
    const tool = await axios.get(
      `MovementTools/reports/Remaining/${partner.id}`
    );
    const totalToolsCount =
      tool.data?.length > 0 &&
      tool.data.reduce((acc, val) => {
        return acc + val.count;
      }, 0);
    let obj = {
      remain: remain.data,
      tool: totalToolsCount,
      inAdvance: partner.partnerTypeId == 2 ? null : client.data.totalLoans,
      truckInvoice:
        partner.partnerTypeId == 2 ? null : client.data.totalInvoices,
      totalLoans: partner.partnerTypeId == 1 ? null : buyer.data.totalLoans,
      totalInvoices:
        partner.partnerTypeId == 1 ? null : buyer.data.totalInvoices,
    };

    return obj;
  } catch (e) {
    Alerto(e);
  }
};

function DepositForm({ onSubmit, deposit, isUpdate, onUpdateClicked }) {
  const userId = useSelector((state) => state.auth.user.UserId);
  const userName = useSelector(state => 
    state.auth.user["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"]
  );
  const [partner, setPartner] = useState("");
  console.log(
    "🚀 ~ file: DepositForm.js:138 ~ DepositForm ~ partner:",
    partner
  );
  const [depositDate, setDepositDate] = useState(new Date());
  const [category, setCategory] = useState("");
  const role = localStorage.getItem("role");
  const [categoryes, setCategoryes] = useState([]);
  const [price, setPrice] = useState("");
  const [allow, setAllow] = useState("");
  const [note, setNote] = useState("");
  const [tool, setTool] = useState({});
  const [tools, setTools] = useState({});
  const [checkDisplayTool, setCheckDisplayTool] = useState(false);
  const [toolCount, setToolCount] = useState("");
  const { push } = useHistory();
  const depositCategories = useSelector(
    (state) => state.treasury.depositCategories
  );

  const { partnerId, factor } = useParams();
  const fetchTools = async () => {
    try {
      const res = await axios.get("MovementTools");
      setTools(res.data);
    } catch (e) {
      Alerto(e);
    }
  };
  useEffect(() => {
    dispatch(fetchPartners());
    dispatch(getDepositCategories());
    fetchTools();
  }, []);
  useEffect(() => {
    let cat = [];
    if (partner.partnerTypeId) {
      if (partner.partnerTypeId === 1 && depositCategories.client) {
        cat = depositCategories.client;
      }
      if (partner.partnerTypeId === 2 && depositCategories.buyer) {
        cat = depositCategories.buyer;
        let target = cat.filter((ele) => ele.name == "نقدية");
        setCategory(target[0]);
      }
      if (partner.partnerTypeId === 3 && depositCategories.clientBuyer) {
        cat = depositCategories.clientBuyer;
        let target = cat.filter((ele) => ele.name == "نقدية");
        setCategory(target[0]);
      }
      if (partner.isEmployee && depositCategories.employee) {
        cat = [...cat, ...depositCategories.employee];
      }
    }
    setCategoryes(cat);
  }, [deposit, partner, depositCategories]);
  const partners = useSelector((state) => state.data.partners);
  React.useEffect(() => {
    if (partnerId && partners.length > 0) {
      const targetPartner = partners.find((p) => {
        return p.id == partnerId;
      });
      if (targetPartner && targetPartner.id) {
        handleMoney(targetPartner).then((res) => {
          setMoney(res);
        });
        setPartner(targetPartner);
        if (categoryes[0]) {
          const target = categoryes.find((ele) => ele.id == factor);
          if (target) {
            setCategory(target);
          }
        }
      }
    }
  }, [partnerId, partners, categoryes]);

  const [isPrintEnabled, setIsPrintEnabled] = useState(false);
  const inpRef = useRef();
  let updatedInvoices = 0;
  let updatedLoans = 0;
  let updatedInAdvance = 0;

  const handleSubmit = async () => {
    let data = { moneyDepositDto: null, toolDto: null };

    // احضار شعار الشركة إذا كانت الطباعة مفعلة
    let logoData = "";
    if (isPrintEnabled) {
      try {
        const logoResponse = await axios.get("/Setting/logoImage");
        logoData = logoResponse.data;
      } catch (err) {
        console.error("Failed to fetch logo:", err);
      }
    }

    if (partner) {
      if (category?.id) {
        // تنفيذ العمليات بناءً على نوع الدفع

        if (category.name === "نقدية") {
          updatedInvoices = money.totalInvoices - (price + allow); // طرح فواتير البيع من المبلغ المدفوع
        } else if (category.name === "سلفه") {
          updatedLoans = money.totalLoans - price; // طرح المبلغ المدفوع من السلف
        } else if (category.name === "عملة مقدمة") {
          updatedInAdvance = money.inAdvance - price; // طرح المبلغ المدفوع من العملة المقدمة
        }

        data.moneyDepositDto = {
          partnerId: partner.id,
          depositDate: Mydate(depositDate),
          price,
          allow: allow > 0 ? allow : 0,
          note,
          treasuryTypeId: category.id,
          depositCategoryName: category.name,
          adderId: userId,
        };
      }

      if (tool?.id) {
        // معالجة عدد العدة

        data.toolDto = {
          partnerId: partner.id,
          importingDate: Mydate(depositDate),
          count: toolCount,
          toolId: tool.id,
          adderId: userId,
        };
      }
    }
    let updatedToolCount = money.tool - toolCount;
    if (isPrintEnabled) {
      const invoiceHTML = `
        
   <!DOCTYPE html>
<html dir="rtl">
  <head>
    <title>ايصال ${partner?.name || "-"}</title>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.1/html2pdf.bundle.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js"></script>
    <style>
      
       @page {
        size: 80mm auto;  /* إجبار العرض 80mm والارتفاع تلقائي */
        margin: 0mm;      /* إزالة الهوامش */
      }
      
      @media print {
        html, body {
          width: 80mm;
          margin: 0;
          padding: 0;
        }
          
         
        .action-button {
          display: none !important; 
          opacity: 0 !important;     
          visibility: hidden !important; 
          position: absolute !important; 
          pointer-events: none !important; 
        }
      
        
        .receipt-container {
          width: 76mm; /* عرض أقل قليلاً من عرض الصفحة لتجنب التجاوز */
          margin: 2mm;
          page-break-after: always;
          opacity: 1 !important;
          visibility: visible !important;
          display: block !important;
          position: relative !important;
        }
      }
      
      @font-face {
        font-family: 'Noto Naskh Arabic';
        src: url('https://fonts.googleapis.com/css2?family=Noto+Naskh+Arabic:wght@400;700&display=swap');
      }
      
      body {
        font-family: 'Noto Naskh Arabic', Arial, sans-serif;
        width: 76mm;
        margin: 0 auto;
        padding: 8px;
        background: white;
        color: #2d3748;
        font-size: 13px;
      }
      
      .receipt-container {
        border: 1px solid #2d3748;
        border-radius: 4px;
        padding: 8px;
        margin: 0 auto;
        background: #fff;
      }
      
      .header {
        text-align: center;
        margin-bottom: 10px;
        border-bottom: 1px dashed #2d3748;
        padding-bottom: 6px;
      }
      
      .logo-container {
        text-align: center;
        margin-bottom: 4px;
        padding: 4px;
      }
      
      .logo-container img {
        max-width: 265px;
        max-height: 150px;
        object-fit: contain;
      }
      
      .receipt-title {
        font-size: 16px;
        font-weight: bold;
        margin: 3px 0;
        color: #1a365d;
      }
      
      .datetime {
        background: #f7fafc;
       font-weight: bold;
        border-radius: 4px;
        font-size: 12px;
        display: inline-block;
      }
      
      .partner-name {
        font-size: 17px;
        font-weight: bold;
        color: #2c5282;
        margin: 3px 0;
        padding: 3px;
         border-bottom: 1px solid #bee3f8;
        
       
      }
      
      .content {
        margin-bottom: 6px;
      }
      
      .financial-summary {
        background: #f7fafc;
        border: 1px solid #e2e8f0;
        border-radius: 4px;
        padding: 6px;
        margin: 4px 0;
      }
      
      .financial-group {
        margin: 4px 0;
      }
      
      .financial-item {
        display: flex;
        justify-content: space-between;
        padding: 4px 0;
        border-bottom: 1px dashed #e2e8f0;
      }
      
      .financial-item:last-child {
        border-bottom: none;
      }
      
      .label {
        font-weight: bold;
        color: #4a5568;
        font-size: 14px;
        margin-right: 10px;
      }
      
      .value {
        color: #2d3748;
        font-weight: 600;
        font-size:14px;
        margin-left: 10px;
      }

      .payment-value {
        color: #2d3748;
        font-weight: 600;
        font-size:14px;
        margin-left: 50px;
      }
      
      .payment-details {
        background: #f8fafc;
        border-radius: 4px;
        padding: 6px;
        margin: 6px 0;
      }
      
      .payment-amount {
        font-size: 18px;
        font-weight: bold;
        color: #2b6cb0;
        text-align: center;
        padding: 6px;
        background: #fff;
        border-radius: 4px;
       margin-left: 45px;
      }
      
      .footer {
        margin-top: 14px;
        border-top: 1px dashed #2d3748;
        padding-top: 8px;
      }
      
      .signature-section {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 8px;
      }
      
      .signature-box {
        text-align: center;
        flex: 1;
      }
      
      .signature-title {
        font-weight: bold;
        color: #4a5568;
        margin-bottom: 4px;
        font-size: 14px;
      }
      
      .signature-line {
        border-bottom: 1px solid #4a5568;
        width: 100px;
        margin: 4px auto;
      }
      
      .signature-name {
        font-size: 13px;
        color: #718096;
        font-weight: bold;
      }
      
      .receipt-footer {
        text-align: center;
        margin-top: 4px;
        font-size: 10px;
        color: #718096;
        font-weight: bold;

      }
      
      .status-tag {
        display: inline-block;
        padding: 2px 4px;
        border-radius: 2px;
        font-size: 12px;
        font-weight: bold;
      }
      
      .status-positive {
        background: #c6f6d5;
        color: #276749;
      }
      
      .status-negative {
        background: #fed7d7;
        color: #9b2c2c;
      }
      
      .status-neutral {
        background: #e2e8f0;
        color: #2d3748;
      }

      h3 {
        font-size: 14px;
        margin: 0 0 6px 0;
        color: #2c5282;
      }
      .action-button {
        position: fixed;
        left: 20px;
        width: 220px;
        height: 80px;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-family: 'Noto Naskh Arabic', Arial, sans-serif;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        font-size: 25px;
        transition: all 0.3s ease;
      }

      .print-button {
        top: 20px;
        background-color: #2b6cb0;
      }

      .pdf-button {
        top: 120px;
        background-color: #38a169;
      }

      .image-button {
        top: 220px;
        background-color: #805ad5;
      }

      .back-button {
        top: 320px;
        background-color: #718096;
      }

      .action-button:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 6px rgba(0,0,0,0.2);
      }

      .print-button:hover { background-color: #2c5282; }
      .pdf-button:hover { background-color: #2f855a; }
      .image-button:hover { background-color: #6b46c1; }
      .back-button:hover { background-color: #4a5568; }

     
    </style>
    <script>

  async function savePDF() {
  try {
    
    const buttons = document.querySelectorAll('.action-button');
    
    buttons.forEach(button => button.style.display = 'none');
    
    const receipt = document.querySelector('.receipt-container');
    
   
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    
    // Get receipt dimensions
    const receiptWidth = receipt.offsetWidth;
    const receiptHeight = receipt.offsetHeight;
    
    // Convert px to mm (assuming 96 DPI)
    const mmWidth = (receiptWidth * 25.4) / 96;
    const mmHeight = (receiptHeight * 25.4) / 96;
    
    // Configure PDF worker
    const worker = html2pdf().from(receipt);
    worker.set({
      margin: 0,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { 
        scale: 2,
        useCORS: true,
        letterRendering: true,
        width: receiptWidth,
        height: receiptHeight
      },
      jsPDF: { 
        unit: 'mm',
        format: [mmWidth, mmHeight], // Use exact receipt dimensions
        orientation: 'portrait',
        compress: true,
        precision: 16
      }
    });

    if (isMobile) {
      // For mobile devices, create download link
      const blob = await worker.output('blob');
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = "receipt-${partner?.name || ""}-${new Date().toISOString().slice(0,10)}.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      // For desktop, try using FileSystem Access API
      try {
        const handle = await window.showSaveFilePicker({
          suggestedName: "receipt-${partner?.name || ""}-${new Date().toISOString().slice(0,10)}.pdf",
          types: [{
            description: 'PDF Files',
            accept: {'application/pdf': ['.pdf']}
          }],
        });
        
        const blob = await worker.output('blob');
        const writable = await handle.createWritable();
        await writable.write(blob);
        await writable.close();
      } catch (error) {
        if (error.name !== 'AbortError') {
          // Fallback to traditional download if FileSystem Access API fails
          const blob = await worker.output('blob');
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = "receipt-${partner?.name || ""}-${new Date().toISOString().slice(0,10)}.pdf";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }
      }
    }
    
    // Show buttons after completion
    buttons.forEach(button => button.style.display = 'flex');
    
  } catch (error) {
    console.error('Error generating PDF:', error);
    alert('حدث خطأ أثناء حفظ PDF. الرجاء المحاولة مرة أخرى');
    document.querySelectorAll('.action-button')
      .forEach(button => button.style.display = 'flex');
  }
}

// تحديث دالة حفظ الصورة
async function saveImage() {
  try {
    // إخفاء الأزرار قبل التقاط الصورة
    const buttons = document.querySelectorAll('.action-button');
    buttons.forEach(button => button.style.display = 'none');
    
    const receipt = document.querySelector('.receipt-container');
    
    // تحقق مما إذا كان الجهاز محمولاً
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    
    // توليد الصورة
    const canvas = await html2canvas(receipt, {
      scale: 2,
      useCORS: true,
      logging: false,
      allowTaint: true,
      letterRendering: true,
      removeContainer: true
    });
    
    // تحويل Canvas إلى Blob
    const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png', 1.0));
    
    if (isMobile) {
      // على الأجهزة المحمولة، قم بإنشاء رابط تحميل
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = "receipt-${partner?.name || ""}-${new Date().toISOString().slice(0,10)}.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      // على سطح المكتب، استخدم FileSystem Access API
      try {
        const handle = await window.showSaveFilePicker({
          suggestedName: "receipt-${partner?.name || ""}-${new Date().toISOString().slice(0,10)}.png",
          types: [{
            description: 'PNG Images',
            accept: {'image/png': ['.png']}
          }],
        });
        
        const writable = await handle.createWritable();
        await writable.write(blob);
        await writable.close();
      } catch (error) {
        if (error.name !== 'AbortError') {
          // إذا فشل FileSystem Access API، استخدم طريقة التحميل التقليدية
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = "receipt-${partner?.name || ""}-${new Date().toISOString().slice(0,10)}.png";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }
      }
    }
    
    // إظهار الأزرار بعد الانتهاء
    buttons.forEach(button => button.style.display = 'flex');
    
  } catch (error) {
    console.error('Error generating image:', error);
    alert('حدث خطأ أثناء حفظ الصورة. الرجاء المحاولة مرة أخرى');
    document.querySelectorAll('.action-button')
      .forEach(button => button.style.display = 'flex');
  }
}

      function goBack() {
        window.close();
      }

      // Add print event listener
      window.onload = function() {
        const mediaQueryList = window.matchMedia('print');
        mediaQueryList.addListener(function(mql) {
          if (mql.matches) {
            document.title = "80mm Receipt";
          }
        });
      };
    </script>
  </head>
  <body>
          <button onclick="window.print()" class="action-button print-button">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <polyline points="6 9 6 2 18 2 18 9"></polyline>
            <path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"></path>
            <rect x="6" y="14" width="12" height="8"></rect>
          </svg>
          طباعة
        </button>

        <button onclick="savePDF()" class="action-button pdf-button">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
            <polyline points="14 2 14 8 20 8"></polyline>
            <line x1="12" y1="18" x2="12" y2="12"></line>
            <line x1="9" y1="15" x2="15" y2="15"></line>
          </svg>
          حفظ PDF
        </button>

        <button onclick="saveImage()" class="action-button image-button">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"/>
            <circle cx="8.5" cy="8.5" r="1.5"/>
            <polyline points="21 15 16 10 5 21"/>
          </svg>
          حفظ كصورة
        </button>

        <button onclick="goBack()" class="action-button back-button">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <line x1="19" y1="12" x2="5" y2="12"></line>
            <polyline points="12 19 5 12 12 5"></polyline>
          </svg>
          رجوع
      </button>

    <div class="receipt-container">
      <div class="header">
        <div class="logo-container">
          ${
            logoData
              ? `<img src="data:image/png;base64,${logoData}" alt="شعار الشركة" />`
              : ""
          }
        </div>
      
        <div class="partner-name"> ${partner?.name || "-"}</div>
        <div class="datetime">
          <span> ${new Date().toLocaleDateString("ar-EG")}</span>
          <span> - </span>
          <span> ${new Date().toLocaleTimeString("ar-EG")}</span>
        </div>
         
      </div>
      
      <div class="content">
        ${
          money && partner?.id
            ? `
        <div class="financial-summary">
          <h3 style="margin: 0 0 10px 0; color: #2c5282;display: flex;
        justify-content: center;
        align-items: center;">الحساب الحالي</h3>
          

          ${
            money.totalLoans != null && money.totalInvoices != null
              ? `
          <div class="financial-group">
            <div class="financial-item">
              <span class="label">فواتير المبيعات المستحقة :</span>
              <span class="value ${
                money.totalInvoices > 0
                  ? "status-positive"
                  : money.totalInvoices < 0
                  ? "status-negative"
                  : "status-neutral"
              }">
                ${ConvertToArabicNumbers(
                  Math.abs(Math.round(money.totalInvoices))
                )}
                ${Math.round(money.totalInvoices) > 0 ? "مدين" : ""}
                ${Math.round(money.totalInvoices) < 0 ? "دائن" : ""}
                ${money.totalInvoices === 0 ? "لايوجد" : ""}
              </span>
            </div>

             ${
               money.totalLoans
                 ? `
            <div class="financial-item">
              <span class="label">رصيد السلف المستحقة :</span>
              <span class="value ${
                money.totalLoans > 0
                  ? "status-positive"
                  : money.totalLoans < 0
                  ? "status-negative"
                  : "status-neutral"
              }">
                ${ConvertToArabicNumbers(
                  Math.abs(Math.round(money.totalLoans))
                )}
                ${Math.round(money.totalLoans) > 0 ? "مدين" : ""}
                ${Math.round(money.totalLoans) < 0 ? "دائن" : ""}
                ${money.totalLoans === 0 ? "لايوجد" : ""}
              </span>
            </div>`
                 : ""
             }
          </div>`
              : ""
          }
          ${
            money.truckInvoice != null && money.inAdvance != null
              ? `
          <div class="financial-group">
         
            <div class="financial-item">
              <span class="label">فواتير النقلات  : </span>
              <span class="value ${
                money.truckInvoice > 0
                  ? "status-positive"
                  : money.truckInvoice < 0
                  ? "status-negative"
                  : "status-neutral"
              }">${ConvertToArabicNumbers(
                  Math.abs(Math.round(money.truckInvoice))
                )}
                ${Math.round(money.truckInvoice) > 0 ? "مدين" : ""}
                ${Math.round(money.truckInvoice) < 0 ? "دائن" : ""}
                ${money.truckInvoice === 0 ? "لايوجد" : ""}
                 </span>
            </div>

            <div class="financial-item">
              <span class="label">رصيد العملة المقدمة :</span>
                <span class="value ${
                  money.inAdvance > 0
                    ? "status-positive"
                    : money.inAdvance < 0
                    ? "status-negative"
                    : "status-neutral"
                }">${ConvertToArabicNumbers(
                  Math.abs(Math.round(money.inAdvance))
                )}
                ${Math.round(money.inAdvance) > 0 ? "مدين" : ""}
                ${Math.round(money.inAdvance) < 0 ? "دائن" : ""}
                ${money.inAdvance === 0 ? "لايوجد" : ""}
              </span>
            </div>
          </div>`
              : ""
          }
               ${
                 money.tool
                   ? `
          <div class="financial-group">
            <div class="financial-item">
              <span class="label">رصيد العدة المستحقة :</span>
              <span class="value ${
                money.tool > 0
                  ? "status-positive"
                  : money.tool < 0
                  ? "status-negative"
                  : "status-neutral"
              }">
                ${ConvertToArabicNumbers(Math.abs(Math.round(money.tool)))}
                ${Math.round(money.tool) > 0 ? "مدين" : ""}
                ${Math.round(money.tool) < 0 ? "دائن" : ""}
                ${money.tool === 0 ? "لايوجد" : ""}
              </span>
            </div>

          </div>`
                   : ""
               }
        </div>
       <div class="financial-summary"> 
        <div class="payment-details">
          <h3 style="margin: 0 0 10px 0; color: #2c5282;display: flex;
        justify-content: center;
        align-items: center; ">تفاصيل المعاملة</h3>
          ${
            category?.id
              ? `
            <div class="financial-item">
              <span class="label"> المبلغ المستلم :</span>
              <span class="payment-amount">${
                ConvertToArabicNumbers(Math.abs(Math.round(price))) || "-"
              }</span>
            </div>
            
            <div class="financial-item">
              <span class="label">نوع الدفع :</span>
              <span class="payment-value">${category.name || "-"}</span>
            </div>
            ${
              allow > 0
                ? `
            <div class="financial-item">
              <span class="label">قيمة السماح :</span>
              <span class="payment-value">${
                ConvertToArabicNumbers(Math.abs(Math.round(allow))) || "-"
              }</span>
            </div>
            `
                : ""
            }
          `
              : ""
          }
        </div>
        `
            : ""
        }
        
        ${
          tool?.id
            ? `
          <div class="financial-summary">
          
            <div class="financial-item">
              <span class="label">نوع العدة  :</span>
              <span class="payment-value">${tool.name || "-"}</span>
            </div>
            <div class="financial-item">
              <span class="label">الكمية المستلمة :</span>
              <span class="payment-value">${
                ConvertToArabicNumbers(Math.abs(Math.round(toolCount))) || "-"
              }</span>
            </div>
          </div>
        `
            : ""
        }
        </div>
        ${
          (updatedInvoices || updatedLoans || updatedInAdvance || (toolCount && updatedToolCount)) ?
          `
        <div class="financial-summary">
        <h3 style="margin: 0 0 10px 0; color: #2c5282;display: flex;
        justify-content: center;
        align-items: center;">تفاصيل المتبقي</h3>
          ${
            money.totalLoans != null && money.totalInvoices != null
              ? `
          <div class="financial-group">
           ${
             updatedInvoices
               ? `
            <div class="financial-item">
              <span class="label">فواتير المبيعات المتبقية :</span>
                <span class="value ${
                  updatedInvoices > 0
                    ? "status-positive"
                    : updatedInvoices < 0
                    ? "status-negative"
                    : "status-neutral"
                }">
                ${ConvertToArabicNumbers(Math.abs(Math.round(updatedInvoices)))}
                ${Math.round(updatedInvoices) > 0 ? "مدين" : ""}
                ${Math.round(updatedInvoices) < 0 ? "دائن" : ""}
                ${updatedInvoices === 0 ? "لايوجد" : ""}
              </span>
            </div>
            `
               : ""
           }
               ${
                 updatedLoans
                   ? `
            <div class="financial-item">
              <span class="label">السلف المتبقية :</span>
              <span class="value ${
                updatedLoans > 0
                  ? "status-positive"
                  : updatedLoans < 0
                  ? "status-negative"
                  : "status-neutral"
              }">
                ${ConvertToArabicNumbers(Math.abs(Math.round(updatedLoans)))}
                ${Math.round(updatedLoans) > 0 ? "مدين" : ""}
                ${Math.round(updatedLoans) < 0 ? "دائن" : ""}
                ${updatedLoans === 0 ? "لايوجد" : ""}
              </span>
            </div> `
                   : ""
               }
          </div>`
              : ""
          }

          ${
            money.truckInvoice != null && money.inAdvance != null
              ? `
          <div class="financial-group">
          ${
            updatedInAdvance
              ? `
            <div class="financial-item">
              <span class="label">العملة المقدمة المتبقية :</span>
               <span class="value ${
                 updatedInAdvance > 0
                   ? "status-positive"
                   : updatedInAdvance < 0
                   ? "status-negative"
                   : "status-neutral"
               }">
                ${ConvertToArabicNumbers(
                  Math.abs(Math.round(updatedInAdvance))
                )}
                ${Math.round(updatedInAdvance) > 0 ? "مدين" : ""}
                ${Math.round(updatedInAdvance) < 0 ? "دائن" : ""}
                ${updatedInAdvance === 0 ? "لايوجد" : ""}
              </span>
            </div>
            `
              : ""
          }

          </div>`
              : ""
          }
           ${
             toolCount && updatedToolCount
               ? `
          <div class="financial-item">
              <span class="label">رصيد العدة المتبقية :</span>
              <span class="value ${
                updatedToolCount > 0
                  ? "status-positive"
                  : updatedToolCount < 0
                  ? "status-negative"
                  : "status-neutral"
              }">
                ${ConvertToArabicNumbers(
                  Math.abs(Math.round(updatedToolCount))
                )}
                ${Math.round(updatedToolCount) > 0 ? "مدين" : ""}
                ${Math.round(updatedToolCount) < 0 ? "دائن" : ""}
                ${updatedToolCount === 0 ? "لايوجد" : ""}
              </span>
            </div>
            `
               : ""
           }
        </div>
        `
            : ""
        }
        
        ${
          note
            ? `
          <div class="financial-summary">
        <h3 class="label">ملاحظات</h3>
        <div class="financial-item" style="word-wrap: break-word; white-space: pre-wrap; max-width: 100%; margin: 0 10px 0 0;">
          <span class="value" style="word-break: break-word;">${note}</span>
        </div>
      </div>
        `
            : ""
        }
        </div>
         <div class="footer">
        <div class="signature-section">
          <div class="signature-box">
            <div class="signature-title">المستلم</div>
           <div class="signature-name">${userName || "-"}</div>
              <div class="signature-line"></div>
          </div>
         </div>
      </div>
      
      <div class="receipt-footer">
        <p>www.owltecheg.com</p>
        <p>جميع الحقوق محفوظة © ${new Date().getFullYear()}</p>
      </div>
      
      </div>
    
    </div>
  </body>
</html>
      `;

      const printWindow = window.open("", "_blank");
      printWindow.document.write(invoiceHTML);
    }

    // Submit the data
    onSubmit(data);

    // Reset the form
    data = { moneyDepositDto: null, toolDto: null };
    setPartner("");
    setDepositDate(new Date());
    setPrice("");
    setAllow("");
    setCategory("");
    setNote("");
    setTool({});
    setToolCount("");
    inpRef.current?.focus();
  };

  React.useEffect(() => {
    if (deposit) {
      setPartner(deposit.partner);
      setDepositDate(parseISO(deposit.depositDate));
      setPrice(deposit.price);
      setAllow(deposit.allow);
      setCategory({
        id: deposit.depostCategoryId,
        name: deposit.depostCategoryName,
      });
      setNote(deposit.note);
    }
  }, [deposit]);
  const [money, setMoney] = useState({});
  const handlPartner = async (value) => {
    try {
      if (value) {
        setPartner(value);
        handleMoney(value).then((res) => {
          setMoney(res);
        });
      } else {
        setPartner("");
        setMoney({});
      }
    } catch (e) {
      Alerto(e);
    }
  };
  const [valid, setValid] = useState(false);
  useEffect(() => {
    let valid =
      (category?.id && price && tool?.id && toolCount) ||
      (category?.id && price && !tool?.id && !toolCount) ||
      (!category?.id && !price && tool?.id && toolCount);
    let v2 = valid && partner?.id;
    setValid(v2);
  }, [partner, category, price, tool, toolCount]);
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div>
      <Container fluid>
        {!isUpdate && (
          <>
            <div style={{ display: "flex" }}>
              <CardHeader className="text-white" title="التحصيل" />

              <FormControlLabel
                style={{
                  color: "#FFF",
                }}
                control={
                  <Checkbox
                    checked={isPrintEnabled}
                    onChange={(e) => setIsPrintEnabled(e.target.checked)}
                    style={{
                      color: "#FFF",
                    }}
                  />
                }
                label="تفعيل الإيصال"
              />

              <FormControlLabel
                style={{
                  color: "#FFF",
                }}
                control={
                  <Checkbox
                    onChange={() => setCheckDisplayTool(!checkDisplayTool)}
                    style={{
                      color: "#FFF",
                    }}
                  />
                }
                label="بدون عدة"
              />

              {partner?.id && <ToolsInfo haveAction partnerId={partner.id} />}
            </div>

            <div
              className={
                // load && load.truckTypeId === 1
                //   ? classes.commissionTruck
                classes.purchasesTruck
              }
            >
              {money && partner?.id && (
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography className={classes.qty}>
                      الرهن :{" "}
                      {ConvertToArabicNumbers(
                        Math.abs(Math.round(money.remain))
                      )}{" "}
                      {Math.round(money.remain) > 0 ? "عليه" : ""}
                      {Math.round(money.remain) < 0 ? "له" : ""}
                      {money.remain === 0 ? "صفر" : ""}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.qty}>
                      عدة :{" "}
                      {ConvertToArabicNumbers(Math.abs(Math.round(money.tool)))}{" "}
                      {Math.round(money.tool) > 0 ? "عليه" : ""}
                      {Math.round(money.tool) < 0 ? "له" : ""}
                      {money.tool === 0 ? "صفر" : ""}
                    </Typography>
                  </Grid>
                  {money.totalLoans != null && (
                    <Grid item>
                      <Typography className={classes.qty}>
                        سلف :{" "}
                        {ConvertToArabicNumbers(
                          Math.abs(Math.round(money.totalLoans))
                        )}{" "}
                        {Math.round(money.totalLoans) > 0 ? "عليه" : ""}
                        {Math.round(money.totalLoans) < 0 ? "له" : ""}
                        {money.totalLoans === 0 ? "صفر" : ""}
                      </Typography>
                    </Grid>
                  )}
                  {money.totalInvoices != null && (
                    <Grid item>
                      <Typography className={classes.qty}>
                        فواتير مبيع:
                        {ConvertToArabicNumbers(
                          Math.abs(Math.round(money.totalInvoices))
                        )}{" "}
                        {Math.round(money.totalInvoices) > 0 ? "عليه" : ""}
                        {Math.round(money.totalInvoices) < 0 ? "له" : ""}
                        {money.totalInvoices === 0 ? "صفر" : ""}
                      </Typography>
                    </Grid>
                  )}
                  {money.inAdvance != null && (
                    <Grid item>
                      <Typography className={classes.qty}>
                        عملة مقدمة:
                        {ConvertToArabicNumbers(
                          Math.abs(Math.round(money.inAdvance))
                        )}{" "}
                        {Math.round(money.inAdvance) > 0 ? "عليه" : ""}
                        {Math.round(money.inAdvance) < 0 ? "له" : ""}
                        {money.inAdvance === 0 ? "صفر" : ""}
                      </Typography>
                    </Grid>
                  )}
                  {money.truckInvoice != null && (
                    <Grid item>
                      <Typography className={classes.qty}>
                        فواتير نقلات:
                        {ConvertToArabicNumbers(
                          Math.abs(Math.round(money.truckInvoice))
                        )}{" "}
                        {Math.round(money.truckInvoice) > 0 ? "عليه" : ""}
                        {Math.round(money.truckInvoice) < 0 ? "له" : ""}
                        {money.truckInvoice === 0 ? "صفر" : ""}
                      </Typography>
                    </Grid>
                  )}
                  <Grid
                    onClick={() =>
                      push(
                        partner.partnerTypeId === 1
                          ? `client-profile/${partner.id}`
                          : `seller-profile/${partner.id}`
                      )
                    }
                    item
                  >
                    <Typography className={classes.qty}>حسابات</Typography>
                  </Grid>
                </Grid>
              )}
            </div>
            <Divider />
          </>
        )}
        <CardContent className="headerSearch pt-4 mt-2">
          <Grid container justifyContent="center" spacing={2}>
            {/* First Row: Partner, Category, Price, Allow */}
            <Grid item xs={12} md={4} lg={3}>
              <Autocomplete
                options={partners}
                getOptionSelected={(option, value) => {
                  return option.value === value?.value;
                }}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                filterOptions={(options, state) =>
                  InputSorting(options, state.inputValue)
                }
                style={{ width: "100%" }}
                autoHighlight
                autoComplete
                noOptionsText="لم يتم العثور على خيارات"
                onChange={(event, value) => {
                  handlPartner(value);
                }}
                value={partner}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    onSubmit();
                  }
                }}
                size="small"
                renderInput={(params) => (
                  <TextField
                    autoFocus={!isUpdate && !partnerId}
                    {...params}
                    inputRef={inpRef}
                    label="الاسم"
                    variant="outlined"
                    // onKeyDown={() => alert("a")}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
              {/* <CustomInput
              value={partner}
              setClient={handlPartner}
              options={partners}
              width="15rem"
            /> */}
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <Autocomplete
                options={categoryes}
                getOptionSelected={(option, value) => {
                  return option?.id === value?.id;
                }}
                getOptionLabel={(option) =>
                  option.name ? option.name.replace(/_/g, " ") : ""
                }
                style={{ width: "100%" }}
                autoHighlight
                autoComplete
                noOptionsText="لم يتم العثور على خيارات"
                onChange={(event, value) => {
                  if (value) {
                    setCategory(value);
                  } else {
                    setCategory("");
                  }
                }}
                value={category}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="الفئة "
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <TextField
                value={price >= 0 ? price : ""}
                onChange={(e) => setPrice(Number.parseFloat(e.target.value))}
                style={{ width: "100%" }}
                label="المبلغ"
                variant="outlined"
                size="small"
                type="number"
                autoFocus={!!partnerId && true}
              />
            </Grid>
            {category?.id == 4 && (
              <Grid item xs={12} md={2} lg={1}>
                <TextField
                  onChange={(e) => setAllow(Number.parseFloat(e.target.value))}
                  value={allow >= 0 ? allow : ""}
                  style={{ width: "100%" }}
                  label="السماح"
                  variant="outlined"
                  size="small"
                  type="number"
                />
              </Grid>
            )}
            {!checkDisplayTool
              ? !isUpdate && (
                  <>
                    <Grid item className="col-6 col-lg-2">
                      <Autocomplete
                        options={tools}
                        getOptionSelected={(option, value) => {
                          return option.id === value.id;
                        }}
                        getOptionLabel={(option) =>
                          option.name ? option.name.replace(/_/g, " ") : ""
                        }
                        style={{ width: "100%" }}
                        autoHighlight
                        autoComplete
                        noOptionsText="لم يتم العثور على خيارات"
                        onChange={(event, value) => {
                          if (value) {
                            setTool(value);
                          } else {
                            setTool("");
                          }
                        }}
                        value={tool}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="العدة "
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                              form: {
                                autoComplete: "off",
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item className="col-6 col-lg-1">
                      <TextField
                        value={toolCount}
                        onChange={(e) =>
                          setToolCount(Number.parseFloat(e.target.value))
                        }
                        style={{ width: "100%" }}
                        label="العدد"
                        variant="outlined"
                        size="small"
                        type="number"
                      />
                    </Grid>
                  </>
                )
              : ""}

            {!isUpdate && (
              <Grid item xs={12} md={4} lg={1}>
                <Button
                  className="btnMore"
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    transform: "translateY(-4px)",
                  }}
                  type="submit"
                  onClick={handleSubmit}
                  disabled={!valid}
                >
                  اضف
                </Button>
              </Grid>
            )}

            {/* Second Row: Date and Note */}
            <Grid item xs={12} container justifyContent="center" spacing={2}>
              {role !== "موظف" && (
                <Grid item xs={12} md={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}  adapterLocale={ar} // Add Arabic locale
                  >
                    <DatePicker
                      disableFuture
                      disabled={role == "موظف"}
                      label="التاريخ"
                      openTo="day"
                      views={["year", "month", "day"]}
                      value={depositDate}
                      onChange={(d) => setDepositDate(d)}
                      format="dd/MM/yyyy" // Add explicit date format
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ width: "100%" }}
                          variant="outlined"
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            style: { textAlign: "center" },
                          }}
                          inputProps={{
                            ...params.inputProps,
                            style: { textAlign: "center" },

                            autoComplete: "new-password",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              )}
              <Grid item xs={12} md={4}>
                <TextField
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  style={{ width: "100%" }}
                  label="ملحوظة"
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Grid>

            {isUpdate && (
              <Grid item className="col-6 col-lg-1">
                <Button
                  className="btnMore"
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    transform: "translateY(-4px)",
                  }}
                  onClick={() => {
                    onUpdateClicked({
                      id: deposit.id,
                      partner,
                      depositDate: Mydate(depositDate),
                      price,
                      allow,
                      note,
                      depostCategoryId: category.id,
                      depostCategoryName: category.name,
                    });
                  }}
                >
                  تعديل
                </Button>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Container>
    </div>
  );
}

DepositForm.defaultProps = {
  isUpdate: false,
};

export default DepositForm;
