import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Security from "./features/Home/SecurityPage";
import AddEmployee from "./features/staff/AddEmployee";
import AddExpenses from "./features/expenses/AddExpenses";
import AddJob from "./features/staff/AddJob";
import AddPartner from "./features/main-data/AddPartner";
import PartnerDetails from "./features/main-data/PartnerDetails";
import AddProduct from "./features/main-data/AddProduct";
import Addresse from "./features/main-data/Addresse";
import AllExpenses from "./features/expenses/AllExpenses";
import AnnualProfits from "./features/profits/AnnualProfits";
import BuyerAccount from "./features/money-account/BuyerAccount";
import BuyersArrears from "./features/arrears/BuyersArrears";
import BuyersCollecting from "./features/collecting-address/BuyersCollecting";
import CashInvoices from "./features/clients-invoices/CashInvoices";
import ClientAccount from "./features/money-account/ClientAccount";
import ClientProfile from "./features/client-profile/ClientProfile";
import ClientUserProfile from "./features/client-profile/ClientUserProfile";
import ClientsArrears from "./features/arrears/ClientsArrears";
import ClientsCollecting from "./features/collecting-address/ClientsCollecting";
import ClientsReports from "./features/movement-tools/tools-reports/ClientsReports";
import CollectingAddresse from "./features/main-data/CollectingAddress";
import CommissionInvoice from "./features/clients-invoices/CommissionInvoice";
import CompletedTrucks from "./features/trucks-reports/CompletedTrucks";
import CssBaseline from "@material-ui/core/CssBaseline";
import DailyProfits from "./features/profits/DailyProfits";
import DailyTrucks from "./features/trucks-reports/DailyTrucks";
import DamageTools from "./features/movement-tools/DamageTools";
import Damages from "./features/movement-tools/Damages";
import DepositPage from "./features/treasury/DepositPage";
import DepositsDetails from "./features/treasury/DepositsDetails";
import DepositsReports from "./features/treasury/DepositsReports";
import Expenses from "./features/expenses/Expenses";
import ExportTools from "./features/movement-tools/ExportTools";
import Exports from "./features/movement-tools/Exports";
import GeneralProfits from "./features/profits/GeneralProfits";
import GeneralReports from "./features/movement-tools/tools-reports/GeneralReports";
import Home from "./features/Home/Home";
import ImportTools from "./features/movement-tools/ImportTools";
import ImportingDetails from "./features/movement-tools/ImportingDetails";
import Imports from "./features/movement-tools/Imports";
import InvoiceDetails from "./features/invoices-sellers/InvoiceDetails";
import InvoicePage from "./features/invoice/InvoicePage";
import Layout from "./components/Layout";
import Loads from "./features/loads/Loads";
import Login from "./features/auth/Login";
import MonthlyProfits from "./features/profits/MonthlyProfits";
import NotFound from "./features/Home/404";
import NotifyArrears from "./features/arrears/components/NotifyArrears";
import Partners from "./features/main-data/Partners";
import Permissions from "./features/users/Permissions";
import Profile from "./features/profile/Profile";
import ProtectedRoute from "./components/ProtectedRoute";
import React from "react";
import Register from "./features/auth/Register";
import RemainingTrucks from "./features/trucks-reports/RemainigTrucks";
import SearchHistory from "./features/users/ٍSearchHistory";
import SellerProfile from "./features/seller-profile/SellerProfile";
import SellersReports from "./features/movement-tools/tools-reports/SellersReports";
import SellingReport from "./features/invoices-sellers/SellingReport";
import Settings from "./features/settings/Settings";
import { ThemeProvider } from "@material-ui/core/styles";
import Tools from "./features/main-data/Tools";
import ToolsAccount from "./features/current-account-tools/ToolsAccount";
import ToolsArrears from "./features/arrears/ToolsArrears";
import Users from "./features/users/Users";
import UsersSessions from "./features/users/UsersSessions";
import { getBuyersArrears } from "./features/main-data/dataSlice";
import { getCurrentUser } from "./features/auth/authSlice";
import { getPartnersData } from "./features/seller-profile/sellerSlice";
import theme from "./configs/theme/theme";
import Reports from "./features/Reports/Reports";
import DailyReports from "./features/Reports/DailyReports";
import MonthlyReports from "./features/Reports/MonthlyReports";
import AnnualReports from "./features/Reports/AnnualReports";
import TreasuryBalance from "./features/expenses/TreasuryBalance";
import EmployeeAccount from "./features/money-account/EmployeeAccount";
import Blocked from "./features/Home/block";
import TrucksHistory from "./features/users/TruckHistory";
import TreasuryHistory from "./features/users/TreasuryHistory";
import ToolsHistory from "./features/users/ToolsHistory";
import SellerUserProfile from "./features/seller-profile/SellerUserProfile";
import BackUp from "./components/BackUpPages/BackUp";
import NamesHistory from "./features/users/NamesHistory";
import Brief from "./components/BackUpPages/Brief/Brief";
import Maintenance from "./components/BackUpPages/Maintenance/Maintenance";
import SubscriptionInfo from "../src/SubscriptionInfo";
export const authRoles = {
  management: ["إدارة"],
  admin: ["مدير", "إدارة"],
  supervisor: ["مشرف", "مدير", "إدارة"],
  officer: ["موظف", "مشرف", "مدير", "إدارة"],
  collector: ["محصل", "موظف", "مشرف", "مدير", "إدارة"],
  partner: ["عميل_او_بائع", "محصل", "موظف", "مشرف", "مدير", "إدارة"],
};
function AppRouter() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const partner = useSelector((state) => state.seller.partner);
  const buyersArrears = useSelector((state) => state.data.buyersArrears);
  const arrearsError = useSelector((state) => state.data.arrearsError);
  const [openArrears, setOpenArrears] = React.useState(false);
  const [isRead, setIsRead] = React.useState(false);

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(getCurrentUser()).then(() => {
        if (user && user.UserId) {
          dispatch(getBuyersArrears());
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, []);

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (user && user.PartnerId) {
        dispatch(getPartnersData(user.PartnerId));
      }
    }
    return () => {
      mounted = false;
    };
  }, [user]);

  //   partnerId: 66 //محمود صقر
  // password: "admin700"
  // phone: "01201588949"
  const blockstate = localStorage.getItem("block"),
    license = localStorage.getItem("license"),
    token = localStorage.getItem("token");
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Layout
        user={user}
        partner={partner}
        isRead={isRead}
        buyersArrears={buyersArrears}
        onBellClicked={() => {
          setOpenArrears(!openArrears);
          setIsRead(true);
        }}
      >
        <NotifyArrears
          open={openArrears}
          onClose={() => setOpenArrears(false)}
          error={arrearsError}
        />
        <Switch>
          {blockstate && <Route path="/block" exact component={Blocked} />}

          {!blockstate && <Route path="/signup" exact component={Register} />}

          {token && (
            <Route path="/login" exact>
              <Redirect to="/" />
            </Route>
          )}
          {!token && !blockstate && (
            <Route path="/login" exact component={Login} />
          )}
          {!blockstate && !license && (
            <Route path="/security" exact component={Security} />
          )}
          <ProtectedRoute
            roles={authRoles.partner}
            path="/"
            exact
            component={Home}
          />
          <ProtectedRoute
            exact
            path="/loads"
            component={Loads}
            roles={authRoles.collector}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/loads/:truckId"
            exact
            component={Loads}
          />

          <ProtectedRoute
            roles={authRoles.collector}
            path="/add-partner/:type?/:partnerId?"
            component={AddPartner}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/partners"
            exact
            component={Partners}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/partners/:partnerId/view"
            exact
            component={PartnerDetails}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/collecting-address"
            exact
            component={CollectingAddresse}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/movementTools"
            exact
            component={Tools}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/products"
            exact
            component={AddProduct}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/daily-trucks"
            exact
            component={DailyTrucks}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/completed-trucks"
            exact
            component={CompletedTrucks}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/invoice/:truckId"
            component={InvoicePage}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/remaining-trucks"
            component={RemainingTrucks}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/address"
            component={Addresse}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/tools-out"
            component={ExportTools}
          />
          <ProtectedRoute
            roles={authRoles.admin}
            path="/backUp"
            component={BackUp}
          />
          <ProtectedRoute
            roles={authRoles.partner}
            path="/brief"
            component={Brief}
          />
          <ProtectedRoute
            roles={authRoles.partner}
            path="/maintain"
            component={Maintenance}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/subscription-info"
            component={SubscriptionInfo}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/tools-in/:partnerId?"
            component={ImportTools}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/importing-details/:importingId"
            component={ImportingDetails}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/damage-tools/:damageId?"
            component={DamageTools}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/exports"
            component={Exports}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/imports/:partnerId?"
            component={Imports}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/damages"
            component={Damages}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/clients-reports"
            component={ClientsReports}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/sellers-reports"
            component={SellersReports}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/tools-general-reports"
            component={GeneralReports}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            exact
            path="/sellers-invoices"
            component={SellingReport}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            exact
            path="/commission-invoices"
            component={CommissionInvoice}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            exact
            path="/purchase-invoices"
            component={CashInvoices}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/sellers-invoices/:partnerId/:sellingInvoiceTypeId/:type?"
            component={InvoiceDetails}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            exact
            path="/clients-collecting-addresses"
            component={ClientsCollecting}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            exact
            path="/buyers-collecting-addresses"
            component={BuyersCollecting}
          />
          <ProtectedRoute
            roles={authRoles.partner}
            path="/tools-current-account/:partnerId"
            component={ToolsAccount}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/deposits"
            component={DepositsDetails}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/add-deposit/:partnerId?/:factor?"
            component={DepositPage}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/deposits-reports"
            component={DepositsReports}
          />

          <ProtectedRoute
            roles={authRoles.collector}
            path="/add-expenses/:type?/:partnerId?/:factor?"
            component={AddExpenses}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/reports-expenses"
            component={Expenses}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/treasury-balance"
            component={TreasuryBalance}
          />

          <ProtectedRoute
            roles={authRoles.collector}
            path="/search-expenses"
            component={AllExpenses}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/profiles/:type"
            component={Profile}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/clients-arrears"
            component={ClientsArrears}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/buyers-arrears"
            component={BuyersArrears}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/tools-arrears"
            component={ToolsArrears}
          />
          <ProtectedRoute
            roles={authRoles.partner}
            path="/MoneyCurrentAccountReport/client/:partnerId/:factor?/:factor2?"
            component={ClientAccount}
          />
          <ProtectedRoute
            roles={authRoles.partner}
            path="/MoneyCurrentAccountReport/employee/:partnerId"
            component={EmployeeAccount}
          />
          <ProtectedRoute
            roles={authRoles.partner}
            path="/MoneyCurrentAccountReport/:type/:partnerId/:factor?"
            component={BuyerAccount}
          />
          <ProtectedRoute
            roles={authRoles.officer}
            redirect={`/seller-usser-profile/${user.PartnerId}`}
            path="/seller-profile/:partnerId"
            component={SellerProfile}
          />
          <ProtectedRoute
            roles={authRoles.partner}
            path="/seller-user-profile/:partnerId"
            component={SellerUserProfile}
          />
          <ProtectedRoute
            roles={authRoles.partner}
            path="/client-user-profile/:partnerId"
            component={ClientUserProfile}
          />
          <ProtectedRoute
            roles={authRoles.officer}
            redirect={`/client-user-profile/${user.PartnerId}`}
            path="/client-profile/:partnerId"
            component={ClientProfile}
          />

          <ProtectedRoute
            path="/daily-profits"
            roles={authRoles.admin}
            component={DailyProfits}
          />
          <ProtectedRoute
            roles={authRoles.admin}
            path="/monthly-profits"
            component={MonthlyProfits}
          />
          <ProtectedRoute
            path="/annual-profits"
            roles={authRoles.admin}
            component={AnnualProfits}
          />
          <ProtectedRoute
            roles={authRoles.admin}
            path="/general-profits"
            component={GeneralProfits}
          />
          <ProtectedRoute
            roles={authRoles.admin}
            path="/general-reports"
            component={Reports}
          />
          <ProtectedRoute
            roles={authRoles.admin}
            path="/daily-reports"
            component={DailyReports}
          />
          <ProtectedRoute
            roles={authRoles.admin}
            path="/monthly-reports"
            component={MonthlyReports}
          />
          <ProtectedRoute
            roles={authRoles.admin}
            path="/annual-reports"
            component={AnnualReports}
          />
          <ProtectedRoute
            roles={authRoles.admin}
            path="/settings"
            component={Settings}
          />
          <ProtectedRoute
            roles={authRoles.collector}
            path="/add-employee/:id?"
            component={AddEmployee}
          />
          <ProtectedRoute
            roles={authRoles.admin}
            path="/add-job"
            component={AddJob}
          />
          <ProtectedRoute
            roles={authRoles.admin}
            path="/users-sessions"
            component={UsersSessions}
          />
          <ProtectedRoute
            roles={authRoles.admin}
            path="/search-history"
            component={SearchHistory}
          />
          <ProtectedRoute
            roles={authRoles.admin}
            path="/names-history"
            component={NamesHistory}
          />
          <ProtectedRoute
            roles={authRoles.admin}
            path="/treasury-history"
            component={TreasuryHistory}
          />
          <ProtectedRoute
            roles={authRoles.admin}
            path="/tool-history"
            component={ToolsHistory}
          />
          <ProtectedRoute
            roles={authRoles.admin}
            path="/truck-history"
            component={TrucksHistory}
          />
          <ProtectedRoute
            path="/users"
            roles={authRoles.admin}
            component={Users}
          />
          <ProtectedRoute
            roles={authRoles.admin}
            path="/user-permissions/:partnerName/:partnerId/:userId"
            component={Permissions}
          />

          <Route path="*" component={NotFound} />
        </Switch>
      </Layout>
    </ThemeProvider>
  );
}

export default AppRouter;
