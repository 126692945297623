import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { ConvertToArabicNumbers } from "../../../utils";
import { addCommisionItem } from "../loadsSlice";
import { addCrop } from "../../main-data/dataSlice";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const AddItemsForm = () => {
  const dispatch = useDispatch();
  const crops = useSelector((state) => state.data.crops);
  const tools = useSelector((state) => state.data.tools);
  const load = useSelector((state) => state.loads.load.data);
  const clients = useSelector((state) => state.data.clients);

  const [owner, setOwner] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [product, setProduct] = useState("");
  const [itemsCount, setItemsCount] = useState("");
  const [weight, setWeight] = useState(0);
  const [price, setPrice] = useState(0);
  const [commisionForClient, setCommisionForClient] = useState(0);
  const [mostSellingPrice, setMostSellingPrice] = useState(0);
  const [mostCommision, setMostCommision] = useState(0);
  const [mostMovementTool, setMostMovementTool] = useState("");

  const handleSubmit = () => {
    if (
      load.truckTypeId === 2 &&
      product &&
      product.id &&
      mostSellingPrice >= 0 &&
      mostCommision >= 0 &&
      itemsCount >= 0
    ) {
      dispatch(
        addCommisionItem({
          SharedPartnerId: owner?.id,
          product,
          mostSellingPrice,
          mostCommision,
          mostMovementTool,
          itemsCount,
          commisionForClient,
          weight,
          price,
        })
      )
        .then(() => {})
        .catch(() => {});
      setItemsCount("");
      setMostSellingPrice(0);
      setMostCommision(0);
      setMostMovementTool("");
      setProduct("");
      setWeight(0);
      setCommisionForClient(0);
      setPrice(0);
      setOwner("");
      setOwnerName("");

      setTimeout(() => {
        document.getElementById("007").focus();
      }, 1000);
    } else {
      if (
        product &&
        mostSellingPrice >= 0 &&
        mostCommision >= 0 &&
        itemsCount >= 0
      ) {
        dispatch(
          addCommisionItem({
            SharedPartnerId: owner?.id,
            product,
            mostSellingPrice,
            mostCommision,
            mostMovementTool,
            itemsCount,
          })
        );
        setItemsCount("");
        setOwner("");
        setOwnerName("");
        setMostSellingPrice(0);
        setMostCommision(0);
        setMostMovementTool("");
        setProduct("");
        setTimeout(() => {
          document.getElementById("007")?.focus();
        }, 1000);
      }
    }
  };

  const [productName, setProductName] = useState("");
  const submitNewProduct = () => {
    let isExist = false;
    crops.filter((crop) => {
      if (crop.name === productName) {
        return (isExist = true);
      }
    });
    if (!isExist && productName?.length > 0) {
      const name = prompt(
        "اسم الصنف غير موجود اذا كان جديد اختار(نعم) او (ﻻ) للبحث في القائمة   ",
        productName
      );
      if (name) {
        dispatch(addCrop({ name })).then(({ payload }) => {
          if (payload.id) {
            setProduct(payload);
          }
        });
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("007").focus();
    }, 1000);
  }, []);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Box
      className="bg-transparent border-0 shadow-0 m-0 p-0"
      style={{ boxShadow: "none !important" }}
    >
      <Card
        className=" bg-transparent border-0 mb-o "
        style={{ boxShadow: "none", padding: "3px", marginTop: "-40px" }}
      >
        <CardContent
          className="headerSearch p-2 center text-center px-5 "
          style={{
            boxShadow:
              "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
          }}
        >
          <form
            noValidate
            autoComplete="off"
            alignItems="center"
            className="m-auto center text-center"
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              className="center"
            >
              {!!load?.sharedTruck && (
                <Grid item xs={6} md={2} className="my-2 p-2">
                  <Autocomplete
                    options={clients}
                    getOptionSelected={(option, value) =>
                      option.value === value?.value
                    }
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    value={owner}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        setOwner(newValue);
                      } else {
                        setOwner("");
                      }
                    }}
                    onInputChange={(e, v) => {
                      setOwnerName(v);
                    }}
                    inputValue={ownerName}
                    renderInput={(params) => (
                      <TextField
                        autoFocus
                        {...params}
                        label="عميل مشترك"
                        variant="outlined"
                        size="small"
                        className="w-100"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={2} className="my-2 p-2">
                <Autocomplete
                  options={crops}
                  getOptionSelected={(option, value) =>
                    option.value === value?.value
                  }
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  autoComplete
                  id="007"
                  value={product}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setProduct(newValue);
                    } else {
                      setProduct("");
                    }
                  }}
                  onInputChange={(e, v) => {
                    setProductName(v);
                  }}
                  inputValue={productName}
                  renderInput={(params) => (
                    <TextField
                      autoFocus
                      onBlur={submitNewProduct}
                      {...params}
                      label="اسم الصنف"
                      variant="outlined"
                      size="small"
                      className="w-100"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={2} className="my-2 p-2">
                <TextField
                  value={itemsCount >= 0 ? itemsCount : ""}
                  onChange={(e) =>
                    setItemsCount(Number.parseFloat(e.target.value))
                  }
                  label="العدد"
                  id="number"
                  className="w-100"
                  variant="outlined"
                  size="small"
                  type="number"
                />
              </Grid>
              {load.truckTypeId === 2 && (
                <>
                  <Grid item xs={6} md={2} className="my-2 p-2">
                    <TextField
                      value={weight >= 0 ? weight : ""}
                      onChange={(e) =>
                        setWeight(Number.parseFloat(e.target.value))
                      }
                      label="الوزن"
                      variant="outlined"
                      size="small"
                      className="w-100"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6} md={2} className="my-2 p-2">
                    <TextField
                      value={price >= 0 ? price : ""}
                      onChange={(e) =>
                        setPrice(Number.parseFloat(e.target.value))
                      }
                      label="سعر الشراء"
                      variant="outlined"
                      size="small"
                      className="w-100"
                      type="number"
                    />
                  </Grid>

                  <Grid item xs={6} md={2} className="my-2 p-2">
                    <TextField
                      value={commisionForClient >= 0 ? commisionForClient : ""}
                      onChange={(e) =>
                        setCommisionForClient(Number.parseFloat(e.target.value))
                      }
                      label="بياعة الشراء"
                      variant="outlined"
                      size="small"
                      type="number"
                      className="w-100"
                    />
                  </Grid>
                  <Grid item xs={12} md={2} className="my-2 p-2">
                    <Typography
                      style={{
                        backgroundColor: "#f5f5f5",
                        borderRadius: 4,
                        height: 48,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0px 8px",
                      }}
                      className=" m-auto"
                      variant="outlined"
                    >
                      الاجمالي:
                      {price >= 0 &&
                        weight >= 0 &&
                        commisionForClient >= 0 &&
                        itemsCount >= 0 &&
                        ConvertToArabicNumbers(
                          price * weight + commisionForClient * itemsCount
                        )}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={2} className="my-2 p-2">
                <Autocomplete
                  options={tools}
                  openOnFocus
                  getOptionSelected={(option, value) =>
                    option.value === value?.value
                  }
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  autoHighlight
                  autoComplete
                  freeSolo
                  value={mostMovementTool}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setMostMovementTool(newValue);
                    } else {
                      setMostMovementTool("");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="العدة"
                      variant="outlined"
                      size="small"
                      className="w-100"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={2} className="my-2 p-2">
                <TextField
                  value={mostSellingPrice >= 0 ? mostSellingPrice : ""}
                  onChange={(e) =>
                    setMostSellingPrice(Number.parseFloat(e.target.value))
                  }
                  label="سعر البيع"
                  variant="outlined"
                  size="small"
                  className="w-100"
                  type="number"
                />
              </Grid>
              <Grid item xs={6} md={2} className="my-2 p-2">
                <TextField
                  value={mostCommision >= 0 ? mostCommision : ""}
                  onChange={(e) =>
                    setMostCommision(Number.parseFloat(e.target.value))
                  }
                  label="بياعة البيع"
                  id="commission"
                  variant="outlined"
                  size="small"
                  type="number"
                  className="w-100"
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      handleSubmit();
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                xs={load.truckTypeId === 2 && 12}
                md={2}
                className="my-2 p-2"
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: "140px" }}
                  onClick={handleSubmit}
                >
                  اضافة
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};
export default AddItemsForm;
