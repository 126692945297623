import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Paper, TableSortLabel } from "@material-ui/core";
import { Link } from "react-router-dom";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import { ConvertToArabicNumbers, renderDate } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  table: {
    borderSpacing: "8px 4px",
    border: "1px solid #222",
  },
  tableCell: {
    fontSize: 15,
    padding: 4,
    border: "1px solid #222",
  },
  searchBar: {
    backgroundColor: "#fff",
    width: 280,
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#f3c637",
  },
  searchDate: {
    display: "flex",
    justifyContent: "center",

    backgroundColor: "#f3c637",
  },
  summaryBox: {
    backgroundColor: "#f3c637",
    padding: 10,
    textAlign: "center",
  },
  nameColumn: {
    fontSize: 15,
    padding: 4,
    width: "30%",
    border: "1px solid #222",
  },
  excludeButton: {
    backgroundColor: (props) => (props.isExcluded ? "#f44336" : "#4caf50"),
    color: "#fff",
    "&:hover": {
      backgroundColor: (props) => (props.isExcluded ? "#d32f2f" : "#45a049"),
    },
  },
  hoverRow: {
    "&:hover": {
      backgroundColor: "#f5f5f5",
      cursor: "pointer",
    },
  },
  datePickerGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "@media print": {
    tableCell: {
      fontSize: 11,
      padding: 2,
      border: "1px solid #222",
    },
    nameColumn: {
      fontSize: 11,
      padding: 2,
      width: "28%",
      border: "1px solid #222",
    },
    "@page": {
      margin: "10px",
      marginBottom: "10px",
    },

    printIcon: {
      display: "none",
    },
    printHidden: {
      display: "none",
    },
    toolBar: {
      display: "none",
    },
    searchDate: {
      display: "none",
    },
    summaryBox: {
      display: "none",
    },
    checkboxColumn: {
      display: "none",
    },
  },
}));

function isWorthy(item) {
  const total = item.totalToolMortage + item.totalLoans + item.totalInvoices;
  return total >= 20;
}

export default function ClientsArrearsTable({ data, handlePrint, headCells }) {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("lastDepositDate");
  const [nameQuery, setNameQuery] = useState("");
  const [addressQuery, setAddressQuery] = useState("");
  const [excludeAddressQuery, setExcludeAddressQuery] = useState("");
  const [excludedNames, setExcludedNames] = useState([]);
  const [selected, setSelected] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isExcluded, setIsExcluded] = useState(false);

  // New state for date range filtering
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const classes = useStyles({ isExcluded });
  const role = localStorage.getItem("role");

  // Calculate totals
  const totalInvoices = filteredData.reduce(
    (sum, item) => sum + item.totalInvoices,
    0
  );
  const totalLoans = filteredData.reduce(
    (sum, item) => sum + item.totalLoans,
    0
  );
  const totalToolMortage = filteredData.reduce(
    (sum, item) => sum + item.totalToolMortage,
    0
  );

  useEffect(() => {
    const filtered = data
      .filter((item) => isWorthy(item))
      .filter((item) =>
        item.partnerName ? item.partnerName.includes(nameQuery) : item
      )
      .filter((item) =>
        addressQuery.length > 0
          ? item.collectingAddressName
            ? item.collectingAddressName.includes(addressQuery)
            : null
          : item
      )
      .filter((item) => {
        if (excludeAddressQuery.length > 0) {
          const excludedAddresses = excludeAddressQuery
            .split("-")
            .map((addr) => addr.trim());
          return !excludedAddresses.some(
            (addr) =>
              item.collectingAddressName &&
              item.collectingAddressName.includes(addr)
          );
        }
        return true;
      })
      .filter((item) => !excludedNames.includes(item.partnerName))
      // Date range filter
      .filter((item) => {
        // If no date filters are set, include all items
        if (!fromDate && !toDate) return true;

        // If lastDepositDate doesn't exist, exclude the item
        if (!item.lastDepositDate) return false;

        const itemDate = new Date(item.lastDepositDate);

        // Filter by fromDate if set
        if (fromDate && itemDate < fromDate) return false;

        // Filter by toDate if set
        if (toDate) {
          // Set toDate to end of day
          const endOfToDate = new Date(toDate);
          endOfToDate.setHours(23, 59, 59, 999);
          if (itemDate > endOfToDate) return false;
        }

        return true;
      });

    setFilteredData(filtered);
  }, [
    data,
    nameQuery,
    addressQuery,
    excludeAddressQuery,
    excludedNames,
    fromDate,
    toDate,
  ]);

  useEffect(() => {
    // Add print-specific styles
    const style = document.createElement("style");
    style.textContent = `
      @media print {
        @page {
          size: auto;
          margin: 10mm;
          margin-bottom: 10mm;
        }
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  function descendingComparator(a, b, orderBy) {
    if (orderBy === "lastDepositDate") {
      // استخراج التاريخ فقط (بدون الوقت)
      const dateA = a[orderBy]
        ? new Date(a[orderBy]).setHours(0, 0, 0, 0)
        : new Date(0);
      const dateB = b[orderBy]
        ? new Date(b[orderBy]).setHours(0, 0, 0, 0)
        : new Date(0);

      if (dateA < dateB) return 1; // ترتيب تنازلي
      if (dateA > dateB) return -1;

      // إذا كانت التواريخ متساوية، يتم ترتيب الأسماء أبجديًا
      return a.partnerName.localeCompare(b.partnerName, "ar");
    }

    // ترتيب أبجدي للأسماء إذا كانت القاعدة الافتراضية هي الاسم
    if (orderBy === "partnerName") {
      return a.partnerName.localeCompare(b.partnerName, "ar");
    }

    // ترتيب افتراضي لباقي الأعمدة
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  }

  useEffect(() => {
    if (data && data.length > 0) {
      // استخراج أقدم تاريخ دفع من البيانات
      const oldestDate = data
        .filter((item) => item.lastDepositDate) // التأكد من وجود تاريخ دفع
        .map((item) => new Date(item.lastDepositDate)) // تحويل النصوص إلى تواريخ
        .sort((a, b) => a - b)[0]; // ترتيب التواريخ واختيار الأقدم

      setFromDate(oldestDate || null); // تعيين "من تاريخ" كأقدم تاريخ أو تركه فارغًا
      setToDate(new Date()); // تعيين "إلى تاريخ" بتاريخ اليوم الحالي
    }
  }, [data]);

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredData.map((n) => n.partnerId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (partnerId) => selected.indexOf(partnerId) !== -1;

  const handleApplyExclusion = () => {
    if (isExcluded) {
      setExcludedNames([]);
      setIsExcluded(false);
    } else {
      if (selected.length > 0) {
        const selectedNames = filteredData
          .filter((item) => selected.includes(item.partnerId))
          .map((item) => item.partnerName);
        setExcludedNames([...excludedNames, ...selectedNames]);
        setIsExcluded(true);
      }
    }
    setSelected([]);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleCheckboxClick = (event, partnerId) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(partnerId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, partnerId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow>
          {!isExcluded && (
            <TableCell padding="checkbox" className={classes.checkboxColumn}>
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ "aria-label": "select all desserts" }}
              />
            </TableCell>
          )}
          {headCells.map((headCell, index) => (
            <TableCell
              key={index}
              align="center"
              style={{ fontWeight: "bold", fontSize: 12 }}
              sortDirection={orderBy === headCell.id ? order : false}
              className="text-center"
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleRequestSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        xs={12}
        className={classes.searchDate}
      >
        <Grid item xs={6} md={4} className={classes.datePickerGrid}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="من تاريخ"
              openTo="day"
              views={["year", "month", "day"]}
              value={fromDate}
              onChange={(newValue) => setFromDate(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ width: "100%" , margin:"10px" }}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      textAlign: "center",
                    },
                  }}
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      textAlign: "center",
                    },
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} md={4} className={classes.datePickerGrid}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="إلى تاريخ"
              openTo="day"
              views={["year", "month", "day"]}
              value={toDate}
              onChange={(newValue) => setToDate(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ width: "100%", margin:"10px" }}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      textAlign: "center",
                    },
                  }}
                  inputProps={{
                    ...params.inputProps,
                    style: {
                      textAlign: "center",
                    },
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Toolbar className={classes.toolBar} style={{ marginTop: 24 }}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          xs={12}
          className="p-2"
        >
          <Grid item md={2}>
            {(selected.length > 0 || isExcluded) && (
              <Button
                variant="contained"
                className={classes.excludeButton}
                onClick={handleApplyExclusion}
              >
                {isExcluded ? "إلغاء" : "استبعاد"}
              </Button>
            )}
          </Grid>
          <Grid item md={3} className="mb-2">
            <TextField
              placeholder="بحث بالاسم"
              size="small"
              value={nameQuery}
              className="bg-white"
              onChange={(e) => setNameQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className="text-black" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              placeholder="خط التحصيل"
              size="small"
              value={addressQuery}
              className="bg-white"
              onChange={(e) => setAddressQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              placeholder="استبعاد خطوط التحصيل"
              size="small"
              value={excludeAddressQuery}
              className="bg-white"
              onChange={(e) => setExcludeAddressQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <div className={classes.printIcon}>
          <Tooltip title="طباعة التقرير">
            <IconButton onClick={handlePrint}>
              <PrintOutlinedIcon className="text-black" />
            </IconButton>
          </Tooltip>
        </div>
      </Toolbar>

      <div className={classes.summaryBox}>
        <Grid container rowSpacing={1} xs={12}>
          <Grid item xs={4}>
            <span>
              إجمالي الفواتير:{" "}
              {ConvertToArabicNumbers(Math.round(totalInvoices))}
            </span>
          </Grid>
          <Grid item xs={4}>
            <span>
              إجمالي السلف: {ConvertToArabicNumbers(Math.round(totalLoans))}
            </span>
          </Grid>
          <Grid item xs={4}>
            <span>
              إجمالي الرهن:{" "}
              {ConvertToArabicNumbers(Math.round(totalToolMortage))}
            </span>
          </Grid>
        </Grid>
      </div>

      <TableContainer
        className="p-2"
        style={{ width: "100%" }}
        component={Paper}
      >
        <Table className={classes.table}>
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={filteredData.length}
          />
          <TableBody className={classes.tableBody}>
            {stableSort(filteredData, getComparator(order, orderBy)).map(
              (item) => {
                const isItemSelected = isSelected(item.partnerId);
                return (
                  <TableRow
                    key={item.partnerId}
                    className={`${classes.hoverRow} hover-row`}
                    component={Link}
                    to={
                      role !== "محصل"
                        ? `/seller-profile/${item.partnerId}`
                        : `/seller-user-profile/${item.partnerId}`
                    }
                  >
                    {!isExcluded && (
                      <TableCell
                        padding="checkbox"
                        className={classes.checkboxColumn}
                        onClick={(event) => event.preventDefault()}
                      >
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(event) =>
                            handleCheckboxClick(event, item.partnerId)
                          }
                          inputProps={{ "aria-labelledby": item.partnerId }}
                        />
                      </TableCell>
                    )}
                    <TableCell className={classes.nameColumn} align="center">
                      {item.partnerName}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {item.collectingAddressName}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {item.lastDepositDate &&
                        ConvertToArabicNumbers(
                          renderDate(item.lastDepositDate)
                        )}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {ConvertToArabicNumbers(
                        Math.abs(Math.round(item.totalInvoices))
                      )}{" "}
                      {Math.round(item.totalInvoices) > 0 ? "عليه" : ""}
                      {Math.round(item.totalInvoices) < 0 ? "له" : ""}
                      {item.totalInvoices === 0 ? "صفر" : ""}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {ConvertToArabicNumbers(
                        Math.abs(Math.round(item.totalLoans))
                      )}{" "}
                      {Math.round(item.totalLoans) > 0 ? "عليه" : ""}
                      {Math.round(item.totalLoans) < 0 ? "له" : ""}
                      {item.totalLoans === 0 ? "صفر" : ""}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {ConvertToArabicNumbers(
                        Math.abs(Math.round(item.totalToolMortage))
                      )}{" "}
                      {Math.round(item.totalToolMortage) > 0 ? "عليه" : ""}
                      {Math.round(item.totalToolMortage) < 0 ? "له" : ""}
                      {item.totalToolMortage === 0 ? "صفر" : ""}
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
