import { Box, Button, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomDialog from "../../../components/CustomDialog";
import clsx from "clsx";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { updateCommisionItem } from "../loadsSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function EditItemForm({
  open,
  onClose,
  selectedItem,
  truckTypeId,
}) {
  const crops = useSelector((state) => state.data.crops);
  const tools = useSelector((state) => state.data.tools);
  const load = useSelector((state) => state.loads.load.data);

  const updateStatus = useSelector(
    (state) => state.loads.commissionItems.updateStatus
  );
  const clients = useSelector((state) => state.data.clients);

  const [product, setProduct] = useState();
  const [itemsCount, setItemsCount] = useState("");
  const [mostSellingPrice, setMostSellingPrice] = useState("");
  const [mostCommision, setMostCommision] = useState("");
  const [mostMovementTool, setMostMovementTool] = useState(tools[0]);
  const [commisionForClient, setCommisionForClient] = useState("");
  const [price, setPrice] = useState("");
  const [weight, setWeight] = useState("");
  const [owner, setOwner] = useState("");
  const [ownerName, setOwnerName] = useState("");
  React.useEffect(() => {
    setProduct(selectedItem.product);
    setMostSellingPrice(selectedItem.mostSellingPrice);
    setMostCommision(selectedItem.mostCommision);
    setMostMovementTool(selectedItem.mostMovementTool);
    setItemsCount(selectedItem.itemsCount);
    setCommisionForClient(selectedItem.commisionForClient);
    setPrice(selectedItem.price);
    setWeight(selectedItem.weight);
    setOwner(selectedItem.sharedPartner);
  }, [selectedItem]);
  
  const formatNumberIfDecimal = (number) => {
    return Number.isInteger(number) ? number : Number(number).toFixed(2);
  };

  const dispatch = useDispatch();
  const onUpdateClicked = () => {
    if (
      product.id &&
      mostCommision >= 0 &&
      mostSellingPrice >= 0
      // itemsCount > 0
    ) {
      if (truckTypeId === 1) {
        dispatch(
          updateCommisionItem({
            id: selectedItem.id,
            product,
            mostSellingPrice,
            mostCommision,
            mostMovementTool,
            itemsCount,
            oldCount: selectedItem.itemsCount,
            sharedPartnerId: owner?.id,
          })
        ).then(() => {
          if (updateStatus !== "loading") {
            onClose();
          }
        });
      } else {
        if (price >= 0 && commisionForClient >= 0 && weight >= 0)
          dispatch(
            updateCommisionItem({
              id: selectedItem.id,
              product,
              mostSellingPrice,
              mostCommision,
              mostMovementTool,
              itemsCount,
              commisionForClient,
              price,
              weight,
              sharedPartnerId: owner?.id,
            })
          ).then(() => {
            setItemsCount("");
            setMostSellingPrice("");
            setMostCommision("");
            setMostMovementTool("");
            setOwner("");
            setOwnerName("");
            setProduct("");
            onClose();
          });
      }
    }
  };

  const classes = useStyles();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: updateStatus === "succeeded",
  });

  if (!selectedItem) {
    return null;
  }
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title="تعديل بيانات الصنف"
      action1={
        <Button
          onClick={onClose}
          variant="outlined"
          size="small"
          style={{ width: 80, height: 40 }}
        >
          الغاء
        </Button>
      }
      action2={
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="primary"
            className={buttonClassname}
            disabled={updateStatus === "loading"}
            onClick={onUpdateClicked}
          >
            تعديل
          </Button>
          {updateStatus === "loading" && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      }
    >
      <Box
        style={{
          display: "flex",
          gap: "5px",
          justifyContent: "space-between",
          marginBottom: 36,
        }}
      >
        {!!load.sharedTruck && (
          <Box>
            <Autocomplete
              options={clients}
              getOptionSelected={(option, value) =>
                option.value === value?.value
              }
              getOptionLabel={(option) => (option.name ? option.name : "")}
              autoHighlight
              value={owner}
              style={{ width: 200 }}
              onChange={(e, newValue) => {
                if (newValue) {
                  setOwner(newValue);
                } else {
                  setOwner("");
                }
              }}
              onInputChange={(e, v) => {
                setOwnerName(v);
              }}
              inputValue={ownerName}
              renderInput={(params) => (
                <TextField
                  autoFocus
                  {...params}
                  label="عميل مشترك"
                  variant="outlined"
                  size="small"
                  className="w-100"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Box>
        )}
        <Box>
          {crops && (
            <Autocomplete
              options={crops}
              getOptionSelected={(option, value) =>
                option.value === value?.value
              }
              getOptionLabel={(option) => (option.name ? option.name : "")}
              style={{ width: 160 }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  onUpdateClicked();
                }
              }}
              autoComplete
              onChange={(e, newValue) => {
                if (newValue) {
                  setProduct(newValue);
                }
              }}
              value={product}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="اسم الصنف"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          )}
        </Box>
        <Box>
          <TextField
            onChange={(e) => setItemsCount(Number.parseFloat(e.target.value))}
            value={itemsCount >= 0 ? itemsCount : ""}
            style={{ width: 130 }}
            label="العدد"
            variant="outlined"
            size="small"
            type="number"
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onUpdateClicked();
              }
            }}
          />
        </Box>
        <Box>
          {tools && (
            <Autocomplete
              options={tools}
              getOptionSelected={(option, value) =>
                option.value === value?.value
              }
              getOptionLabel={(option) => (option.name ? option.name : "")}
              style={{ width: 130 }}
              autoHighlight
              autoComplete
              freeSolo
              onChange={(e, newValue) => {
                setMostMovementTool(newValue);
              }}
              value={mostMovementTool}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="العدة"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          )}
        </Box>
        {truckTypeId === 2 && (
          <>
            <Box>
              <TextField
                value={weight >= 0 ? weight : ""}
                onChange={(e) => setWeight(Number.parseFloat(e.target.value))}
                style={{ width: 130 }}
                label="الوزن"
                variant="outlined"
                size="small"
                type="number"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    onUpdateClicked();
                  }
                }}
              />
            </Box>

            <Box>
              <TextField
                value={price >= 0 ? price : ""}
                onChange={(e) => setPrice(e.target.value)}
                style={{ width: 130 }}
                label="السعر"
                variant="outlined"
                size="small"
                type="number"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    onUpdateClicked();
                  }
                }}
              />
            </Box>

            <Box>
              <TextField
                value={commisionForClient >= 0 ? commisionForClient : ""}
                onChange={(e) =>
                  setCommisionForClient(Number.parseFloat(e.target.value))
                }
                style={{ width: 130 }}
                label="بياعة العميل"
                variant="outlined"
                size="small"
                type="number"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    onUpdateClicked();
                  }
                }}
              />
            </Box>
            <Box>
              <Typography
                style={{
                  padding: 8,
                  backgroundColor: "#f5f5f5",
                  borderRadius: 7,
                }}
              >
                {price >= 0 &&
                  weight >= 0 &&
                  commisionForClient >= 0 &&
                  itemsCount >= 0 &&
                  formatNumberIfDecimal(
                    price * weight + commisionForClient * itemsCount
                  )}
                  
              </Typography>
            </Box>
          </>
        )}
        <Box>
          <TextField
            onChange={(e) =>
              setMostSellingPrice(Number.parseFloat(e.target.value))
            }
            value={mostSellingPrice >= 0 ? mostSellingPrice : ""}
            style={{ width: 130 }}
            label="سعر البيع"
            variant="outlined"
            size="small"
            type="number"
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onUpdateClicked();
              }
            }}
          />
        </Box>
        <Box>
          <TextField
            onChange={(e) =>
              setMostCommision(Number.parseFloat(e.target.value))
            }
            value={mostCommision >= 0 ? mostCommision : ""}
            style={{ width: 130 }}
            label="بياعة"
            variant="outlined"
            size="small"
            type="number"
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onUpdateClicked();
              }
            }}
          />
        </Box>
      </Box>
    </CustomDialog>
  );
}
