import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import { Card, CardContent, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "../../components/UI/toaster";

const useStyles = makeStyles({
  root: {
    width: "100%",
    padding: "20px",
    maxWidth: 800,
    margin: "0 auto",
  },
  detailItem: {
    marginBottom: "15px",
  },
  label: {
    fontWeight: "bold",
    marginRight: "10px",
    color: "#555",
  },
  value: {
    color: "#333",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  profileImage: {
    maxWidth: "250px",
    maxHeight: "250px",
    
    borderRadius: "50%",
    objectFit: "cover",
  },
});

function PartnerDetails() {
  const classes = useStyles();
  const { partnerId } = useParams();
  const history = useHistory();
  const [partnerData, setPartnerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [defimg, setDefimg] = useState(null);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    if (partnerId) {
      fetchPartnerDetails();
    }
  }, [partnerId]);

  const fetchPartnerDetails = () => {
    axios
      .get(`/Partners/${partnerId}`)
      .then((res) => {
        console.log("Full Partner Data:", res.data);
        console.log("Photo Object:", res.data.photo);

        setPartnerData(res.data);

        // محاولة تحديد مصدر الصورة بشكل أكثر مرونة
        if (res.data.photo) {
          const imageSource =
            res.data.photo.image || res.data.photo.path || res.data.photo.url;

          console.log("Image Source:", imageSource);
          setDefimg(imageSource);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching partner details:", err);
        Alert(err);
        setLoading(false);
      });
  };

  const getPartnerType = (typeId) => {
    switch (typeId) {
      case 1:
        return "عميل";
      case 2:
        return "بائع";
      case 3:
        return "عميل بائع";
      default:
        return "";
    }
  };

  const handleImageError = () => {
    console.error("Image failed to load");
    setImageError(true);
  };

  if (loading) {
    return <div>جار التحميل...</div>;
  }

  if (!partnerData) {
    return <div>لم يتم العثور على بيانات </div>;
  }

  // محاولة عرض الصورة مع التعامل مع الأخطاء
  const imageToShow =
    !imageError &&
    (partnerData.photo?.image || partnerData.photo?.path || defimg)
      ? partnerData.photo.image || partnerData.photo.path || defimg
      : null;

  return (
    <div className={classes.root}>
      <div className="headerSearch my-3 m-auto w-50 center p-3 ">
        تفاصيل {getPartnerType(partnerData.partnerTypeId)}
      </div>

      <Card className="headerSearch col-12 col-md-7  mt-0 center">
        <CardContent>
          {/* Profile Image */}
          {imageToShow && (
            <div  className={classes.imageContainer}>
              <img
                src={`data:image/jpeg;base64,${partnerData.photo.image}`}
                alt="الصورة الشخصية"
                className={classes.profileImage}
                onError={(e) => {
                  console.error("Image load error", e);
                  e.target.style.display = "none";
                }}
              />
            </div>
          )}

          <Row>
            <Col md={6} className={classes.detailItem}>
              <Typography variant="body1">
                <span className={classes.label}>الاسم:</span>
                <span className={classes.value}>{partnerData.name}</span>
              </Typography>
            </Col>

            <Col md={6} className={classes.detailItem}>
              <Typography variant="body1">
                <span className={classes.label}>الهاتف :</span>
                <span className={classes.value}>
                  {partnerData.phone1 || "غير متوفر"}
                </span>
              </Typography>
            </Col>

            <Col md={6} className={classes.detailItem}>
              <Typography variant="body1">
                <span className={classes.label}>الهاتف :</span>
                <span className={classes.value}>
                  {partnerData.phone2 || "-"}
                </span>
              </Typography>
            </Col>

            {partnerData.city && (
              <>
                <Col md={6} className={classes.detailItem}>
                  <Typography variant="body1">
                    <span className={classes.label}>المحافظة:</span>
                    <span className={classes.value}>
                      {partnerData.city.govornorate?.name || "-"}
                    </span>
                  </Typography>
                </Col>

                <Col md={6} className={classes.detailItem}>
                  <Typography variant="body1">
                    <span className={classes.label}>المدينة:</span>
                    <span className={classes.value}>
                      {partnerData.city.name || "-"}
                    </span>
                  </Typography>
                </Col>
              </>
            )}

            <Col xs={12} className={classes.detailItem}>
              <Typography variant="body1">
                <span className={classes.label}>العنوان:</span>
                <span className={classes.value}>
                  {partnerData.address || "-"}
                </span>
              </Typography>
            </Col>

            {partnerData.collectingAddress && (
              <Col md={6} className={classes.detailItem}>
                <Typography variant="body1">
                  <span className={classes.label}>خط التحصيل:</span>
                  <span className={classes.value}>
                    {partnerData.collectingAddress.name || "غير متوفر"}
                  </span>
                </Typography>
              </Col>
            )}
          </Row>

          <div className="text-center mt-4">
            <Button
              className="btnMore mx-auto"
              variant="contained"
              color="default"
              onClick={() => history.goBack()}
              style={{
                height: 40,
                fontWeight: 600,
                color: "#FFF",
                width: "150px",
              }}
            >
              العودة
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default PartnerDetails;
