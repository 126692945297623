import { CardContent, Grid, TextField } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePicker from "react-datepicker";
import ar from "date-fns/locale/ar-EG";
import { makeStyles } from "@material-ui/core/styles";
import { registerLocale } from "react-datepicker";
registerLocale("ar", ar);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  form: {
    marginTop: 24,
  },
}));
export default function ToolsSearch({ searchData, setSearchData }) {
  const partners = useSelector((state) => state.data.partners);

  return (
    <CardContent className="headerSearch mt-3">
      <Grid container justifyContent="center" spacing={2}>
        <Grid item>
          <Autocomplete
            options={partners}
            getOptionSelected={(option, value) => {
              return option.value === value?.value;
            }}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            style={{ width: 300 }}
            autoHighlight
            autoComplete
            noOptionsText="لم يتم العثور على خيارات"
            onChange={(event, value) => {
              if (value) {
                setSearchData((state) => ({ ...state, partner: value }));
              } else {
                setSearchData((state) => ({ ...state, partner: "" }));
              }
            }}
            value={searchData.partner}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="الاسم"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item>
          <DatePicker
            locale="ar"
            selected={searchData.dateTime}
            onChange={(d) => {
              setSearchData((state) => ({ ...state, dateTime: d }));
            }}
            dateFormat="dd/MM/yyyy"
            customInput={
              <TextField
                variant="outlined"
                id="dateTime"
                style={{ width: 300, backgroundColor: "#FFF" }}
              />
            }
          />
        </Grid>
      </Grid>
    </CardContent>
  );
}
