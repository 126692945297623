import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      //width: '100%',
    },
    [theme.breakpoints.up("md")]: {
      width: 520,
    },
  },
}));
const initialState = {
  independentCommision: 0,
  commisionPercentage: 0,
  carryingPrice: 0,
  officeTips: 0,
  movementToolRent: 0,
  fridgeRent: 0,
  sharedPartner: {},
};
function Deductions({
  onAddDeductionsClicked,
  onUpdateClicked,
  deduction,
  onDisableEdit,
}) {
  const [ownerName, setOwnerName] = useState("");
  const [sharedPartners, setSharedPartners] = useState([]);
  const [state, setState] = useState(initialState);
  const load = useSelector((state) => state.loads.load);
  const truck = useSelector((state) => state.invoice?.truck);
  const truckId = truck?.id || load?.data?.id;
  const isShared = truck?.sharedTruck || load?.data?.sharedTruck;
  const staticOwnerId = truck?.farmer?.id || load?.data?.farmer?.id;
  const classes = useStyles();

  React.useEffect(() => {
    if (!!deduction) {
      setState(deduction);
    }
  }, [deduction]);
  function handleState(key, value) {
    setState((prev) => ({ ...prev, [key]: value }));
  }
  useEffect(() => {
    !!truckId && fetchSharedpartners();
  }, [truckId]);
  async function fetchSharedpartners() {
    try {
      const res = await axios.get(`Truck/GetSharedPartners/${truckId}`);
      setSharedPartners(res.data);
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <form className="w-100 p-3" noValidate autoComplete="off">
      <Grid
        container
        justifyContent="center"
        spacing={2}
        className="headerSearch center p-3"
        style={{
          boxShadow:
            "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
          background: "#F3C637",
        }}
      >
        {!!isShared && (
          <Grid item sm={6} md={3}>
            <Autocomplete
              options={sharedPartners}
              getOptionSelected={(option, value) =>
                option.value === value?.value
              }
              getOptionLabel={(option) => (option.name ? option.name : "")}
              autoHighlight
              value={state.sharedPartner}
              id="0"
              onChange={(e, newValue) => {
                if (newValue) {
                  handleState("sharedPartner", newValue);
                } else {
                  handleState("sharedPartner", {});
                }
              }}
              onInputChange={(e, v) => {
                setOwnerName(v);
              }}
              inputValue={ownerName}
              renderInput={(params) => (
                <TextField
                  autoFocus
                  {...params}
                  label="عميل مشترك"
                  variant="outlined"
                  size="small"
                  className="w-100"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
        )}
        <Grid item sm={6} lg={2}>
          <TextField
            type="number"
            size="small"
            label="قيمة العمولة"
            variant="outlined"
            value={
              state.independentCommision >= 0 ? state.independentCommision : ""
            }
            onChange={(e) =>
              handleState(
                "independentCommision",
                Number.parseFloat(e.target.value)
              )
            }
          />
        </Grid>
        <Grid item sm={6} lg={2}>
          <TextField
            type="number"
            size="small"
            label="نسبة العمولة"
            variant="outlined"
            value={
              state.commisionPercentage >= 0 ? state.commisionPercentage : ""
            }
            onChange={(e) =>
              handleState(
                "commisionPercentage",
                Number.parseFloat(e.target.value)
              )
            }
          />
        </Grid>
        <Grid item sm={6} lg={1}>
          <TextField
            type="number"
            size="small"
            label="مشال"
            variant="outlined"
            value={state.carryingPrice >= 0 ? state.carryingPrice : ""}
            onChange={(e) =>
              handleState("carryingPrice", Number.parseFloat(e.target.value))
            }
          />
        </Grid>
        <Grid item sm={6} lg={1}>
          <TextField
            type="number"
            size="small"
            label="مكتب"
            variant="outlined"
            value={state.officeTips >= 0 ? state.officeTips : ""}
            onChange={(e) =>
              handleState("officeTips", Number.parseFloat(e.target.value))
            }
          />
        </Grid>
        <Grid item sm={6} lg={1}>
          <TextField
            type="number"
            size="small"
            label="ايجار عدة"
            variant="outlined"
            value={state.movementToolRent >= 0 ? state.movementToolRent : ""}
            onChange={(e) =>
              handleState("movementToolRent", Number.parseFloat(e.target.value))
            }
          />
        </Grid>
        <Grid item sm={6} lg={1}>
          <TextField
            type="number"
            size="small"
            label="ثلاجة"
            variant="outlined"
            value={state.fridgeRent >= 0 ? state.fridgeRent : ""}
            onChange={(e) =>
              handleState("fridgeRent", Number.parseFloat(e.target.value))
            }
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                // onAddDeductionsClicked();
              }
            }}
          />
        </Grid>
        {deduction && deduction.id ? (
          <>
            <Grid item sm={6} lg={1}>
              <Button
                variant="outlined"
                style={{ height: 40 }}
                className="btnMore p-3"
                onClick={() => {
                  onUpdateClicked({
                    ...Object.keys(state).reduce((result, key) => {
                      result[key] = state[key] || 0;
                      return result;
                    }, {}),
                    sharedPartnerId: state.sharedPartner?.id || staticOwnerId,
                    deductionId: deduction.id,
                  });
                  setState(initialState);
                }}
                size="small"
              >
                تعديل
              </Button>
            </Grid>
            {/* <Grid item sm={6} lg={1}>
              <Button
                variant="outlined"
                style={{ height: 40 }}
                className="btnMore p-3"
                onClick={onDisableEdit}
                size="small"
              >
                الغاء
              </Button>
            </Grid> */}
          </>
        ) : (
          <Grid item sm={6} lg={1}>
            <Button
              variant="contained"
              color="secondary"
              style={{ height: 40 }}
              onClick={() => {
                onAddDeductionsClicked({
                  ...Object.keys(state).reduce((result, key) => {
                    result[key] = state[key] || 0;
                    return result;
                  }, {}),
                  sharedPartnerId: state.sharedPartner?.id || staticOwnerId,
                });
                setState(initialState);
              }}
              size="small"
            >
              اضافة
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
}

export default Deductions;
