import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { deleteCommisionItem, productSelected } from "../loadsSlice";
import { makeStyles, withStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { ConvertToArabicNumbers } from "../../../utils";
import DeleteBox from "../../../components/DeleteBox";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditItemForm from "./EditItemForm";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  table: {},
  tableBody: {
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  moveBtn: {
    height: 40,
    [theme.breakpoints.down("md")]: {
      padding: 4,
      fontSize: 12,
      height: 32,
    },
  },
  trash: {
    color: theme.palette.error.light,
  },
  edit: {
    color: theme.palette.info.light,
  },
}));

export default function ItemsTable({ items, truckTypeId, onCloseModal }) {
  const [deletedItemId, setDeletedItemId] = useState("");
  const deleteStatus = useSelector(
    (state) => state.loads.commissionItems.deleteStatus
  );
  const [open, setOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const load = useSelector((state) => state.loads.load.data);

  const dispatch = useDispatch();

  const formatNumberIfDecimal = (number) => {
    return Number.isInteger(number) ? number : Number(number).toFixed(2);
  };

  const onDeleteItemClicked = () => {
    dispatch(deleteCommisionItem(deletedItemId));
    setOpenDelete(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onMoveSelected = (item) => {
    onCloseModal();
    dispatch(productSelected(item));
  };
  const classes = useStyles();
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.grey[700],
      color: theme.palette.common.white,
      [theme.breakpoints.down("md")]: {
        fontSize: 12,
      },
    },
    body: {
      fontSize: 16,
      overflow: "auto",
      [theme.breakpoints.down("md")]: {
        padding: 2,
        fontSize: 14,
      },
    },
  }))(TableCell);

  const tableHeader =
    truckTypeId === 1
      ? [
          "عميل مشترك",
          "الصنف",
          "العدد",
          "العدة",
          "عدد البيع",
          "متبقي",
          "تعديل",
          "حذف",
          "",
        ]
      : [
          "عميل مشترك",
          "الصنف",
          "العدد",
          "العدة",
          "عدد البيع",
          "متبقي",
          "الوزن",
          "السعر",
          "دلالة",
          "القيمة",
          "تعديل",
          "حذف",
          "",
        ];
  !load.sharedTruck && tableHeader.splice(0, 1);
  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableHeader.map((item, index) => (
                <StyledTableCell
                  key={index}
                  align="center"
                  style={{
                    background: "#FED049",
                    height: "65px",
                    color: "black",
                  }}
                >
                  <Typography variant="body2" style={{ fontWeight: 600 }}>
                    {item}
                  </Typography>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.length > 0 &&
              items.map((item, index) => {
                return (
                  <TableRow key={index} className={classes.tableBody}>
                    {!!load.sharedTruck && (
                      <StyledTableCell
                        align="center"
                        component="th"
                        scope="row"
                      >
                        {item.sharedPartner?.name}{" "}
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="center" component="th" scope="row">
                      {item && item.product && item.product.name
                        ? item.product.name
                        : ""}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {ConvertToArabicNumbers(item.itemsCount)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {item.mostMovementTool ? item.mostMovementTool.name : ""}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {!isNaN(item.remainingProductCount) &&
                      item.itemsCount === item.remainingProductCount
                        ? "صفر"
                        : ConvertToArabicNumbers(
                            item.itemsCount - item.remainingProductCount
                          )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {!isNaN(item.remainingProductCount) && (
                        <Typography
                          variant="body1"
                          style={
                            item.remainingProductCount < 0
                              ? { color: "#E21" }
                              : {}
                          }
                          color="textPrimary"
                        >
                          {item.remainingProductCount === 0
                            ? "صفر"
                            : ConvertToArabicNumbers(
                                Math.abs(item.remainingProductCount)
                              )}
                        </Typography>
                      )}
                    </StyledTableCell>
                    {truckTypeId === 2 && (
                      <>
                        <StyledTableCell align="center">
                          {ConvertToArabicNumbers(item.weight)}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {ConvertToArabicNumbers(item.price)}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.commisionForClient === 0
                            ? "صفر "
                            : ConvertToArabicNumbers(item.commisionForClient)}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {(item.price >= 0 || item.commisionForClient >= 0) &&
                            (item.itemsCount >= 0 || item.weight >= 0) &&
                            ConvertToArabicNumbers(
                              formatNumberIfDecimal(
                                item.price * item.weight +
                                  item.commisionForClient * item.itemsCount
                              )
                            )}
                        </StyledTableCell>
                      </>
                    )}

                    <TableCell
                      padding="none"
                      align="center"
                      style={{ width: "32px" }}
                    >
                      <IconButton
                        className={classes.edit}
                        onClick={() => {
                          setOpen(true);
                          setSelectedItem(item);
                        }}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      padding="none"
                      align="center"
                      style={{ width: "32px" }}
                    >
                      {deleteStatus === "loading" &&
                      deletedItemId === item.id ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        <IconButton
                          className={classes.trash}
                          onClick={() => {
                            setOpenDelete(true);
                            setDeletedItemId(item.id);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      className="p-0 "
                      style={{ cursor: "pointer" }}
                    >
                      <button
                        className="btnMore w-100 h-100 "
                        onClick={() => onMoveSelected(item)}
                      >
                        ترحيل
                      </button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <EditItemForm
        truckTypeId={truckTypeId}
        open={open}
        onClose={handleClose}
        selectedItem={selectedItem}
      />

      <DeleteBox
        action={onDeleteItemClicked}
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        message="هل تريد حذف العنصر؟"
      />
    </>
  );
}
