import "date-fns";
import "react-datepicker/dist/react-datepicker.css";

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  ConvertToArabicNumbers,
  getDateDashed,
  renderDate,
} from "../../../utils";
import React, { useEffect, useState } from "react";
import { addPartner, fetchClients } from "../../main-data/dataSlice";
import {
  deleteLoad,
  getTruck,
  initializeLoad,
  updateLoad,
} from "../loadsSlice";
import { useDispatch, useSelector } from "react-redux";

import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePicker from "react-datepicker";
import DeleteBox from "../../../components/DeleteBox";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import MuiAlert from "@material-ui/lab/Alert";
import ar from "date-fns/locale/ar-EG";
import { makeStyles } from "@material-ui/styles";
import { parseISO } from "date-fns";
import { registerLocale } from "react-datepicker";
import { toast } from "react-toastify";
import axios from "axios";
import Alert from "../../../components/UI/toaster";
import Alerto from "../../../components/UI/toaster";
import Mydate from "../../../components/Helpers/MyDateParser";
import CustomInput from "../../../features/treasury/components/CustomInput";

import { useHistory } from "react-router-dom";
import {
  InputSorting,
  isDayPast,
} from "../../../components/Helpers/InsputSorting";
import { Container } from "react-bootstrap";
import MainHeader from "../../../components/mainHeader/MainHeader";
registerLocale("ar", ar);

const useStyles = makeStyles((theme) => ({
  number: {
    backgroundColor: "#fff",
    borderRadius: 7,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 16px",
    width: 180,
    marginBottom: 8,
    boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      boxShadow: "0 0 0",
    },
  },
  edit: {
    display: "flex",
    justifyContent: "space-around",
    width: "30%",
  },
  delBtn: {
    backgroundColor: theme.palette.error.main,
    width: 80,
    marginLeft: 16,
    height: 40,
  },
  editIcon: {
    color: theme.palette.info.main,
  },
  trash: {
    color: theme.palette.error.light,
  },
  details: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "70%",
  },
  label: {
    fontSize: 16,
    color: theme.palette.grey[600],
    display: "inline-block",
  },
  loadData: {
    fontSize: 18,
    marginLeft: 8,
    display: "inline",
  },
  icon: {
    color: theme.palette.grey[600],
    fontSize: 18,
    marginRight: 3,
  },
  deleteIcon: {
    color: theme.palette.error.light,
    fontSize: 80,
    marginBottom: 24,
  },
}));

const LoadsToolbar = ({ onClick, truckId }) => {
  const load = useSelector((state) => state.loads.load);
  const clients = useSelector((state) => state.data.clients);
  const [client, setClient] = useState({});
  const [clientName, setClientName] = useState("");
  const [truckTypeId, setTruckTypeId] = useState(1);
  const role = localStorage.getItem("role");
  const [truckDate, setTruckDate] = React.useState(new Date());
  const disableAll = role == "موظف" && isDayPast(load.data?.truckDate);
  const [truckIdForThisDayAndClient, seTruckForThisDay] = React.useState(
    load.data?.truckIdForThisDayAndClient
  );
  useEffect(() => {
    load?.data?.truckIdForThisDayAndClient &&
      seTruckForThisDay(load.data?.truckIdForThisDayAndClient);
    load?.data?.truckTypeId && setTruckTypeId(load.data.truckTypeId);
  }, [load]);
  const history = useHistory();
  const refreshclients = () => {
    dispatch(fetchClients());
  };
  useEffect(() => {
    refreshclients();
  }, []);
  const inputRef = React.useRef(null);
  const submitNewClient = async () => {
    let isExist = false;
    let arr = Array.from(clientName);
    let valid = arr.filter((ele, index) => {
      return ele.trim() != "" || arr[index + 1].trim() != "";
    });
    let str = valid.join("");
    clients.filter((farmer) => {
      if (farmer.name === str) {
        return (isExist = true);
      } else {
        return null;
      }
    });
    if (!isExist && str.length > 0) {
      axios
        .get("Partners/UnActive")
        .then((res) => {
          let cliexist = res.data.filter((ele) => ele.name === str);

          if (cliexist[0]) {
            const name = prompt(" العميل غير مفعل ,, اضغط نعم للتفعيل ", str);
            if (name) {
              axios
                .put(`Partners/recover/${cliexist[0].id}`)
                .then((res) => {
                  refreshclients();
                  toast.success("تم التفعيل");
                  setClient(cliexist[0]);
                })
                .catch((e) => {
                  Alerto(e);
                });
            }
          } else {
            axios
              .get(`/Partners/isBuyer/${str}`)
              .then((res) => {
                if (res.data.id) {
                  const name = prompt(
                    "أضغط نعم لتحويل البائع الي عميل بائع ",
                    str
                  );
                  if (name) {
                    axios
                      .put(`/Partners/toClientBuyer/${res.data.id}`)
                      .then((res1) => {
                        refreshclients();
                        setClient(res1.data);
                        toast.success("تمت الاضافة");
                        return;
                      })
                      .catch((e) => {
                        Alert(e);
                      });
                  }
                } else {
                  const name = prompt(
                    "اسم العميل او الفلاح غير موجود اختار نعم لاضافته",
                    str
                  );
                  if (name) {
                    dispatch(
                      addPartner({
                        Name: name,
                        OpeningBalance: 0,
                      })
                    )
                      .then(({ payload }) => {
                        if (payload && payload.id) {
                          setClient(payload);
                          toast.success("تمت الاضافة");
                          return;
                        }
                      })
                      .catch((e) => {
                        toast.error("ححدث خطأ ما");
                        setClient("");
                      });
                  }
                }
              })
              .catch((e) => {
                Alerto(e);
              });
          }
        })
        .catch((e) => {
          Alerto(e);
        });

      setClientName("");
      inputRef.current.focus();
    }
  };

  const classes = useStyles();

  const loadTypes = [
    { id: 1, name: "عمولة" },
    { id: 2, name: "مشتريات" },
  ];

  const handleLoadType = (e) => {
    setTruckTypeId(e.target.value);
  };

  const dispatch = useDispatch();
  const [isFormOpen, setIsFormOpen] = useState(
    load.data && load.data.id ? false : true
  );

  const [showSnack, setShowSnack] = useState(false);
  const submitLoad = () => {
    if (client.id && truckDate && truckTypeId) {
      dispatch(
        initializeLoad({
          farmer: client,
          truckDate: Mydate(truckDate),
          truckTypeId,
        })
      ).then(({ payload }) => {
        if (payload.id) {
          dispatch(getTruck(payload.id))
            .then((res) => {
              seTruckForThisDay(res.payload.truckIdForThisDayAndClient);
            })
            .catch((e) => {
              Alerto(e);
            });
          onClick();
        }
      });
      setClient({});
      setClientName("");
      setIsFormOpen(false);
    } else {
      setShowSnack(true);
    }
  };

  const [openDelete, setOpenDelete] = React.useState(false);
  const deleteTruck = () => {
    setOpenDelete(false);
    dispatch(deleteLoad())
      .then(() => {
        setIsFormOpen(true);
        history.replace("/loads");
      })
      .catch(() => {
        setIsFormOpen(false);
      });
  };

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const onUpdateClicked = () => {
    setIsFormOpen(true);
    dispatch(
      updateLoad({
        farmer: client,
        truckDate: Mydate(truckDate),
        truckTypeId,
      })
    )
      .then((res) => {
        seTruckForThisDay(res.payload.truckIdForThisDayAndClient);
        setIsFormOpen(false);
        setIsEditFormOpen(false);
      })
      .catch((err) => {
        setIsFormOpen(false);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setShowSnack(false);
    }
  };

  function Alert1(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    if (truckId) {
      setIsFormOpen(false);
    }

    if (load.data) {
      setClient(load.data.farmer);
    }
  }, [load]);

  const [change, setChange] = useState(false);
  const addNewTruck = () => {
    window.location = "/loads";

    setIsFormOpen(true);
    setTruckTypeId(2);
    setChange(true);
  };
  const newTruck = () => {
    if (truckTypeId === 2) setChange(true);
  };
  return (
    <div
      className=" w-100 p-0 pt-1 margin15px "
      style={{ background: "#534d3b", outline: " 8px solid #534d3b" }}
    >
      <div className="mt-5">
        <MainHeader title="اضف نقلة" />
      </div>
      <Container className="">
        <Box className="h-100">
          <Box className="m-0 p-0">
            {isFormOpen ? (
              <div className="headerSearch py-2 mb-0 mt-3">
                <CardContent className="w-100 py-3">
                  <form noValidate autoComplete="off ">
                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={6} lg={3} className="m-auto">
                        <Autocomplete
                          options={clients}
                          size="small"
                          getOptionSelected={(option, value) =>
                            option.value === value?.value
                          }
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          filterOptions={(options, state) =>
                            InputSorting(options, state.inputValue)
                          }
                          autoHighlight
                          autoComplete
                          freeSolo
                          noOptionsText="لم يتم العثور على خيارات"
                          onChange={(event, value) => {
                            if (value) {
                              setClient(value);
                            } else {
                              setClient("");
                            }
                          }}
                          onInputChange={(e, v) => {
                            setClientName(v);
                          }}
                          value={client}
                          inputValue={clientName}
                          renderInput={(params) => (
                            <TextField
                              autoFocus
                              onBlur={submitNewClient}
                              inputRef={inputRef}
                              {...params}
                              label="اسم العميل"
                              variant="outlined"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                                form: {
                                  autoComplete: "off",
                                },
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3} className="m-auto">
                        <DatePicker
                          locale="ar"
                          selected={truckDate}
                          onChange={(date) => setTruckDate(date)}
                          dateFormat="dd/MM/yyyy"
                          disabled={role == "موظف"}
                          customInput={
                            <TextField
                              label=" تاريخ الفاتورة"
                              disabled={role == "موظف"}
                              variant="outlined"
                              size="small"
                              style={{ width: "100%" }}
                            />
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={3} className="m-auto">
                        <TextField
                          disabled={load.data?.truckTypeId}
                          size="small"
                          style={{ width: "100%" }}
                          select
                          onChange={handleLoadType}
                          label="نوع النقلة "
                          value={truckTypeId}
                          variant="outlined"
                        >
                          {loadTypes.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      {/* )} */}
                      {!isEditFormOpen && (
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={3}
                          className="m-auto center"
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={submitLoad}
                            className="globalFont"
                            style={{
                              color: "white !important",
                              height: "38px",
                              boxShadow:
                                "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset",
                              padding: "25px",
                            }}
                          >
                            انشاء نقلة جديدة
                          </Button>
                        </Grid>
                      )}
                      {isEditFormOpen && (
                        <>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={onUpdateClicked}
                              style={{
                                color: "white !important",
                                boxShadow:
                                  "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset",
                                padding: "10px 25px",
                              }}
                              className="center"
                            >
                              تعديل البيانات
                            </Button>
                          </Grid>

                          <Grid item>
                            <Button
                              variant="text"
                              onClick={() => {
                                setIsFormOpen(false);
                                setIsEditFormOpen(false);
                              }}
                              className="globalFont text-white center"
                              style={{
                                color: "white !important",
                                boxShadow:
                                  "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset",
                                padding: "10px 25px",
                                background: "#AD081B",
                              }}
                            >
                              رجوع
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </form>
                </CardContent>
              </div>
            ) : (
              load.data &&
              load.data.id && (
                <Card
                  style={{ height: "auto" }}
                  className="bg-transparent mt-5 "
                >
                  <CardContent className=" h-100 m-auto headerSearch  center p-1 px-3  ">
                    <Grid container spacing={2} className="  m-auto py-3">
                      <Grid
                        item
                        container
                        lg={9}
                        spacing={3}
                        alignItems="center"
                      >
                        <Grid item xs={12} lg={3}>
                          <Typography
                            className={classes.label}
                            style={{ fontSize: "16.6px !important" }}
                          >
                            اسم العميل:
                          </Typography>
                          <Typography className="d-inline-block globalFont ">
                            {" "}
                            {load.data.farmer ? load.data.farmer.name : ""}{" "}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={6} lg={2}>
                          <Typography className={classes.label}>
                            النوع:
                          </Typography>
                          <Typography className={classes.loadData}>
                            {Boolean(load.data.truckTypeId) &&
                            load.data.truckTypeId === 1
                              ? "عمولة"
                              : "مشتريات"}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                          <Typography className={classes.label}>
                            التاريخ :
                          </Typography>
                          <Typography className={classes.loadData}>
                            {ConvertToArabicNumbers(
                              renderDate(load.data.truckDate)
                            )}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={6} lg={2}>
                          <Typography className={classes.label}>
                            رقم النقلة:
                          </Typography>
                          <Typography
                            variant="body1"
                            className={classes.loadData}
                          >
                            {ConvertToArabicNumbers(truckIdForThisDayAndClient)}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <IconButton
                            disabled={disableAll}
                            className={classes.editIcon}
                            onClick={() => {
                              setIsFormOpen(true);
                              setIsEditFormOpen(true);
                              setTruckDate(new Date(load.data.truckDate));
                              newTruck();
                            }}
                          >
                            <EditOutlinedIcon />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <IconButton
                            disabled={disableAll}
                            className={classes.trash}
                            onClick={() => {
                              setOpenDelete(true);
                              setChange(false);
                            }}
                          >
                            <DeleteForeverOutlinedIcon />
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        container
                        spacing={2}
                        xs={12}
                        md={8}
                        lg={3}
                        alignItems="center"
                        className="center justify-content-end"
                      >
                        <Grid item>
                          <Button
                            variant="contained"
                            className="globalFont "
                            style={{
                              height: "38px",
                              boxShadow:
                                "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset",
                              padding: "25px 10px",
                              background: "#06CEA8",
                            }}
                            size="small"
                            onClick={() => {
                              onClick();
                            }}
                          >
                            اصناف النقلة
                          </Button>
                        </Grid>

                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            className="globalFont btnMore"
                            style={{
                              color: "white !important",
                              height: "38px",
                              boxShadow:
                                "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset",
                              padding: "25px 10px",
                              background: "#06CEA8",
                            }}
                            size="small"
                            onClick={() => {
                              addNewTruck();
                              setChange(false);
                            }}
                          >
                            نقلة جديدة
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              )
            )}
          </Box>
          <DeleteBox
            action={deleteTruck}
            open={openDelete}
            onClose={() => setOpenDelete(false)}
            message="هل تريد حذف النقلة"
          />
        </Box>
        <Snackbar
          open={showSnack}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert1 onClose={handleClose} severity="warning">
            اكمل بيانات النقلة!
          </Alert1>
        </Snackbar>
      </Container>
    </div>
  );
};
export default LoadsToolbar;
