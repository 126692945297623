import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { infoCardHeader } from "../../../components/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DetailsIcon from "@material-ui/icons/Details";
import InfoIcon from "@mui/icons-material/Info";
import DatePicker from "react-datepicker";
import ar from "date-fns/locale/ar-EG";
import { makeStyles } from "@material-ui/core/styles";
import { registerLocale } from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import Alerto from "../../../components/UI/toaster";
import axios from "axios";
import Mydate from "../../../components/Helpers/MyDateParser";
import { InputSorting } from "../../../components/Helpers/InsputSorting";
import { ConvertToArabicNumbers } from "../../../utils";
import { getRemainingDetailsForPartners } from "../../movement-tools/tools-reports/toolsSlice";
import RemainingDetails from "../../movement-tools/components/RemainingDetails";
import { Container } from "react-bootstrap";
import ToolsInfo from "../../../components/ToolsForm/partials/ToolsInfo";

registerLocale("ar", ar);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
    backgroundColor: "white",
  },
  qty: {
    background: "#f3c637 !important",
    color: "black !important",
    boxShadow:
      "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset",
    height: "50px",
    padding: "0 20px",
    width: "content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  para: {
    margin: "0 0 0 10px",
  },
  title: {
    fontSize: 24,
    marginBottom: 16,
  },
  formContent: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    "& > *": {
      marginRight: 12,
    },
  },
}));

const getStateColor = (Id, state) => {
  switch (Id) {
    case 1:
      return [
        { id: 7, name: "دخان" },
        { id: 1, name: "رهن_مشتروات" },
        { id: 4, name: "عملة_مقدمة" },
        { id: 6, name: "مصروفات_أخري_منصرف" },
        { id: 5, name: "مصروفات_أخري_هالك" },
        { id: 2, name: "نقدية" },
        { id: 3, name: "نولون" },
      ];

    case 2:
      if (state) {
        return [
          { id: 13, name: "إرجاع_رهن" },
          { id: 8, name: "راتب" },
          { id: 11, name: "سلف_بائع" },
          { id: 12, name: "سلفة_موظفين" },
        ];
      }
      return [
        { id: 13, name: "إرجاع_رهن" },
        { id: 11, name: "سلف_بائع" },
      ];

    case 3:
      return [
        { id: 7, name: "دخان" },
        { id: 1, name: "رهن_مشتروات" },
        { id: 4, name: "عملة_مقدمة" },
        { id: 6, name: "مصروفات_أخري_منصرف" },
        { id: 5, name: "مصروفات_أخري_هالك" },
        { id: 2, name: "نقدية" },
        { id: 3, name: "نولون" },
        { id: 13, name: "إرجاع_رهن" },
        { id: 11, name: "سلف_بائع" },
      ];

    default:
      return [{ undefined }];
  }
};
const handleMoney = async (partner) => {
  try {
    const remain = await axios.get(
      `MovementTools/reports/Remaining/price/${partner.id}`
    );
    const buyer = await axios.get(
      `MoneyCurrentAccountReport/debtDetails/buyer/${partner.id}`
    );
    const client = await axios.get(
      `MoneyCurrentAccountReport/debtDetails/client/${partner.id}`
    );
    const tool = await axios.get(
      `MovementTools/reports/Remaining/${partner.id}`
    );
    const totalToolsCount =
      tool.data?.length > 0 &&
      tool.data.reduce((acc, val) => {
        return acc + val.count;
      }, 0);
    let obj = {
      remain: remain.data,
      tool: totalToolsCount,
      inAdvance: partner.partnerTypeId == 2 ? null : client.data.totalLoans,
      truckInvoice:
        partner.partnerTypeId == 2 ? null : client.data.totalInvoices,
      totalLoans: partner.partnerTypeId == 1 ? null : buyer.data.totalLoans,
      totalInvoices:
        partner.partnerTypeId == 1 ? null : buyer.data.totalInvoices,
    };

    return obj;
  } catch (e) {
    Alerto(e);
  }
};

function ExpensesForm({
  onSubmit,
  expense,
  isUpdate,
  onUpdateClicked,
  type,
  partnerId,
  factor,
}) {
  const expenseCategories = useSelector(
    (state) => state.expenses.expenseCategories
  );
  const remainingDetails = useSelector((state) => state.tools.remainingDetails);

  let partners = useSelector((state) => state.data.partners);
  const [partner, setPartner] = useState("");
  const [dateTime, setDateTime] = useState(new Date());
  const [category, setCategory] = useState("");
  const [categoryes, setCategoryes] = useState([]);
  const role = localStorage.getItem("role");
  const [money, setMoney] = useState("");
  const [moneyAnalys, setMoneyAnalys] = useState({});
  const [notes, setNotes] = useState(" ");
  const [section, setsection] = useState("نفقات");
  const priceRef = useRef();
  const handleSubmit = () => {
    if (type === "staff") {
      onSubmit({
        partner,
        dateTime: Mydate(new Date()),
        money,
        notes,
        category: {
          id: 12,
          name: "سلف موظفين",
        },
      });
    }
    onSubmit({
      partner,
      dateTime: Mydate(new Date()),
      money,
      notes,
      category,
    });
    setPartner("");
    setDateTime(new Date());
    setMoney("");
    setCategory("");
    setNotes("");
  };

  React.useEffect(() => {
    if (expense) {
      let subcat = {};
      subcat = { id: expense.categoryTypeId, name: expense.categoryName };

      setPartner(expense.partner);
      setDateTime(expense.dateTime);
      setMoney(expense.money);

      setCategory(subcat);
      setNotes(expense.note);
    }
  }, [expense]);

  React.useEffect(() => {
    if (partnerId) {
      const target = partners.find((p) => {
        return p.id == partnerId;
      });

      target &&
        handleMoney(target).then((res) => {
          setMoneyAnalys(res);
          setPartner(target);
        });
    }
  }, [partnerId, partners]);
  useEffect(() => {
    partner?.id &&
      handleMoney(partner).then((res) => {
        setMoneyAnalys(res);
      });
  }, [partner]);
  if (type === "staff") {
    partners = partners.filter((buyer) => {
      return buyer.isEmployee;
    });
  }

  const classes = useStyles();
  React.useEffect(() => {
    let cat = [];

    if (
      partner &&
      section === "نفقات" &&
      (!expense || expense.isDamage !== true)
    ) {
      if (partner.partnerTypeId === 1) {
        cat = expenseCategories.client;
      }
      if (partner.partnerTypeId === 2) {
        cat = expenseCategories.buyer;
      }
      if (partner.partnerTypeId === 3) {
        cat = expenseCategories.clientBuyer;
      }
      if (expenseCategories.employee && partner.isEmployee) {
        cat = [...cat, ...expenseCategories.employee];
      }
    } else if (section === "هالك") {
      cat = expenseCategories.damage;
    }

    setCategoryes(cat);
  }, [isUpdate, section, partner, expenseCategories]);
  useEffect(() => {
    if (categoryes[0] && factor) {
      let target = categoryes.find((ele) => ele.id == factor);
      if (target) {
        setCategory(target);
        // priceRef.current.focus()
      }
    }
  }, [categoryes]);
  const dispatch = useDispatch();
  const [openDetails, setOpenDetails] = useState(false);
  const onDetailsClicked = (partnerId) => {
    setOpenDetails(true);
    dispatch(getRemainingDetailsForPartners(partnerId));
  };
  return (
    <div>
      <Container fluid>
        {!isUpdate && (
          <>
            <div style={{ display: "flex" }}>
              {partner?.id && <ToolsInfo partnerId={partner.id} />}
            </div>
            <div
              className={
                // load && load.truckTypeId === 1
                //   ? classes.commissionTruck
                classes.purchasesTruck
              }
            >
              {moneyAnalys && partner?.id && (
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography className={classes.qty}>
                      الرهن :{" "}
                      {ConvertToArabicNumbers(
                        Math.abs(Math.round(moneyAnalys.remain))
                      )}{" "}
                      {Math.round(moneyAnalys.remain) > 0 ? "عليه" : ""}
                      {Math.round(moneyAnalys.remain) < 0 ? "له" : ""}
                      {moneyAnalys.remain === 0 ? "صفر" : ""}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.qty}>
                      عدة:
                      {ConvertToArabicNumbers(
                        Math.abs(Math.round(moneyAnalys.tool))
                      )}{" "}
                      {Math.round(moneyAnalys.tool) > 0 ? "عليه" : ""}
                      {Math.round(moneyAnalys.tool) < 0 ? "له" : ""}
                      {moneyAnalys.tool === 0 ? "صفر" : ""}
                    </Typography>
                  </Grid>
                  {moneyAnalys.totalLoans != null && (
                    <Grid item>
                      <Typography className={classes.qty}>
                        سلف :{" "}
                        {moneyAnalys?.totalLoans == 0
                          ? "صفر"
                          : ConvertToArabicNumbers(
                              Math.round(Math.abs(moneyAnalys.totalLoans))
                            )}
                        <p className={classes.para}>
                          {moneyAnalys.totalLoans > 0 ? "عليه" : "له"}
                        </p>
                      </Typography>
                    </Grid>
                  )}
                  {moneyAnalys.totalInvoices != null && (
                    <Grid item>
                      <Typography className={classes.qty}>
                        فواتير مبيع:
                        {moneyAnalys?.totalInvoices == 0
                          ? "صفر"
                          : ConvertToArabicNumbers(
                              Math.round(Math.abs(moneyAnalys.totalInvoices))
                            )}
                        <p className={classes.para}>
                          {moneyAnalys.totalInvoices > 0 ? "عليه" : "له"}
                        </p>
                      </Typography>
                    </Grid>
                  )}
                  {moneyAnalys.inAdvance != null && (
                    <Grid item>
                      <Typography className={classes.qty}>
                        عملة مقدمة:
                        {moneyAnalys?.inAdvance == 0
                          ? "صفر"
                          : ConvertToArabicNumbers(
                              Math.round(Math.abs(moneyAnalys.inAdvance))
                            )}
                        <p className={classes.para}>
                          {moneyAnalys.inAdvance > 0 ? "عليه" : "له"}
                        </p>
                      </Typography>
                    </Grid>
                  )}
                  {moneyAnalys.truckInvoice != null && (
                    <Grid item>
                      <Typography className={classes.qty}>
                        فواتير نقلات:
                        {moneyAnalys?.truckInvoice == 0
                          ? "صفر"
                          : ConvertToArabicNumbers(
                              Math.round(Math.abs(moneyAnalys.truckInvoice))
                            )}
                        <p className={classes.para}>
                          {moneyAnalys.truckInvoice > 0 ? "عليه" : "له"}
                        </p>
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </div>
            <Divider />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={section}
              onChange={(e) => setsection(e.target.value)}
            >
              <FormControlLabel
                value="نفقات"
                control={<Radio />}
                label="نفقات"
              />
              <FormControlLabel value="هالك" control={<Radio />} label="هالك" />
            </RadioGroup>
          </>
        )}
        <CardContent className="headerSearch mt-2">
          <Grid item container spacing={1}>
            {section === "نفقات" &&
              (!expense || expense.isDamage === false) && (
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    disabled={expense?.isFromTruck}
                    options={partners}
                    getOptionSelected={(option, value) => {
                      return option.value === value?.value;
                    }}
                    getOptionLabel={(option) =>
                      option.name ? option.name : ""
                    }
                    style={{ width: "100%" }}
                    autoHighlight
                    autoComplete
                    filterOptions={(options, state) =>
                      InputSorting(options, state.inputValue)
                    }
                    noOptionsText="لم يتم العثور على خيارات"
                    onChange={(event, value) => {
                      if (value) {
                        setPartner(value);
                      } else {
                        setPartner("");
                      }
                    }}
                    value={partner}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        onSubmit();
                      }
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        autoFocus={!isUpdate}
                        {...params}
                        label="الاسم"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                      />
                    )}
                  />
                  {/* <CustomInput
                value={partner}
                options={partners}
                setClient={setPartner}
                width={"20rem"}
              /> */}
                </Grid>
              )}
            {type !== "staff" && (
              <Grid item className="col-6 col-lg">
                <Autocomplete
                  disabled={expense?.isFromTruck}
                  options={categoryes}
                  getOptionSelected={(option, value) => {
                    return option.id === value.id;
                  }}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  style={{ width: "100%" }}
                  autoComplete
                  onChange={(event, value) => {
                    if (value) {
                      setCategory(value);
                    } else {
                      setCategory("");
                    }
                  }}
                  noOptionsText="لم يتم العثور على خيارات"
                  value={category}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="الفئة "
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        form: {
                          autoComplete: "off",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item className="col-6 col-lg">
              <TextField
                value={money >= 0 ? money : ""}
                onChange={(e) => setMoney(Number.parseFloat(e.target.value))}
                style={{ width: "100%" }}
                label="المبلغ"
                variant="outlined"
                size="small"
                type="number"
                autoFocus={!!partnerId && true}
              />
            </Grid>

            {!isUpdate && (
              <Grid item className="col-6 col-lg-1">
                <Button
                  className="btnMore m-0 p-0"
                  style={{ cursor: "pointer", width: "100%", height: "41px" }}
                  type="submit"
                  onClick={handleSubmit}
                >
                  اضف
                </Button>
              </Grid>
            )}

            {isUpdate && (
              <Grid item className="col-6 col-lg">
                <DatePicker
                  locale="ar"
                  disabled={role == "موظف"}
                  selected={new Date(dateTime)}
                  onChange={(d) => setDateTime(d)}
                  dateFormat="dd/MM/yyyy"
                  customInput={
                    <TextField
                      label="التاريخ"
                      size="small"
                      variant="outlined"
                      id="dateTime"
                      style={{ width: "100%" }}
                    />
                  }
                />
              </Grid>
            )}

            <Grid item className="col-6 col-lg">
              <TextField
                disabled={expense?.isFromTruck}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                style={{ width: "100%" }}
                label="ملحوظة"
                variant="outlined"
                size="small"
              />
            </Grid>
            {isUpdate && (
              <Grid item className="col-6 col-lg-1">
                <Button
                  className="btnMore"
                  style={{ cursor: "pointer", width: "100%", height: "41px" }}
                  type="submit"
                  onClick={() => {
                    onUpdateClicked(
                      {
                        id: expense.expenseId,
                        partner,
                        dateTime: Mydate(dateTime),
                        money,
                        notes,
                        category,
                        isDamage: expense.isDamage,
                      },
                      expense
                    );
                  }}
                >
                  تعديل
                </Button>
              </Grid>
            )}
          </Grid>
        </CardContent>
        <RemainingDetails
          rows={remainingDetails}
          open={openDetails}
          onClose={() => setOpenDetails(false)}
        />
      </Container>
    </div>
  );
}

ExpensesForm.defaultProps = {
  isUpdate: false,
};

export default ExpensesForm;
