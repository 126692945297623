import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import ar from "date-fns/locale/ar-EG";
import { makeStyles } from "@material-ui/core/styles";
import { registerLocale } from "react-datepicker";
import axios from "axios";
import Alerto from "../../components/UI/toaster";
import { Autocomplete } from "@material-ui/lab";
import TruckHistoryTable from "./components/TruckHistoryTable";
import CommisonTable from "./components/TruckCommisonHistory";
import BuyingTable from "./components/BuyingTable";
import SellingsTable from "./components/SellingTable";
import DiscountsTable from "./components/DiscountsTable";
import InvoiceItemTable from "./components/InvoiceItemTable";
import RExpensesTable from "./components/RExpenseTable";
import Mydate from "../../components/Helpers/MyDateParser";
import FromToDateHandler from "./components/FromToDateHandler";
import MainHeader from "../../components/mainHeader/MainHeader";
const Size = 20;
const actions = [
  { label: "اضافة", id: "1", state: true },
  { label: "تعديل", id: "2", state: false },
  { label: "حذف", id: "3", state: false },
];
const categories = [
  { label: "الكل", id: "0", state: true },
  { label: "النقلات", id: "1", state: false },
  { label: "أصناف عمولة ", id: "2", state: false },
  { label: "أصناف مشتريات", id: "3", state: false },
  { label: "مبيعات", id: "4", state: false },
  { label: "خصومات", id: "5", state: false },
  { label: "فواتير", id: "6", state: false },
  { label: "مصروفات ", id: "7", state: false },
];
registerLocale("ar", ar);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  radio: {
    margin: "0vh 0vw 0vh 10vw",
    display: "block",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: 12,
  },
  SearchForm: {
    width: "20vw",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    "& > *": {
      marginRight: 12,
    },
  },
}));

function TrucksHistory() {
  const [mainData, setMainData] = useState([]);
  const [commision, setCommision] = useState([]);
  const [buying, setBuying] = useState([]);
  const [selling, setSelling] = useState([]);
  const [discount, setDiscount] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [fromTime, setFromTime] = useState(new Date());
  const [toTime, setToTime] = useState(new Date());
  const [parsedFrom, setParsedFrom] = useState(Mydate(new Date()));
  const [parsedTo, setParsedTo] = useState(Mydate(new Date()));
  const [Namesearch, setNameSearch] = useState("");
  const [buyerSearch, setBuyerSearch] = useState("");
  const [Actions, setActions] = useState(actions);
  const [categ, setCateg] = useState(categories);
  const checkhandler = (id) => {
    let element = categ.filter((ele) => ele.id == id);
    if (!element[0].state) {
      return false;
    }
    return true;
  };
  const handleCatg = (id) => {
    let arr = [...categ];
    let element = arr.filter((ele) => ele.id == id);
    let index = arr.indexOf(element[0]);

    element[0].state = !element[0].state;
    arr[index] = element[0];
    if (index == 0 && arr[0].state) {
      arr.map((ele) => (ele.id > 0 ? (ele.state = false) : null));
    } else {
      arr.map((ele) => (ele.id == 0 ? (ele.state = false) : null));
    }
    setCateg(arr);
  };
  const checkhandler2 = (id) => {
    let element = Actions.filter((ele) => ele.id == id);
    if (!element[0].state) {
      return false;
    }
    return true;
  };
  const handleAction = (id) => {
    let arr = [...Actions];
    let element = arr.filter((ele) => ele.id == id);
    let index = arr.indexOf(element[0]);
    element[0].state = !element[0].state;
    arr[index] = element[0];
    setActions(arr);
  };
  const getUsers = () => {
    axios
      .get(`/Security/users`)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  React.useEffect(() => {
    getUsers();
  }, []);
  const mainDataHandler = async () => {
    try {
      const res = await axios.post(`/History/Trucks/MainData`, {
        recorderId: user.userId,
        from: parsedFrom,
        to: parsedTo,
        added: Actions[0].state,
        updated: Actions[1].state,
        deleted: Actions[2].state,
      });
      setMainData(res.data);
    } catch (err) {
      Alerto(err);
    }
  };
  const commisionHandler = async () => {
    try {
      const res = await axios.post(`History/Trucks/CommisionItems`, {
        recorderId: user.userId,
        from: parsedFrom,
        to: parsedTo,
        added: Actions[0].state,
        updated: Actions[1].state,
        deleted: Actions[2].state,
      });
      setCommision(res.data);
    } catch (err) {
      Alerto(err);
    }
  };
  const buingHandler = async () => {
    try {
      const res = await axios.post(`History/Trucks/BuyingItems`, {
        recorderId: user.userId,
        from: parsedFrom,
        to: parsedTo,
        added: Actions[0].state,
        updated: Actions[1].state,
        deleted: Actions[2].state,
      });
      setBuying(res.data);
    } catch (err) {
      Alerto(err);
    }
  };
  const sellingHandler = async () => {
    try {
      const res = await axios.post(`History/Trucks/Sellings`, {
        recorderId: user.userId,
        from: parsedFrom,
        to: parsedTo,
        added: Actions[0].state,
        updated: Actions[1].state,
        deleted: Actions[2].state,
      });
      setSelling(res.data);
    } catch (err) {
      Alerto(err);
    }
  };
  const discountsHandler = async () => {
    try {
      const res = await axios.post(`History/Trucks/Discounts`, {
        recorderId: user.userId,
        from: parsedFrom,
        to: parsedTo,
        added: Actions[0].state,
        updated: Actions[1].state,
        deleted: Actions[2].state,
      });
      setDiscount(res.data);
    } catch (err) {
      Alerto(err);
    }
  };
  const invoiceHandler = async () => {
    try {
      const res = await axios.post(`History/Trucks/InvoiceItems`, {
        recorderId: user.userId,
        from: parsedFrom,
        to: parsedTo,
        added: Actions[0].state,
        updated: Actions[1].state,
        deleted: Actions[2].state,
      });
      setInvoice(res.data);
    } catch (err) {
      Alerto(err);
    }
  };
  const expenseHandler = async () => {
    try {
      const res = await axios.post(`History/Trucks/ExpensesAndDamages`, {
        recorderId: user.userId,
        from: parsedFrom,
        to: parsedTo,
        added: Actions[0].state,
        updated: Actions[1].state,
        deleted: Actions[2].state,
      });
      setExpenses(res.data);
    } catch (err) {
      Alerto(err);
    }
  };
  const ResetAll = () => {
    setMainData([]);
    setCommision([]);
    setInvoice([]);
    setBuying([]);
    setSelling([]);
    setExpenses([]);
    setDiscount([]);
  };
  const timeRef = useRef();
  React.useEffect(() => {
    clearTimeout(timeRef.current);
    timeRef.current = setTimeout(() => {
      ResetAll();
      if (categ[0].state) {
        mainDataHandler();
        invoiceHandler();
        sellingHandler();
        expenseHandler();
        invoiceHandler();
        discountsHandler();
        buingHandler();
        commisionHandler();
        return;
      }
      if (categ[1].state) {
        mainDataHandler();
      }
      if (categ[2].state) {
        commisionHandler();
      }
      if (categ[3].state) {
        buingHandler();
      }
      if (categ[4].state) {
        sellingHandler();
      }
      if (categ[5].state) {
        discountsHandler();
      }
      if (categ[6].state) {
        invoiceHandler();
      }
      if (categ[7].state) {
        expenseHandler();
      }
    }, 1000);
    return () => clearTimeout(timeRef.current);
  }, [categ, Actions, user, fromTime, toTime]);
  const classes = useStyles();
  const manageFromDate = (d) => {
    setFromTime(d);
    setParsedFrom(Mydate(d));
  };
  const manageToDate = (d) => {
    setToTime(d);
    setParsedTo(Mydate(d));
  };

  return (
    <div className={classes.root}>
      <div className="my-2">
        <MainHeader title="سجل النقلات والفواتير والمبيعات" />
      </div>
      <CardContent className="headerSearch m-0 px-3 ">
        <Grid container spacing={2}>
          <FromToDateHandler
            toTime={toTime}
            fromTime={fromTime}
            handleFrom={manageFromDate}
            handleTo={manageToDate}
          />
          <Grid className="col-12 col-md-5 col-lg  my-1 mx-1   m-auto">
            <Autocomplete
              size="small"
              className="w-100 m-auto"
              options={users}
              getOptionSelected={(option, value) => {
                return option.value === value?.value;
              }}
              getOptionLabel={(option) =>
                option.partnerName ? option.partnerName : ""
              }
              autoHighlight
              autoComplete
              noOptionsText="لم يتم العثور على خيارات"
              onChange={(event, value) => {
                if (value) {
                  setUser(value);
                } else {
                  setUser({});
                }
              }}
              value={user}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="w-100 m-auto"
                  label="بحث بأسم المستخدم"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid className="col-12 col-md-5 col-lg my-1 mx-1  m-auto">
            <TextField
              placeholder="بحث بأسم العميل"
              className="w-100 m-auto"
              size="small"
              value={Namesearch}
              onChange={(e) => setNameSearch(e.target.value)}
            />
          </Grid>
          <Grid className="col-12 col-md-5 col-lg my-1 mx-1  m-auto">
            <TextField
              placeholder="بحث بأسم البائع"
              className="w-100 m-auto"
              size="small"
              value={buyerSearch}
              onChange={(e) => setBuyerSearch(e.target.value)}
            />
          </Grid>
          <Grid className={classes.radio}>
            <FormControl
              component="fieldset"
              style={{ marginBottom: 4, display: "block" }}
            >
              <RadioGroup
                row
                onClick={(event) => {
                  handleCatg(event.target.value);
                }}
              >
                {categories.map((ele) => (
                  <FormControlLabel
                    value={ele.id}
                    control={
                      <Radio
                        style={{
                          color: "#fbc02d",
                        }}
                        checked={checkhandler(ele.id)}
                      />
                    }
                    label={ele.label}
                  />
                ))}
              </RadioGroup>
              <RadioGroup
                row
                onClick={(event) => {
                  handleAction(event.target.value);
                }}
              >
                {actions.map((ele) => (
                  <FormControlLabel
                    value={ele.id}
                    control={
                      <Radio
                        style={{
                          color: "#fbc02d",
                        }}
                        checked={checkhandler2(ele.id)}
                      />
                    }
                    label={ele.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      {mainData[0] && (
        <TruckHistoryTable
          search={Namesearch}
          buyer={buyerSearch}
          data={mainData}
        />
      )}
      {commision[0] && (
        <CommisonTable
          search={Namesearch}
          buyer={buyerSearch}
          data={commision}
        />
      )}
      {buying[0] && (
        <BuyingTable search={Namesearch} buyer={buyerSearch} data={buying} />
      )}
      {selling[0] && (
        <SellingsTable buyer={buyerSearch} search={Namesearch} data={selling} />
      )}
      {discount[0] && (
        <DiscountsTable
          search={Namesearch}
          buyer={buyerSearch}
          data={discount}
        />
      )}
      {invoice[0] && (
        <InvoiceItemTable
          search={Namesearch}
          buyer={buyerSearch}
          data={invoice}
        />
      )}
      {expenses[0] && (
        <RExpensesTable
          search={Namesearch}
          buyer={buyerSearch}
          data={expenses}
        />
      )}
    </div>
  );
}

export default TrucksHistory;
