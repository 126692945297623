import {
  Box,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Autocomplete from "@material-ui/lab/Autocomplete";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import DatePicker from "react-datepicker";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import TrucksTable from "./components/TrucksTable";
import ar from "date-fns/locale/ar-EG";
import axios from "axios";
import { fetchClients } from "../main-data/dataSlice";
import { getDateDashed } from "../../utils";
import { makeStyles } from "@material-ui/core/styles";
import { registerLocale } from "react-datepicker";
import Mydate from "../../components/Helpers/MyDateParser";
import { Col, Container, Row } from "react-bootstrap";
import MainHeader from "../../components/mainHeader/MainHeader";

registerLocale("ar", ar);
const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
  },
  toolbar: {
    marginBottom: 24,
  },

  title: {
    fontSize: 14,
  },
});

export default function CompletedTrucks() {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [truckDate, setTruckDate] = React.useState("");
  const [client, setClient] = useState("");
  const [truckType, setTruckType] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [trucks, setTrucks] = useState([]);
  const [renderNext, setRenderNext] = useState(true);
  const [hasInvoice, setHasInvoice] = useState("");
  const clients = useSelector((state) => state.data.clients);
  const dispatch = useDispatch();

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(fetchClients());
    }
    return () => {
      mounted = false;
    };
  }, []);

  const loadTypes = [
    { id: 1, name: "عمولة" },
    { id: 2, name: "مشتريات" },
  ];
  const invoiceStatus = [
    { status: 3, label: "الكل" },
    { status: 1, label: "فاتورة" },
    { status: 2, label: "بدون فاتورة" },
  ];

  const onLoadTypeChanged = (e) => {
    setTruckType(e.target.value);
  };
  const getReports = () => {
    setLoading(true);
    axios
      .post(`/Truck/Report?pageNumber=${pageNumber}&pageSize=15`, {
        at: !!truckDate ? Mydate(truckDate) : null,
        partnerId: !!client.id ? client.id : null,
        truckTypeId: !!truckType ? truckType : null,
        completedOrOverFlow: true,
        hasInvoice: hasInvoice === 1 ? true : hasInvoice === 2 ? false : null,
      })
      .then((res) => {
        setLoading(false);
        setTrucks(res.data);
        if (res.data.length < 15) {
          setRenderNext(false);
        } else {
          setRenderNext(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response && pageNumber !== 1) {
          setRenderNext(false);
        }
      });
  };

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      getReports();
    }
    return () => {
      mounted = false;
    };
  }, [pageNumber, truckDate, truckType, client, hasInvoice]);
  return (
    <>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <CircularProgress style={{ color: "#F06563" }} size={80} />{" "}
        </div>
      ) : (
        <div
          className=" w-100 p-0 py-4 pb-5 margin15px"
          style={{
            background: "#534d3b",
            marginTop: " -15px  !important",
            outline: " 8px solid #534d3b",
            minHeight: "109vh ",
          }}
        >
          <Container>
            <MainHeader title="النقلات المنتهية" />
            <Card
              style={{
                marginBottom: 16,
                boxShadow:
                  " rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px ",
              }}
              className="mt-3 center"
              variant="outlined"
            >
              <CardContent className=" center w-100 p-0 pt-3 pb-2">
                <Row className="col-12 center">
                  <Col item xs={10} md={3} className=" text-center mb-2">
                    <div className="w-100">
                      <DatePicker
                        locale="ar"
                        selected={truckDate}
                        onChange={(date) => setTruckDate(date)}
                        dateFormat="dd/MM/yyyy"
                        style={{ width: "100%" }}
                        customInput={
                          <TextField
                            size="small"
                            variant="outlined"
                            label="التاريخ"
                            className="w-100"
                          />
                        }
                      />
                    </div>
                  </Col>
                  <Col item xs={10} md={3} className=" text-center mb-2">
                    <Autocomplete
                      options={clients}
                      getOptionSelected={(option, value) =>
                        option.value === value?.value
                      }
                      getOptionLabel={(option) =>
                        option.name ? option.name : ""
                      }
                      style={{ width: "100%" }}
                      autoHighlight
                      autoComplete
                      noOptionsText="لم يتم العثور على خيارات"
                      onChange={(event, value) => {
                        if (value) {
                          setClient(value);
                        } else {
                          setClient("");
                        }
                      }}
                      value={client}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="اسم العميل"
                          size="small"
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                        />
                      )}
                    />
                  </Col>
                  <Col item xs={10} md={3} className=" text-center mb-2">
                    <TextField
                      select
                      label="نوع النقلة"
                      style={{ width: "100%" }}
                      size="small"
                      onChange={(e) => setTruckType(e.target.value)}
                      value={truckType}
                      variant="outlined"
                    >
                      {loadTypes.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Col>
                  <Col item xs={10} md={3} className=" text-center mb-2">
                    <TextField
                      select
                      label="الفاتورة"
                      style={{ width: "100%" }}
                      size="small"
                      onChange={(e) => setHasInvoice(e.target.value)}
                      value={hasInvoice}
                      variant="outlined"
                    >
                      {invoiceStatus.map((option) => (
                        <MenuItem key={option.label} value={option.status}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Col>
                </Row>
              </CardContent>
            </Card>
            {trucks.length > 0 ? (
              <TrucksTable
                trucks={trucks}
                truckDate={truckDate}
                client={client}
                truckType={truckType}
                completed={true}
              />
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" color="textSecondary">
                  {" "}
                  ﻻ يوجد نقلات{" "}
                </Typography>
              </div>
            )}
            <div style={{ display: "flex", justifyContent: "center" }}>
              {trucks.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: 140,
                    padding: 12,
                  }}
                >
                  <IconButton
                    disabled={!renderNext}
                    onClick={() => {
                      setPageNumber((num) => num + 1);
                    }}
                  >
                    <NavigateNextIcon />
                  </IconButton>
                  <IconButton
                    disabled={pageNumber === 1}
                    onClick={() => {
                      setPageNumber((num) => (num > 0 ? num - 1 : 1));
                    }}
                  >
                    <NavigateBeforeIcon />
                  </IconButton>
                </Box>
              )}
            </div>
          </Container>
        </div>
      )}
      ;
    </>
  );
}
