import { Button, Paper } from "@material-ui/core";
import { ConvertToArabicNumbers, renderDate } from "../../../utils";
import { Link, useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/styles";
import DetailsIcon from "@material-ui/icons/Details";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { parseISO } from "date-fns";
import LoadDetails from "../../movement-tools/components/LoadDetails";
import { useState } from "react";
import axios from "axios";
import Alerto from "../../../components/UI/toaster";
import SummarizeModal from "./SummarizeModal";
import { EnhancedTableHead, getComparator, stableSort } from "../../../components/Tables/EnhancedTableHead";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));
const tableHeader = [
  [
    {
      id: "truckDate",
      label: "تاريخ النقلة",
    },
    {
      id: "farmerName",
      label: "اسم العميل",
    },
    {
      id: "truckIdForThisDayAndClient",
      label: "رقم النقلة",
    },
    {
      id: "truckType",
      label: "نوع النقلة",
    },
    {
      id: "itemsCount",
      label: "عدد الاصناف",
    },
    {
      id: "innerItemsCount",
      label: "العدد الاساسي",
    },
    {
      label: "العدد المباع",
    },
    {
      id: "overFlow",
      label: "الزيادة",
    },
    {
      label: "فاتورة",
    },
    {
      label: "تفاصيل",
    },
    {
      label: "بيان",
    },
    {
      label: "ترحيل",
    },
    {
      label: "الفاتورة",
    },
  ],
  [
    {
      id: "truckDate",
      label: "تاريخ النقلة",
    },
    {
      id: "farmerName",
      label: "اسم العميل",
    },
    {
      id: "truckIdForThisDayAndClient",
      label: "رقم النقلة",
    },
    {
      id: "truckType",
      label: "نوع النقلة",
    },
    {
      id: "itemsCount",
      label: "عدد الاصناف",
    },
    {
      id: "innerItemsCount",
      label: "العدد الاساسي",
    },

    {
      label: "العدد المباع",
    },
    {
      id: "remaining",
      label: "العدد المتبقي",
    },
    {
      label: "تفاصيل",
    },
    {
      label: "بيان",
    },
    {
      label: "ترحيل",
    },
    {
      label: "الفاتورة",
    },
  ],
];

export default function TrucksTable({ trucks, completed }) {
  const history = useHistory();
  const [openDetails, setOpenDetails] = useState(false);
  const [truck, setTruck] = useState(false);
  const [details, setDetails] = useState([]);
  const [openSummarize, setOpenSummarize] = useState(false);
  const [orderBy, setOrderBy] = React.useState("truckDate");
  const [order, setOrder] = React.useState("asc");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "desc";
    setOrder(isAsc ? "asc" : "desc");
    setOrderBy(property);
  };
  const onDetailsClicked = (truck) => {
    setOpenDetails(true);
    setTruck(truck);
    axios
      .get(
        `Truck/${truck.truckTypeId === 1 ? "CommisionItems" : "BuyingItems"}/${truck.truckId
        }`
      )
      .then((res) => {
        setDetails(res.data);
      })
      .catch((e) => Alerto(e));
  };
  const classes = useStyles();
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
    },
    body: {
      fontSize: 16,
      overflow: "auto",
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        padding: 0,
      },
    },
  }))(TableCell);
  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);


  return (
    <TableContainer component={Paper}>
      <Table>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          condition={completed}
          cells={tableHeader}
          dual={true}
        />
        <TableBody>
          {trucks.length > 0 &&
            stableSort(trucks, getComparator(order, orderBy)).map(
              (truck, index) => {
                return (
                  <StyledTableRow
                    key={truck.truckId}
                    className={classes.tableBody}
                  >
                    <StyledTableCell align="center" component="th" scope="row">
                      {ConvertToArabicNumbers(
                        renderDate(truck.truckDate)
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {truck.farmerName}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {ConvertToArabicNumbers(truck.truckIdForThisDayAndClient)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {truck.truckType}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {truck.itemsCount === 0
                        ? "ﻻ يوجد"
                        : ConvertToArabicNumbers(truck.itemsCount)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {truck.innerItemsCount === 0
                        ? "ﻻ يوجد"
                        : ConvertToArabicNumbers(truck.innerItemsCount)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {truck.innerItemsCount === truck.remaining
                        ? "ﻻ يوجد"
                        : ConvertToArabicNumbers(
                          truck.innerItemsCount -
                          truck.remaining +
                          truck.overFlow
                        )}
                    </StyledTableCell>
                    {!completed && (
                      <StyledTableCell align="center">
                        {truck.remaining === 0
                          ? "ﻻ يوجد"
                          : ConvertToArabicNumbers(truck.remaining)}
                      </StyledTableCell>
                    )}
                    {completed && (
                      <>
                        <StyledTableCell align="center">
                          {truck.overFlow === 0
                            ? "ﻻ يوجد"
                            : ConvertToArabicNumbers(truck.overFlow)}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {truck.hasInvoice ? "نعم" : "لا"}
                        </StyledTableCell>
                      </>
                    )}

                    <TableCell align="center">
                      <InfoIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => onDetailsClicked(truck)}
                        className='center'
                      />
                    </TableCell>
                    <TableCell align="center">
                      <InsertDriveFileOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setTruck(truck);
                          setOpenSummarize(true);
                        }}
                      />
                    </TableCell>


                    <TableCell component={Link} className='btnMore'
                      to={`/loads/${truck.truckId}`} align="center" style={{ width: "32px" }}>

                      ترحيل
                    </TableCell>
                    <TableCell component={Link}
                      to={`/invoice/${truck.truckId}`} className='btnMore' align="center" style={{ width: "32px" }}>
                      فاتورة
                    </TableCell>
                  </StyledTableRow>
                );
              }
            )}
        </TableBody>
      </Table>
      <LoadDetails
        truck={truck}
        rows={details}
        open={openDetails}
        onClose={() => setOpenDetails(false)}
      />
      {truck && (
        <SummarizeModal
          open={openSummarize}
          setOpen={setOpenSummarize}
          truck={truck}
        />
      )}
    </TableContainer>
  );
}
