import { updateDeductions, addDeductions, getDeductions } from "../loadsSlice";
import { useDispatch, useSelector } from "react-redux";
import { 
  IconButton, 
  TableRow, 
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Paper,
  Button
} from "@material-ui/core";
import { styled } from '@material-ui/core/styles';
import { ConvertToArabicNumbers } from "../../../utils";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Deductions from "../components/Deductions";
import React, { useState } from "react";
import DeleteBox from "../../../components/DeleteBox";
import axios from "axios";
import Alerto from "../../../components/UI/toaster";

const TableContainer = styled(Paper)({
  margin: '10px 0',
  overflowX: 'auto',
  color:"yellow",
  boxShadow: '0 0 10px rgba(0,0,0,0.1)'
});

const CompactTable = styled(Table)({
  minWidth: 650,
});

const CompactCell = styled(TableCell)({
  padding: '6px 15px',
  textAlign: 'center',
  fontSize: '14px',
  '&:first-of-type': {
    paddingRight: '8px'
  },
  '&:last-of-type': {
    paddingLeft: '8px'
  }
});

const HeadCell = styled(CompactCell)({
  backgroundColor: 'rgb(255, 204, 49)',
  fontWeight: 'bold',
  color: 'rgba(0, 0, 0, 0.87)'
});

const ButtonsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
  marginTop: '10px'
});

function AddDedeuctions({ closeDialog }) {
  const deduction = useSelector((state) => state.loads.deduction);
  const truck = useSelector((state) => state.loads.load?.data);
  const [deductionToDelete, setDeductionToDelete] = useState(null);
  const [deductionToEdit, setDeductionToEdit] = useState(null);
  const [openDeleteDeduction, setOpendeleteDeduction] = useState(false);
  
  const tableHeader = [
    "عميل مشترك",
    "عمولة",
    "نسبة العمولة",
    "مشال",
    "مكتب",
    "أيجار عدة",
    "تلاجة",
    "تعديل",
    "حذف",
  ];
  !truck?.sharedTruck && tableHeader.splice(0, 1);
  
  const dispatch = useDispatch();
  
  const showAddForm = !deduction?.length || 
                     deduction?.length > 1 || 
                     truck?.sharedTruck || 
                     deductionToEdit !== null;

  const onAddDeductions = ({
    independentCommision,
    commisionPercentage,
    carryingPrice,
    officeTips,
    movementToolRent,
    fridgeRent,
    sharedPartnerId,
  }) => {
    dispatch(
      addDeductions({
        sharedPartnerId,
        independentCommision,
        commisionPercentage,
        carryingPrice,
        officeTips,
        movementToolRent,
        fridgeRent,
      })
    );
  };

  const onUpdate = (data) => {
    dispatch(updateDeductions(data));
    setDeductionToEdit(null);
  };

  // إضافة وظيفة الإلغاء
  const handleCancel = () => {
    setDeductionToEdit(null);
  };

  async function onDeleteDeduction() {
    try {
      await axios.delete(`discounts?discountid=${deductionToDelete.id}`);
      setOpendeleteDeduction(false);
      dispatch(getDeductions(truck?.id));
    } catch (error) {
      Alerto(error);
    }
  }

  return (
    <div>
      {(showAddForm || deductionToEdit) && (
        <>
          <Deductions
            deduction={deductionToEdit}
            onAddDeductionsClicked={onAddDeductions}
            onUpdateClicked={onUpdate}
          />
          {deductionToEdit && (
            <ButtonsContainer>
              <Button
                variant="contained"
                fullWidth

                onClick={handleCancel}
              >
                إلغاء
              </Button>
            </ButtonsContainer>
          )}
        </>
      )}
      {deduction?.length > 0 && (
        <TableContainer>
          <CompactTable>
            <TableHead>
              <TableRow>
                {tableHeader.map((header, index) => (
                  <HeadCell key={index}>{header}</HeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {deduction.map((item, index) => (
                <TableRow key={index}>
                  {!!truck?.sharedTruck && (
                    <CompactCell>
                      <Typography variant="body1" color="textPrimary">
                        {item.sharedPartner?.name}
                      </Typography>
                    </CompactCell>
                  )}
                  <CompactCell>
                    <Typography variant="body1" color="textPrimary">
                      {ConvertToArabicNumbers(item.independentCommision)}
                    </Typography>
                  </CompactCell>
                  <CompactCell>
                    <Typography variant="body1" color="textPrimary">
                      {ConvertToArabicNumbers(item.commisionPercentage)}
                    </Typography>
                  </CompactCell>
                  <CompactCell>
                    <Typography variant="body1" color="textPrimary">
                      {ConvertToArabicNumbers(item.carryingPrice)}
                    </Typography>
                  </CompactCell>
                  <CompactCell>
                    <Typography variant="body1" color="textPrimary">
                      {ConvertToArabicNumbers(item.officeTips)}
                    </Typography>
                  </CompactCell>
                  <CompactCell>
                    <Typography variant="body1" color="textPrimary">
                      {ConvertToArabicNumbers(item.movementToolRent)}
                    </Typography>
                  </CompactCell>
                  <CompactCell>
                    <Typography variant="body1" color="textPrimary">
                      {ConvertToArabicNumbers(item.fridgeRent)}
                    </Typography>
                  </CompactCell>
                  <CompactCell>
                    <IconButton onClick={() => setDeductionToEdit(item)}>
                      <EditOutlinedIcon />
                    </IconButton>
                  </CompactCell>
                  <CompactCell>
                    <IconButton
                      onClick={() => {
                        setOpendeleteDeduction(true);
                        setDeductionToDelete(item);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </CompactCell>
                </TableRow>
              ))}
            </TableBody>
          </CompactTable>
        </TableContainer>
      )}
      {!!deductionToDelete && (
        <DeleteBox
          action={onDeleteDeduction}
          open={openDeleteDeduction}
          onClose={() => setOpendeleteDeduction(false)}
          message={"حذف خصومات العميل"}
        />
      )}
    </div>
  );
}

export default AddDedeuctions;