import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { addBuyer, fetchBuyers } from "../../main-data/dataSlice";
import { indexOfId } from "../../../utils";
import {
  getSoldItems,
  productSelected,
  sellCommisionItem,
} from "../loadsSlice";
import { useDispatch, useSelector } from "react-redux";

import AddBuyer from "./AddBuyer";
import Autocomplete from "@material-ui/lab/Autocomplete";
import BalanceNotification from "./BalanceNotification";
import DatePicker from "react-datepicker";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import axios from "axios";
import { toast } from "react-toastify";
import Mydate from "../../../components/Helpers/MyDateParser";
import CustomInput from "../../treasury/components/CustomInput";
import { InputSorting } from "../../../components/Helpers/InsputSorting";
import { min } from "date-fns";
import Divider from "@mui/material/Divider";

const SellCommissionForm = (props) => {
  const buyers = useSelector((state) => state.data.buyers);
  const items = useSelector((state) => state.loads.commissionItems.items);
  const tools = useSelector((state) => state.data.tools);
  const [partner, setPartner] = useState("");
  const [partnerName, setPartnerName] = useState("");
  const [productsCount, setProductsCount] = useState(0);
  const [movementTool, setMovementTool] = useState("");
  const [weight, setWeight] = useState(0);
  const [priceForUnitWeight, setPriceForUnitWeight] = useState(0);
  const [commision, setCommision] = useState(0);
  const [dateTime, setDateTime] = useState(new Date());

  const dispatch = useDispatch();
  const selectedProduct = useSelector((state) => state.loads.selectedProduct);
  const soldItems = useSelector((state) => state.loads.soldItems);
  const getProducts = (arr) => {
    let products = [];
    for (let i = 0; i < arr.length; i++) {
      products.push({
        id: arr[i].id,
        product: {
          name: arr[i].product?.name,
          id: arr[i].product?.id,
        },
        mostCommision: arr[i].mostCommision,
        mostSellingPrice: arr[i].mostSellingPrice,
      });
    }
    return products;
  };

  const [truckItem, setTruckItem] = useState("");

  useEffect(() => {
    if (selectedProduct && selectedProduct.id) {
      setTruckItem(selectedProduct);
      setPriceForUnitWeight(selectedProduct.mostSellingPrice);
      setCommision(selectedProduct.mostCommision);
      setMovementTool(selectedProduct.mostMovementTool);
    }
  }, [selectedProduct]);

  useEffect(() => {
    const data = sessionStorage.getItem("buyerName");
    if (data) {
      setPartnerName(JSON.parse(data));
    }
  }, []);
  useEffect(() => {
    sessionStorage.setItem("buyerName", JSON.stringify(partnerName));
  }, [partnerName]);
  const inputRef = React.useRef(null);
  const [openAddBuyer, setOpenAddBuyer] = useState(false);

  const addNewBuyer = ({
    partnerName,
    balance,
    openbalance,
    paymenttype,
    collectingAddress,
  }) => {
    setBalanceAlert(balance);
    setDebt(0);
    dispatch(
      addBuyer({
        Name: partnerName,
        BalanceAlert: balance,
        PayementTypeId: paymenttype,
        OpeningBalance: openbalance,
        collectingAddress,
      })
    ).then(({ payload }) => {
      if (payload && payload.id) {
        setPartner(payload);
      }
    });
  };

  const submitNewBuyer = () => {
    let isExist = false;
    let arr = Array.from(partnerName.trim());
    let valid = arr.filter((ele, index) => {
      return ele.trim() != "" || arr[index + 1].trim() != "";
    });
    let str = valid.join("");
    setPartnerName(str);
    buyers.filter((partner) => {
      if (partner.name === str) {
        return (isExist = true);
      }
    });
    if (!isExist && partnerName.length > 0) {
      setOpenAddBuyer(true);
    }
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(fetchBuyers());
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted && inputRef.current) {
      inputRef.current.focus();
    }
    return () => {
      mounted = false;
      setPartnerName("");
    };
  }, [props.focustBuyer]);

  const [alertSnack, setAlertSnack] = useState(false);
  const [isSuppling, setIsSuppling] = useState(false);
  const role = localStorage.getItem("role");
  const [balanceAlert, setBalanceAlert] = useState(0);
  const [debt, setDebt] = useState(0);
  const [warningBalance, setWarningBalance] = useState(false);
  const onSellClicked = () => {
    if (
      debt + (priceForUnitWeight * weight + productsCount * commision) >
        balanceAlert &&
      partner?.payementTypeId === 1
    ) {
      setWarningBalance(true);
    } else if (
      Boolean(partner.id) &&
      Boolean(truckItem.id) &&
      priceForUnitWeight >= 0 &&
      weight > 0 &&
      productsCount >= 0 &&
      commision >= 0
    ) {
      const seller = soldItems.find((soldItem) => {
        return soldItem.partner.id === partner.id;
      });
      if (seller && seller.invoiceTypeId === 3 && !isSuppling) {
        toast.warning("ﻻ يجب ان يأخذ البائع اكثر من نوع مبيع في نفس النقلة!");
        return;
      }

      if (seller && seller.invoiceTypeId !== 3 && isSuppling) {
        toast.warning("ﻻ يجب ان يأخذ البائع اكثر من نوع مبيع في نفس النقلة!");
        return;
      }
      setPartner("");
      dispatch(
        sellCommisionItem({
          partner,
          truckItem,
          movementTool,
          priceForUnitWeight,
          weight,
          productsCount,
          commision,
          invoiceTypeId: isSuppling ? 3 : partner.payementTypeId,
          dateTime: Mydate(dateTime),
        })
      ).then(() => {
        dispatch(getSoldItems({}));
        setWeight(0);
        setProductsCount(0);
        setPartnerName("");
        inputRef.current.focus();
        setIsSuppling(false);
      });
    } else {
      setAlertSnack(true);
    }
  };
  const timeerRef = useRef();
  function handleFire() {
    clearTimeout(timeerRef.current);
    timeerRef.current = setTimeout(() => {
      onSellClicked();
    }, 500);
  }
  //===========================alert=============================

  const getParnterDebtWithAlert = (id) => {
    axios
      .get(`/Partners/DebtWithAlert/${id}`)
      .then((res) => {
        setDebt(res.data.debt);
        setBalanceAlert(res.data.balanceAlert);
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (
        partner.id &&
        partner.payementTypeId === 1 &&
        productsCount &&
        weight &&
        priceForUnitWeight &&
        commision &&
        truckItem.id
      ) {
        const total = priceForUnitWeight * weight + productsCount * commision;
        if (total + debt > balanceAlert) {
          setWarningBalance(true);
        }
      }
    }
    return () => {
      mounted = false;
    };
  }, [
    partner.id,
    productsCount,
    weight,
    priceForUnitWeight,
    commision,
    truckItem.id,
    balanceAlert,
    debt,
  ]);

  const updateBalanceAlert = (addedBalance) => {
    axios
      .put(
        `/Partners/UpdateBalanceAlert?partnerId=${partner.id}&balanceAlert=${addedBalance}`
      )
      .then((response) => {
        if (response.status === 200) {
          toast.info("تم تغيير قيمة تنبيه الرصيد");
          setBalanceAlert(addedBalance);
        }
      })
      .catch((error) => {
        toast.error(error.response.data);
      });
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const NumHandler = (num) => {
    let val = Number.parseFloat(num);
    if (val && Number.isInteger(val)) {
      setProductsCount(val);
    } else if (!val) {
      setProductsCount(NaN);
    } else {
      toast.error("لا يمكن كتابه رقم عشري كعدد");
      setProductsCount(NaN);
    }
  };
  return (
    items.length > 0 && (
      <div
        className=" col-11 p-0  m-auto  mb-3 "
        style={{ background: "#534d3b" }}
      >
        <Box mt={1} className="headerSearch p-0  m-auto center">
          <div>
            <CardContent>
              <form noValidate autoComplete="off" className="center">
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  className="center"
                >
                  <Grid item xs={12} md={3} lg={3}>
                    <Autocomplete
                      options={buyers}
                      getOptionSelected={(option, value) =>
                        option.value === value?.value
                      }
                      getOptionLabel={(option) =>
                        option.name ? option.name : ""
                      }
                      filterOptions={(options, state) =>
                        InputSorting(options, state.inputValue)
                      }
                      autoHighlight
                      noOptionsText="لم يتم العثور على خيارات"
                      onChange={(e, value) => {
                        if (value) {
                          setPartner(value);
                          getParnterDebtWithAlert(value.id);
                        } else {
                          setPartner("");
                        }
                      }}
                      value={partner}
                      onInputChange={(e, v) => {
                        if (e) {
                          if (e.type !== "blur") {
                            setPartnerName(v);
                          }
                        }
                      }}
                      size="small"
                      inputValue={partnerName}
                      renderInput={(params) => (
                        <TextField
                          autoFocus
                          onBlur={submitNewBuyer}
                          inputRef={inputRef}
                          {...params}
                          label="اسم البائع"
                          variant="outlined"
                        />
                      )}
                    />
                    {/* <CustomInput
                      options={buyers}
                      inputValue={partnerName}
                      onBlur={submitNewBuyer}
                      value={partner}
                      setClient={handlePartner}
                      setClientName={setPartnerName}
                      ref2={inputRef}
                      width="10rem"
                    /> */}
                  </Grid>
                  <Grid item xs={6} md={3} lg={2}>
                    <TextField
                      size="small"
                      label="العدد"
                      variant="outlined"
                      className="w-100"
                      type="number"
                      onChange={(e) => NumHandler(e.target.value)}
                      value={productsCount >= 0 ? productsCount : ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={3} lg={2}>
                    <TextField
                      size="small"
                      label="الوزن"
                      variant="outlined"
                      type="number"
                      className="w-100"
                      onChange={(e) =>
                        setWeight(Number.parseFloat(e.target.value))
                      }
                      value={weight >= 0 ? weight : ""}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          handleFire();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={3} lg={2}>
                    <TextField
                      size="small"
                      label="السعر"
                      id="price"
                      className="w-100"
                      variant="outlined"
                      type="number"
                      onChange={(e) =>
                        setPriceForUnitWeight(Number.parseFloat(e.target.value))
                      }
                      value={priceForUnitWeight >= 0 ? priceForUnitWeight : ""}
                    />
                  </Grid>
                  <Grid item xs={6} md={1} lg={1}>
                    <TextField
                      size="small"
                      label="بياعة"
                      className="w-100"
                      variant="outlined"
                      type="number"
                      onChange={(e) =>
                        setCommision(Number.parseFloat(e.target.value))
                      }
                      value={commision >= 0 ? commision : ""}
                    />
                  </Grid>
                  <Grid className="center" item xs={12} md={3} lg={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ width: 150, height: 48, padding: 25 }}
                      className=" m-auto my-3"
                      onClick={handleFire}
                    >
                      اتمام العملية
                    </Button>
                  </Grid>
                  <div
                    className="w-100 m-auto bg-dark mb-3 mt-0 px-5 rounded-3"
                    style={{ height: "0.5px" }}
                  ></div>
                  <Grid item xs={12} md={3} lg={2}>
                    {tools && (
                      <Autocomplete
                        options={tools}
                        getOptionSelected={(option, value) =>
                          option.value === value?.value
                        }
                        getOptionLabel={(option) =>
                          option.name ? option.name : ""
                        }
                        autoHighlight
                        freeSolo
                        onChange={(e, value) => {
                          setMovementTool(value);
                        }}
                        value={movementTool}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            autoComplete="off"
                            {...params}
                            label="العدة"
                            className="w-100"
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                              form: {
                                autoComplete: "off",
                              },
                            }}
                          />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={3} lg={2}>
                    {items.length > 0 && (
                      <Autocomplete
                        className="w-100"
                        freeSolo
                        options={
                          items.length > 0 &&
                          selectedProduct &&
                          selectedProduct.id
                            ? getProducts(items)
                            : items
                        }
                        getOptionSelected={(option, value) =>
                          option.value === value?.value
                        }
                        getOptionLabel={(option) => {
                          return option.product
                            ? option.product.name.toString()
                            : "";
                        }}
                        noOptionsText="ﻻ يوجد اصناف للترحيل"
                        onChange={(e, value) => {
                          if (value) {
                            const index = indexOfId(items, value.id);
                            dispatch(productSelected(items[index]));
                            setTruckItem(value);
                          }
                        }}
                        value={truckItem}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            autoComplete="off"
                            {...params}
                            label="اسم الصنف"
                            className="w-100"
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                              form: {
                                autoComplete: "off",
                              },
                            }}
                          />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={8} md={3} lg={2}>
    <DatePicker
                      locale="ar"
                      selected={dateTime}
                      onChange={(date) => setDateTime(date)}
                      dateFormat="dd/MM/yyyy"
          size="small"
                      disabled={role == "موظف"}
                      customInput={
                        <TextField
          className="w-100"
                          variant="outlined"
                          label="التاريخ"
                          size="small"
                        />
                      }
    />
                  </Grid>
                  {partner.payementTypeId !== 2 && (
                    <Grid item xs={4} md={3} lg={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isSuppling}
                            onChange={(event) =>
                              setIsSuppling(event.target.checked)
                            }
                          />
                        }
                        label="توريد"
                      />
                    </Grid>
                  )}
                </Grid>
              </form>
            </CardContent>
          </div>
        </Box>
        <Snackbar
          open={alertSnack}
          autoHideDuration={6000}
          onClose={() => setAlertSnack(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error">اكمل بيانات عملية البيع</Alert>
        </Snackbar>
        <AddBuyer
          open={openAddBuyer}
          onClose={() => setOpenAddBuyer(false)}
          name={partnerName}
          onAddBuyer={addNewBuyer}
        />
        <BalanceNotification
          open={warningBalance}
          onClose={() => {
            setWarningBalance(false);
          }}
          onUpdateBalance={updateBalanceAlert}
          debt={debt}
          balanceAlert={balanceAlert}
          total={priceForUnitWeight * weight + productsCount * commision}
        />
      </div>
    )
  );
};
export default SellCommissionForm;
