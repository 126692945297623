import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { fetchBuyers, fetchClients } from "../main-data/dataSlice";
import { searchImportingByDate, searchImportingByName } from "./importSlice";
import { useDispatch, useSelector } from "react-redux";

import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePicker from "react-datepicker";
import SearchTable from "./components/SearchTable";
import ar from "date-fns/locale/ar-EG";
import { makeStyles } from "@material-ui/core/styles";
import { registerLocale } from "react-datepicker";

registerLocale("ar", ar);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  form: {
    marginTop: 24,
  },
  title: {
    fontSize: 24,
    marginBottom: 16,
  },
  SearchForm: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    "& > *": {
      marginRight: 12,
    },
  },
}));

function Imports() {
  const [partner, setPartner] = useState("");
  const [dateTime, setDateTime] = useState(new Date());

  const imports = useSelector((state) => state.importing.imports);
  const dispatch = useDispatch();
  const clients = useSelector((state) => state.data.clients).filter(
    (client) => {
      return client.partners_TypeId !== 3;
    }
  );
  const buyers = useSelector((state) => state.data.buyers);

  const partners = [...buyers, ...clients];
  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(fetchClients()).then(() => {
        dispatch(fetchBuyers());
      });
    }

    return () => {
      mounted = false;
    };
  }, []);

  React.useEffect(() => {
    let mounted = true;
    if (mounted && partner.id) {
      dispatch(searchImportingByName(partner.id));
    }
    return () => {
      mounted = false;
    };
  }, [partner]);

  React.useEffect(() => {
    let mounted = true;
    if (mounted && dateTime) {
      dispatch(searchImportingByDate(dateTime));
    }
    return () => {
      mounted = false;
    };
  }, [dateTime]);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Card className={classes.form}>
        <CardHeader subheader="بحث بالاسم او التاريخ" title="وارد العدة" />
        <Divider />
        <CardContent>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <Autocomplete
                options={partners}
                getOptionSelected={(option, value) => {
                  return option.value === value?.value;
                }}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                style={{ width: 300 }}
                autoHighlight
                autoComplete
                noOptionsText="لم يتم العثور على خيارات"
                onChange={(event, value) => {
                  if (value) {
                    setPartner(value);
                  } else {
                    setPartner("");
                  }
                }}
                value={partner}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="الاسم"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <DatePicker
                locale="ar"
                selected={dateTime}
                onChange={(d) => {
                  setDateTime(d);
                }}
                dateFormat="dd/MM/yyyy"
                customInput={
                  <TextField
                    variant="outlined"
                    id="dateTime"
                    style={{ width: 260, backgroundColor: "#FFF" }}
                  />
                }
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {imports.length > 0 ? (
        <SearchTable items={imports} type="importing" />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70%",
          }}
        >
          <Typography variant="h6" color="textSecondary">
            ﻻ يوجد تحصيل
          </Typography>
        </div>
      )}
    </div>
  );
}

export default Imports;
