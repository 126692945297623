// Constants and Types
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Typography,
  Grid,
  Paper,
  CircularProgress,
  Box,
} from "@material-ui/core";
import {
  Business,
  Person,
  Phone,
  LocationCity,
  LocationOn,
  DateRange,
  Payment,
  AttachMoney,
  CalendarToday,
  Description,
  Timer,
  Schedule,
} from "@mui/icons-material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import MainHeader from "../src/components/mainHeader/MainHeader";
import { Container } from "react-bootstrap";
import CryptoJS from "crypto-js";
import PaymentMethods from "../src/PaymentMethods ";

const SECRET_KEY = "hjljfsdflkjdf$%#$%fgDFGDF4534*&^4654dfs0g5DFs65&fjg#twB";
const API_BASE_URL = "https://backowlsupport.owltecheg.com/api";
// const API_BASE_URL = "https://owls.owltecheg.net/api";

const PACKAGE_COLORS = {
  Bronze: "rgba(165, 42, 42, 0.3)",
  Silver: "rgba(192, 192, 192, 0.3)",
  Golden: "rgba(255, 217, 0, 0.3)",
  Platinum: "rgba(83, 195, 232, 0.3)",
};

// Utility Functions
const formatDate = (date) => {
  return date.toLocaleDateString("en-US", { day: "numeric" });
};

const calculateDays = {
  sinceStart: (startDate) => {
    if (!startDate) return null;
    const start = new Date(startDate);
    const cairoNow = new Date(new Date().toLocaleString("en-US", { timeZone: "Africa/Cairo" }));
    const diffTime = Math.abs(cairoNow - start);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  },

  remaining: (paymentDate) => {
    if (!paymentDate) return null;
    
    // Convert payment date to Cairo timezone
    const payment = new Date(paymentDate);
    const cairoPayment = new Date(payment.toLocaleString("en-US", { timeZone: "Africa/Cairo" }));
    
    // Set the payment date to exactly midnight
    cairoPayment.setHours(0, 0, 0, 0);
    
    // Get current time in Cairo
    const cairoNow = new Date(new Date().toLocaleString("en-US", { timeZone: "Africa/Cairo" }));
    
    // Calculate difference in days
    const diffTime = cairoPayment - cairoNow;
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }
};
const encryptName = (name) => {
  const encrypted = CryptoJS.AES.encrypt(name, SECRET_KEY).toString();
  return btoa(encrypted);
};

// Update the useTimeUpdate hook to use Cairo timezone
const useTimeUpdate = () => {
  const [timeLeft, setTimeLeft] = useState("");
  const [currentTimePercentage, setCurrentTimePercentage] = useState(0);

  useEffect(() => {
    const updateTime = () => {
      // Get current time in Cairo
      const cairoNow = new Date(new Date().toLocaleString("en-US", { timeZone: "Africa/Cairo" }));
      
      // Set next midnight in Cairo time
      const cairoMidnight = new Date(cairoNow);
      cairoMidnight.setHours(24, 0, 0, 0);

      const diff = cairoMidnight - cairoNow;
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      setTimeLeft(
        `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`
      );

      const totalSecondsInDay = 24 * 60 * 60;
      const currentSeconds =
        cairoNow.getHours() * 60 * 60 + cairoNow.getMinutes() * 60 + cairoNow.getSeconds();
      const percentage =
        ((totalSecondsInDay - currentSeconds) / totalSecondsInDay) * 100;
      setCurrentTimePercentage(percentage);
    };

    updateTime();
    const timer = setInterval(updateTime, 1000);
    return () => clearInterval(timer);
  }, []);

  return { timeLeft, currentTimePercentage };
};

const useSubscriptionData = () => {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const fetchData = async (encryptedName) => {
    try {
      setLoading(true);
      setError("");

      const response = await fetch(
        `${API_BASE_URL}/subscription/details/${encodeURIComponent(
          encryptedName
        )}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error fetching data! Status: ${response.status}`);
      }

      const data = await response.json();
      setSubscriptionData(data.infoData);
      setPaymentData(data.paymentData);
    } catch (e) {
      setError(e.message || "Error fetching data.");
    } finally {
      setLoading(false);
    }
  };

  return { subscriptionData, paymentData, loading, error, fetchData };
};

// Components
const LoadingSpinner = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="80vh"
  >
    <CircularProgress size={60} thickness={4} style={{ color: "#1976d2" }} />
  </Box>
);

const ErrorMessage = ({ message }) => (
  <Container style={{ padding: "20px" }}>
    <Paper
      style={{
        backgroundColor: "#ffebee",
        padding: "20px",
        borderRadius: "8px",
      }}
    >
      <Typography color="error">{message}</Typography>
    </Paper>
  </Container>
);

const SectionHeader = ({ icon: Icon, title }) => (
  <Typography
    variant="h5"
    style={{
      color: "#1976d2",
      fontWeight: "bold",
      marginBottom: "20px",
      borderBottom: "2px solid #1976d2",
      paddingBottom: "8px",
      display: "flex",
      alignItems: "center",
    }}
  >
    {Icon && <Icon style={{ marginRight: "10px" }} />}
    {title}
  </Typography>
);

const DetailCard = ({ Icon, label, value, remainingDays }) => {
  const isRenewalDateField = label === "تاريخ التجديد";
  const isRemainingDaysField = label === "الأيام المتبقية";
  const isPackageTypeField = label === "نوع الباقة";

  const getCardStyle = () => {
    let backgroundColor = "#ffffff";
    let borderColor = "none";
    let textColor = "#333333";

    if (isPackageTypeField) {
      backgroundColor = PACKAGE_COLORS[value] || "#ffffff";
    } else if (isRemainingDaysField || isRenewalDateField) {
      const daysLeft = parseInt(remainingDays);
      const isWithinTwoDays = daysLeft <= 2 && daysLeft >= 0;
      const isMoreThanTwoDays = daysLeft > 2;

      if (isWithinTwoDays) {
        backgroundColor = "#ffebee";
        borderColor = "1px solid rgb(166, 66, 65)";
        textColor = "#d32f2f";
      } else if (isMoreThanTwoDays) {
        backgroundColor = "#e8f5e9";
        borderColor = "1px solid #4caf50";
        textColor = "#2e7d32";
      }
    }

    return { backgroundColor, borderColor, textColor };
  };

  let displayValue = value;

  if (isRemainingDaysField) {
    const daysLeft = parseInt(value);

    if (daysLeft === 0) {
      displayValue = "فترة السماح";
    } else if (daysLeft < 0) {
      const remainingDays = Math.abs(daysLeft);
      displayValue = `إنتهاء من  ${remainingDays} يوم`;
    }
  }

  const { backgroundColor, borderColor, textColor } = getCardStyle();

  return (
    <Grid item xs={12} md={6} lg={3}>
      <Paper
        elevation={3}
        style={{
          padding: "20px",
          height: "100%",
          backgroundColor,
          borderRadius: "8px",
          transition: "transform 0.2s",
          border: borderColor,
        }}
      >
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: 600,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "#1976d2",
          }}
        >
          <span style={{ display: "flex", alignItems: "center" }}>
            {Icon && <Icon style={{ marginRight: "12px" }} />}
            {label}
          </span>
          <span style={{ color: textColor }}>
            {displayValue || "غير متوفر"}
          </span>
        </Typography>
      </Paper>
    </Grid>
  );
};

const SubscriptionSection = ({ subscriptionData }) => (
  <Paper elevation={2} style={{ padding: "24px", marginBottom: "24px" }}>
    <SectionHeader
      icon={Business}
      title={`اسم النظام: ${subscriptionData.Name}`}
    />
    <Grid
      container
      spacing={3}
      style={{ display: "flex", justifyContent: "center", textAlign: "center" }}
    >
      <DetailCard
        Icon={Person}
        label="اسم المالك"
        value={subscriptionData.NameOwner}
      />
      <DetailCard
        Icon={Phone}
        label="رقم الهاتف"
        value={subscriptionData.Phone}
      />
      <DetailCard
        Icon={LocationCity}
        label="المدينة"
        value={subscriptionData.City}
      />
      <DetailCard
        Icon={LocationOn}
        label="المحافظة"
        value={subscriptionData.Governorate}
      />
    </Grid>
  </Paper>
);

const PaymentSection = ({
  paymentData,
  remainingDays,
  timeLeft,
  currentTimePercentage,
  hoveredIndex,
  setHoveredIndex,
}) => (
  <Paper elevation={2} style={{ padding: "24px", marginBottom: "24px" }}>
    <SectionHeader icon={Payment} title="معلومات الدفع" />
    <Grid container spacing={4}>
      <DetailCard
        Icon={Payment}
        label="نوع الباقة"
        value={paymentData.PriceType}
        remainingDays={remainingDays}
      />
      <DetailCard
        Icon={AttachMoney}
        label="سعر الباقة"
        value={paymentData.Price ? `${paymentData.Price} ج.م` : null}
        remainingDays={remainingDays}
      />
      <DetailCard
        Icon={CalendarToday}
        label="تاريخ التجديد"
        value={
          paymentData.PaymentDate
            ? new Date(
                new Date(paymentData.PaymentDate).setDate(
                  new Date(paymentData.PaymentDate).getDate() - 1
                )
              ).toLocaleDateString("en-GB")
            : null
        }
        remainingDays={remainingDays}
      />
      <DetailCard
        Icon={Schedule}
        label="الأيام المتبقية"
        value={remainingDays ? `${remainingDays} يوم` : "0"}
        remainingDays={remainingDays}
      />
    </Grid>
    <TimelineVisualizer
      remainingDays={remainingDays}
      timeLeft={timeLeft}
      currentTimePercentage={currentTimePercentage}
      hoveredIndex={hoveredIndex}
      setHoveredIndex={setHoveredIndex}
    />
  </Paper>
);

const TimelineVisualizer = ({
  remainingDays,
  timeLeft,
  currentTimePercentage,
  hoveredIndex,
  setHoveredIndex,
}) => (
  <Box
    className="chain-container"
    display="flex"
    alignItems="center"
    justifyContent="center"
    style={{
      marginTop: "30px",
      position: "relative",
      width: "100%",
      height: "40px",
      backgroundColor: "rgb(102, 90, 52)",
      borderRadius: "8px",
      overflow: "visible",
      borderTop: "1px solid rgba(7, 7, 7, 0.32)",
      boxShadow:
        "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset",
      perspective: "1000px",
      flexDirection: "row-reverse",
    }}
  >
    {Array.from(
      { length: remainingDays > 0 ? remainingDays : 1 },
      (_, index) => (
        <TimelineSegment
          key={index}
          index={index}
          remainingDays={remainingDays}
          timeLeft={timeLeft}
          currentTimePercentage={currentTimePercentage}
          hoveredIndex={hoveredIndex}
          setHoveredIndex={setHoveredIndex}
        />
      )
    )}
  </Box>
);

const TimelineSegment = ({
  index,
  remainingDays,
  hoveredIndex,
  setHoveredIndex,
  timeLeft,
  currentTimePercentage,
}) => {
  const reversedIndex = remainingDays - index - 1;
  const isLastTwoDays = remainingDays - reversedIndex <= 2;
  const isFinalSegment = remainingDays <= 0;
  const showSingleSegment = remainingDays > 30;
  const shouldShowDate = !showSingleSegment && remainingDays > 1;

  const getSegmentStyle = () => {
    if (remainingDays <= 0) {
      return {
        height: "80%",
        width: "100%",
        borderRadius: "30px",
        margin: "0 2px",
        opacity: 1,
        background: `linear-gradient(to right, rgb(206, 4, 4) ${currentTimePercentage}%, rgba(169, 169, 169, 0.3) ${currentTimePercentage}%)`,
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow:
          "0 4px 8px rgba(0,0,0,0.2), inset 0 -2px 5px rgba(255,255,255,0.2)",
      };
    }

    if (showSingleSegment) {
      return {
        height: "80%",
        width: "100%",
        borderRadius: "30px",
        margin: "0 2px",
        opacity: 1,
        background:
          "linear-gradient(45deg, rgba(0, 128, 0, 1), rgba(91, 223, 91, 0.87), rgba(0, 128, 0, 1))",
        backgroundBlendMode: "overlay",
        boxShadow:
          "0 4px 8px rgba(0,0,0,0.2), inset 0 -2px 5px rgba(255,255,255,0.2)",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };
    }

    const distance =
      hoveredIndex !== null ? Math.abs(hoveredIndex - reversedIndex) : null;
    const baseElevation =
      remainingDays <= 2 ? -15 : remainingDays <= 5 ? -20 : -20;
    let scale = 1;
    let translateY = 0;
    let zIndex = 1;

    if (distance !== null) {
      if (distance === 0) {
        scale =
          remainingDays <= 2
            ? 1.01
            : remainingDays <= 5
            ? 1.1
            : remainingDays <= 10
            ? 1.1
            : 1.2;
        translateY = baseElevation;
        zIndex = 9999;
      } else if (distance === 1) {
        scale =
          remainingDays <= 2
            ? 1
            : remainingDays <= 5
            ? 1.05
            : remainingDays <= 10
            ? 1.05
            : 1.1;
        translateY = baseElevation / 1.5;
        zIndex = 9998;
      } else if (distance === 2) {
        scale =
          remainingDays <= 2
            ? 1.05
            : remainingDays <= 5
            ? 1.01
            : remainingDays <= 10
            ? 1.01
            : 1.02;
        translateY = baseElevation / 2;
        zIndex = 9997;
      }
    }

    const isFirstSegment = reversedIndex === 0;
    const background =
      isLastTwoDays || (reversedIndex < 2 && remainingDays <= 2)
        ? isFirstSegment
          ? `linear-gradient(to right, rgb(206, 4, 4) ${currentTimePercentage}%, rgba(169, 169, 169, 0.3) ${currentTimePercentage}%)`
          : "linear-gradient(45deg, rgb(206, 4, 4), rgba(253, 101, 101, 0.91), rgb(206, 4, 4))"
        : isFirstSegment
        ? `linear-gradient(to right, rgb(5, 192, 5) ${currentTimePercentage}%, rgba(169, 169, 169, 0.3) ${currentTimePercentage}%)`
        : "linear-gradient(45deg, rgba(0, 128, 0, 1), rgba(86, 225, 86, 0.91), rgba(0, 128, 0, 1))";

    const glowColor = isLastTwoDays
      ? "rgba(255, 0, 0, 0.3)"
      : "rgba(0, 255, 0, 0.3)";
    const boxShadow =
      distance === 0
        ? `0 10px 20px ${glowColor}, 0 6px 6px rgba(0,0,0,0.2), 0 0 15px ${glowColor}, inset 0 0 10px rgba(255,255,255,0.5)`
        : "0 4px 8px rgba(0,0,0,0.2), inset 0 -2px 5px rgba(255,255,255,0.2)";

    return {
      height: "80%",
      width: "100%",
      borderRadius: "30px",
      margin: "0 2px",
      transition: "all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
      opacity: 1,
      background,
      boxShadow,
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transform: `scale(${scale}) translateY(${translateY}px)`,
      zIndex,
    };
  };

  return (
    <Box
      className="chain-segment"
      style={getSegmentStyle()}
      onMouseEnter={() => setHoveredIndex(reversedIndex)}
      onMouseLeave={() => setHoveredIndex(null)}
    >
      {isFinalSegment ? (
        <span
          className="hover-date"
          style={{ opacity: 1, fontSize: "14px", color: "white" }}
        >
          {timeLeft}
        </span>
      ) : (
        shouldShowDate && (
          <span
            className="hover-date"
            style={{ opacity: 1, fontSize: "14px", color: "black" }}
          >
            {formatDate(
              new Date(new Date().setDate(new Date().getDate() + reversedIndex))
            )}
          </span>
        )
      )}
    </Box>
  );
};

const NotesSection = ({ notes }) => (
  <Paper elevation={2} style={{ padding: "24px" }}>
    <SectionHeader icon={Description} title="ملاحظات" />
    <Typography style={{ color: "#333333", wordBreak: "break-word" }}>
      {notes}
    </Typography>
  </Paper>
);

// Styles
const styles = `
  .chain-segment {
    transform-origin: center center;
    position: relative;
    overflow: hidden;
    transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .chain-segment::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.31) 0%, rgba(1, 1, 1, 0.09) 70%);
    opacity: 0;
    transform: scale(0);
    transition: all 0.4s ease-out;
    pointer-events: none;
  }

  .chain-segment:hover::before {
    opacity: 0.7;
    transform: scale(1);
    animation: pulseGlow 2s ease-in-out infinite;
  }

  @keyframes pulseGlow {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.3);
      opacity: 0.5;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  .chain-container {
    position: relative;
    &::before, &::after {
      // content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: rgba(255,255,255,0.2);
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }

  .hover-date {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  .chain-segment:hover .hover-date {
    opacity: 1;
  }
`;

// Main Component
const Details = () => {
  const [subdomain, setSubdomain] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const { timeLeft, currentTimePercentage } = useTimeUpdate();
  const { subscriptionData, paymentData, loading, error, fetchData } =
    useSubscriptionData();
  const [showPaymentMethods, setShowPaymentMethods] = useState(true);
  useEffect(() => {
    const hostname = window.location.hostname;
    // const hostname = "test3.owltecheg.net";
    const extractedSubdomain = hostname.split(".")[0];
    setSubdomain(extractedSubdomain);

    if (extractedSubdomain) {
      const encryptedName = encryptName(extractedSubdomain);
      fetchData(encryptedName);
    }
  }, []);

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;

  const remainingDays = paymentData?.PaymentDate
    ? calculateDays.remaining(paymentData.PaymentDate)
    : null;

  return (
    <Container>
      <Helmet>
        <title>تفاصيل الاشتراك</title>
        <style>{styles}</style>
      </Helmet>

      <Box my={4}>
        <MainHeader title="تفاصيل الاشتراك" />
      </Box>

      {subscriptionData && (
        <SubscriptionSection subscriptionData={subscriptionData} />
      )}

      {paymentData && (
        <PaymentSection
          paymentData={paymentData}
          remainingDays={remainingDays}
          timeLeft={timeLeft}
          currentTimePercentage={currentTimePercentage}
          hoveredIndex={hoveredIndex}
          setHoveredIndex={setHoveredIndex}
        />
      )}

      {paymentData?.Notes && <NotesSection notes={paymentData.Notes} />}

      <Paper elevation={2} style={{ padding: "24px", marginTop: "24px" }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          style={{ cursor: "pointer" }}
          onClick={() => setShowPaymentMethods(!showPaymentMethods)}
        >
          <SectionHeader icon={Payment} title="طرق الدفع المتاحة" />
          {showPaymentMethods ? (
            <KeyboardArrowUp style={{ color: "#1976d2" }} />
          ) : (
            <KeyboardArrowDown style={{ color: "#1976d2" }} />
          )}
        </Box>

        {showPaymentMethods && (
          <Box mt={2}>
            <PaymentMethods />
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default Details;
