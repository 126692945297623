import { Button, Grid, TextField } from "@material-ui/core";
import React, { useState } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomDialog from "../../../components/CustomDialog";
import DatePicker from "react-datepicker";
import ar from "date-fns/locale/ar-EG";
import { makeStyles } from "@material-ui/core/styles";
import { parseISO } from "date-fns/esm";
import { registerLocale } from "react-datepicker";
import { useSelector } from "react-redux";
import Mydate from "../../../components/Helpers/MyDateParser";

registerLocale("ar", ar);
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

function EditImportingDetails({
  importing,
  open,
  onClose,
  onEditItemClicked,
  partners,
  disableTime,
}) {
  const classes = useStyles();

  const [partner, setPartner] = useState("");
  const [dateTime, setDateTime] = useState("");

  React.useEffect(() => {
    if (importing.partner && importing.dateTime) {
      setPartner(importing.partner);
      setDateTime(parseISO(importing.dateTime));
    }
  }, [importing]);

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      action1={
        <Button
          onClick={onClose}
          color="secondary"
          variant="outlined"
          size="small"
          style={{ width: 56, height: 40 }}
        >
          الغاء
        </Button>
      }
      action2={
        <Button
          variant="contained"
          color="secondary"
          style={{ width: 56, height: 40, marginLeft: 16 }}
          size="small"
          onClick={() => {
            onEditItemClicked({
              id: importing.id,
              partner,
              dateTime: Mydate(dateTime),
            });
            onClose();
          }}
        >
          تعديل
        </Button>
      }
    >
      <form className={classes.root} noValidate autoComplete="off">
        <Grid item container spacing={1}>
          <Grid item>
            <Autocomplete
              options={partners}
              getOptionSelected={(option, value) =>
                option.value === value?.value
              }
              getOptionLabel={(option) => (option.name ? option.name : "")}
              style={{ width: 260 }}
              autoHighlight
              autoComplete
              noOptionsText="لم يتم العثور على خيارات"
              onChange={(event, value) => {
                if (value) {
                  setPartner(value);
                } else {
                  setPartner("");
                }
              }}
              value={partner}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  //onSubmit();
                }
              }}
              size="small"
              renderInput={(params) => (
                <TextField
                  autoFocus
                  {...params}
                  label="الاسم "
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid item>
            <DatePicker
              locale="ar"
              selected={dateTime}
              disabled={disableTime}
              onChange={(d) => setDateTime(d)}
              dateFormat="dd/MM/yyyy"
              customInput={
                <TextField
                  label="التاريخ"
                  size="small"
                  variant="outlined"
                  id="dateTime"
                  style={{ width: 200 }}
                />
              }
            />
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
}

export default EditImportingDetails;
